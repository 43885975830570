import { Range } from 'domains/reporter/RichTextEditor/core';

export function stringifyRange(range: Range): string {
  const { anchor, focus } = range;
  const anchorOffset = `ao:${anchor.offset}`;
  const rangeOffset = `fo:${focus.offset}`;
  const anchorPath = `ap:[${anchor.path.join(',')}]`;
  const focusPath = `fp:[${focus.path.join(',')}]`;
  return `${anchorOffset}|${rangeOffset}|${anchorPath}|${focusPath}`;
}

export function parseRange(rangeStr: string): Range {
  const match = rangeStr.match(
    /ao:(\d+)\|fo:(\d+)\|ap:\[(-?\d*(,-?\d*)*)\]\|fp:\[(-?\d*(,-?\d*)*)\]/
  );

  if (match) {
    const anchorOffset = parseInt(match[1]);
    const focusOffset = parseInt(match[2]);

    const anchorPath = match[3] ? match[3].split(',').map(Number) : [];
    const focusPath = match[5] ? match[5].split(',').map(Number) : [];

    return {
      anchor: {
        path: anchorPath,
        offset: anchorOffset,
      },
      focus: {
        path: focusPath,
        offset: focusOffset,
      },
    };
  } else {
    throw new Error('Invalid range string');
  }
}
