import { Flow } from 'flow-to-typescript-codemod';
import { datadogRum, InternalContext } from '@datadog/browser-rum';
import { datadogLogs } from '@datadog/browser-logs';
import { min as loadSegmentMin } from '@segment/snippet';
import {
  API_GATEWAY_URL,
  DEPLOY_ENV,
  DATADOG_APP_VERSION,
  SEGMENT_APP_ID,
  SIRONA_PREVIEW,
} from 'config';
import { performance } from 'utils/performance';
import { env } from 'config/env';
import { reporter, viewer, worklist, globalContext, isMetric, workspace } from './constants';
import { datadogGlobalEventFilter } from './datadogGlobalEventFilter';
import { nanoid } from 'nanoid';
import type { Json, ReporterSettings } from 'generated/graphql';
import { broadcastChannelAnalytics } from './broadcastChannelAnalytics';
import { getPageType } from 'utils/pageTypes';
import { getWindowId } from 'hooks/useOpenTabs';
import type { PageTypes } from 'utils/pageTypes';
import { datadogGlobalLogFilter } from './datadogGlobalLogFilter';
import { apolloResourceBeforeSend } from './datadogApolloResource';
import type { ExperimentConfiguration } from 'domains/reporter/Reporter/ExperimentControlTab/ExperimentControlTab';

// This is going to be available on Datadog for both RUM and APM
export type ReadSessionContext = {
  // A unique ID for the reading session
  sessionId: string;
  // The ID of the read worklist case
  caseSmid: string;
  // List of IDs of loaded prior studies
  priorStudiesSmids: ReadonlyArray<string>;
};

type DynamicReadSessionContext = {
  // The type of page the user is on
  pageType: PageTypes;
  // The ID of the viewer window
  viewerId: string | null | undefined;
  // Number of loaded prior studies
  priorStudiesCount: number;
};

type MemoryAnalytics = {
  memory_jsHeapSizeLimit: number;
  memory_totalJSHeapSize: number;
  memory_usedJSHeapSize: number;
  memory_ratioUsed: number;
};
export type UserInfo = {
  id: string;
  firstName: string;
  lastName: string;
  email: string;
  clinic: {
    smid: string;
    name: string;
  };
};
type ClinicData = {
  clinicName: string;
  clinicSmid: string;
};
export type UserAnalytics = ClinicData & {
  id: string;
  email: string;
  name: string;
};
export type AnalyticEventName =
  | (typeof viewer.sys)[keyof typeof viewer.sys]
  | (typeof viewer.usr)[keyof typeof viewer.usr]
  | (typeof reporter.sys)[keyof typeof reporter.sys]
  | (typeof reporter.usr)[keyof typeof reporter.usr]
  | (typeof worklist.sys)[keyof typeof worklist.sys]
  | (typeof worklist.usr)[keyof typeof worklist.usr]
  | (typeof workspace.sys)[keyof typeof workspace.sys]
  | (typeof workspace.usr)[keyof typeof workspace.usr]
  | (typeof globalContext.window)[keyof typeof globalContext.window];

export type AdditionalUserData = {
  segmentData?: {
    experimentConfiguration?: Partial<ExperimentConfiguration>;
    reporterSettings?: Partial<ReporterSettings>;
  };
};

const ENABLE_LOCAL_CONSOLE = env.ENABLE_CONSOLE_ANALYTICS === 'true';
const ALLOWED_SEGMENT_SOURCES = ['development', 'staging', 'production'];
const DEBUG_MODE = false; // turn to true to send test analytics
const ENABLE_SEGMENT = env.DISABLE_SEGMENT !== 'true';
/**
 * By default, we only want to enable remote analytics in following environments:
 *
 * - `development`
 * - `staging`
 * - `production`
 *
 * UAT's run on development environment, and we don't want to send analytics
 * when UAT's are being run. We need an additional check for PUBLIC_DISABLE_SEGMENT
 * to gate analytics in UAT's.
 *
 * For local development, we can just override the `ENABLE_REMOTE_ANALYTICS` value
 * to test analytics locally.
 */
const ENABLE_REMOTE_ANALYTICS =
  (ALLOWED_SEGMENT_SOURCES.includes(DEPLOY_ENV) && ENABLE_SEGMENT) || DEBUG_MODE;

export class Analytics {
  user: UserAnalytics | null | undefined;
  performanceObserver: PerformanceObserver | null | undefined;
  // parallel timing tracker for custom calculations
  sessionStartTime: number;
  loadedFrames: number;
  #loadEventStart: number | null;
  #onInitialLoad: boolean;

  constructor() {
    this.user = null;
    this.performanceObserver = null;
    this.#loadEventStart = null;
    this.#onInitialLoad = true;
    this.sessionStartTime = 0;
    this.loadedFrames = 0;

    if (ENABLE_REMOTE_ANALYTICS) {
      /***** DataDog RUM integration *****/
      datadogRum.init({
        applicationId: '8fbd27c5-26fd-4ef4-af77-a64666786eca',
        clientToken: 'pub81b4143716d6b080c751abe66b2a2d02',
        site: 'datadoghq.com',
        sessionSampleRate: 100,
        sessionReplaySampleRate: 100,
        service: 'srna-frontend',
        trackUserInteractions: true,
        trackResources: true,
        trackLongTasks: true,
        defaultPrivacyLevel: 'mask',
        actionNameAttribute: 'data-analytics-name',
        env: DEPLOY_ENV,
        version: DATADOG_APP_VERSION,
        allowedTracingUrls: [API_GATEWAY_URL],
        beforeSend: (event, context) => {
          if (datadogGlobalEventFilter(event, context) === false) return false;
          apolloResourceBeforeSend(event, context);
        },
        enableExperimentalFeatures: ['feature_flags'],
      });

      if (SIRONA_PREVIEW) {
        datadogRum.setGlobalContextProperty(globalContext.workspace.sironaPreview, SIRONA_PREVIEW);
      }

      window.datadogRumInitialized = true;
      window.datadogRum = datadogRum;

      datadogLogs.init({
        clientToken: 'pub81b4143716d6b080c751abe66b2a2d02',
        site: 'datadoghq.com',
        forwardErrorsToLogs: true,
        forwardConsoleLogs: ['warn', 'error'],
        sessionSampleRate: 100,
        service: 'srna-frontend',
        env: DEPLOY_ENV,
        version: DATADOG_APP_VERSION,
        beforeSend: datadogGlobalLogFilter,
      });

      /***** Segment integration *****/
      const jsSnippet = loadSegmentMin({
        apiKey: SEGMENT_APP_ID,
        load: {
          integrations: {
            // add batching strategy
            // https://segment.com/docs/connections/sources/catalog/libraries/website/javascript/#batching
            // @ts-expect-error [EN-7967] - TS2322 - Type '{ retryQueue: true; deliveryStrategy: { strategy: string; config: { size: number; timeout: number; }; }; }' is not assignable to type 'boolean'.
            'Segment.io': {
              retryQueue: true,
              deliveryStrategy: {
                strategy: 'batching',
                config: {
                  size: 20,
                  timeout: 5000,
                },
              },
            },
          },
        },
      });
      const script = document.createElement('script');
      script.innerHTML = jsSnippet;
      document.head?.appendChild(script);

      window.addEventListener('storage', (event) => {
        if (event.key === 'analyticsReadSession') {
          this.refreshReadSession();
        }
      });
    }
  }

  #log(type: string, ...args: unknown[]) {
    if (ENABLE_LOCAL_CONSOLE) {
      //eslint-disable-next-line no-console
      console.log(`%c${type}`, 'color: #7A4DA8', ...args);
    }
  }

  /**
   * @function
   * Identify the user of the current session
   * @param user Current user of the application (result of the `GET_ME` GraphQL query)
   */
  identify(user?: UserInfo | null, data?: AdditionalUserData) {
    if (user == null) return;

    this.user = {
      id: user.id,
      email: user.email,
      name: `${user.firstName} ${user.lastName}`,
      clinicName: user.clinic.name,
      clinicSmid: user.clinic.smid,
    };

    if (ENABLE_REMOTE_ANALYTICS) {
      const user = this.user;

      // Datadog identification
      const ddUser = datadogRum.getUser();
      // if the user is already identified in the window session for DataDog RUM, we don't want to override it
      if (ddUser.id == null) {
        datadogRum.setUser(user);
        datadogLogs.setUser(user);
        document.dispatchEvent(
          new CustomEvent('srna.user', {
            detail: {
              user: this.user,
            },
          })
        );
      }

      // Segment identification
      const userWithSegmentData = {
        ...user,
        ...data?.segmentData,
      } as const;
      /**
       * Calling `identify` here will pass data all the way down to MixPanel.
       * In MixPanel, however, that data will not show up in the `identify` event;
       * instead, that information is "set" in the user profile. There is no "tracking"
       * of this data, so we would need to make an extra track call to preserve the
       * data at that moment in time.
       * https://docs.mixpanel.com/docs/quickstart/identify-users
       */
      window.analytics?.identify(user.id, userWithSegmentData);
      this.track(reporter.sys.mixPanelIdentifyUser, userWithSegmentData);
    } else {
      this.#log(`analytics:identify`, this.user);
    }
  }

  timings: Set<string> = new Set();
  resetTimings() {
    this.timings.clear();
  }
  /**
   * @function
   * Track a custom performance timing with the given `name`.
   * @param name Name of the event to track
   * @param data Optional object containing contextual data for the event
   */
  timing(name: string, tail: boolean = false) {
    // Datadog can only track one timing per name per RUM View
    // it will only preserve the last timing tracked and discard the rest
    // This is exactly the opposite of what we want to do, so we need to
    // track the timings ourselves and only send the first one.
    // We reset the timings when the user navigates to a new page (see `useAnalytics`).
    if (tail === false && this.timings.has(name)) {
      if (ENABLE_LOCAL_CONSOLE) {
        // eslint-disable-next-line no-console
        console.debug(`analytics:timing - ${name} already tracked`);
      }
      return;
    }
    this.timings.add(name);

    if (ENABLE_REMOTE_ANALYTICS) {
      // DataDog tracking
      datadogRum.addTiming(name);
    } else {
      this.#log(`analytics:timing - ${name} ${this.getTimeFromLoadEvent()}`);
    }
  }

  /**
   * @function
   * Track an event with the given `name` and, optionally,
   * any helpful contextual data.
   * @param name Name of the event to track
   * @param data Optional object containing contextual data for the event
   */
  track(name: AnalyticEventName, data?: Json) {
    if (ENABLE_REMOTE_ANALYTICS) {
      // DataDog tracking
      // @ts-expect-error [EN-7967] - TS2345 - Argument of type 'unknown' is not assignable to parameter of type 'object'.
      datadogRum.addAction(name, data);
      // Segment tracking
      if (
        isMetric(name, { scope: 'reporter', type: 'sys' }) ||
        isMetric(name, { scope: 'reporter', type: 'usr' })
      ) {
        window.analytics?.track(name as string, data);
      }
    } else {
      this.#log(`analytics:track - ${name}`, data);
    }
  }

  /**
   * Segment's `reset()` will manage its own id system, but does not
   * trigger a MixPanel `reset()` so we need to call this separately.
   * https://segment.com/docs/connections/destinations/catalog/mixpanel/#reset-mixpanel-cookies
   *
   * For now, since anonymous users can't access resources other than
   * the login page, we don't need to worry about this.
   */
  reset() {
    if (ENABLE_REMOTE_ANALYTICS) {
      // Segment reset
      window.analytics?.reset();
      this.track(reporter.sys.mixPanelResetUser);
    }
  }

  /**
   * Manually track an error, such as from an `ErrorBoundary`
   */
  error(error: Error, data?: Json) {
    if (ENABLE_REMOTE_ANALYTICS) {
      // DataDog tracking
      // @ts-expect-error [EN-7967] - TS2345 - Argument of type 'unknown' is not assignable to parameter of type 'object'.
      datadogRum.addError(error, data);
    } else {
      this.#log(`analytics:error - ${error.message}`, data);
    }
  }

  /**
   * Returns {true} if we're on the initial page load, {false} if we've loaded a new
   * case while the viewer / reporter is already open.
   */
  isOnInitialLoad(): boolean {
    return this.#onInitialLoad;
  }

  /**
   * Get the time (in milliseconds) that has ellapsed from `loadEventStart` until now.
   */
  getTimeFromLoadEvent(): number {
    return Math.round(performance.now() - this.getLoadEventStart());
  }

  /**
   * Get the performance timestamp of the `loadEventStart` navigation timing.
   * https://developer.mozilla.org/en-US/docs/Web/API/PerformanceNavigationTiming/loadEventStart
   */
  getLoadEventStart(): number {
    if (this.#loadEventStart == null) {
      const [navigationTiming] = performance.getEntriesByType('navigation');

      // @ts-expect-error [EN-7967] - TS2339 - Property 'loadEventStart' does not exist on type 'PerformanceEntry'.
      this.#loadEventStart = navigationTiming.loadEventStart;
    }

    return this.#loadEventStart;
  }

  /**
   * Update the timestamp for the `loadEventStart` navigation timing with the current
   * value for {performance.now()}. This is needed for when the user loads a new case,
   * which doesn't refresh the page to create a new {NavigationTiming}.
   * Instead, we need to manually track the the timestamp when the new case is loaded.
   */
  resetLoadEventStart() {
    this.#onInitialLoad = false;
    this.#loadEventStart = performance.now();
  }

  /**
   * Retrieve information about the client's memory usage from `performance.memory`
   */
  getCurrentMemory(): MemoryAnalytics | null | undefined {
    let memoryAnalytics: MemoryAnalytics | null | undefined = null;

    if ('memory' in performance) {
      const memory = performance.memory();
      if (memory != null) {
        memoryAnalytics = {
          memory_totalJSHeapSize: memory.totalJSHeapSize,
          memory_jsHeapSizeLimit: memory.jsHeapSizeLimit,
          memory_usedJSHeapSize: memory.usedJSHeapSize,
          memory_ratioUsed: memory.usedJSHeapSize / memory.totalJSHeapSize,
        };
      }
    }

    return memoryAnalytics;
  }

  /**
   * @function
   * Start a `PerformanceObserver` that will track all `measure` type `PerformanceEntry`s.
   * @param name Name to group all performance measurements for analytics (sent to `this.track`)
   * @param additionalData Optional object containing additional data to inject into each
   *                                measurement tracked.
   */
  startPerformanceObserver(
    name: AnalyticEventName,
    additionalData?:
      | {
          [key: string]: Json;
        }
      | ((entry: PerformanceEntry) => {
          [key: string]: Json;
        })
  ) {
    if (this.performanceObserver != null) {
      this.stopPerformanceObserver();
    }

    this.performanceObserver = new PerformanceObserver((list: PerformanceObserverEntryList) => {
      for (const entry of list.getEntriesByType('measure')) {
        const data = typeof additionalData === 'function' ? additionalData(entry) : additionalData;

        this.track(
          name,
          Object.assign(
            {
              name: entry.name,
              duration_ms: Math.round(entry.duration),
            },
            data
          )
        );
        performance.clearMeasures(entry.name);
      }
    });

    this.performanceObserver.observe({
      type: 'measure',
      buffered: true,
    });
  }

  stopPerformanceObserver() {
    if (this.performanceObserver != null) {
      this.performanceObserver.disconnect();
      this.performanceObserver = null;
    }
  }

  addContext(key: string, value: Json) {
    if (ENABLE_REMOTE_ANALYTICS) {
      datadogRum.setGlobalContextProperty(key, value);
    } else {
      this.#log(`analytics:context - ${key}`, value);
    }
  }

  startSessionReplayRecording() {
    datadogRum.startSessionReplayRecording();
  }

  stopSessionReplayRecording() {
    datadogRum.stopSessionReplayRecording();
  }

  getInternalContext(): InternalContext | undefined {
    return datadogRum.getInternalContext();
  }

  getRumSessionId(): string | null | undefined {
    return datadogRum.getInternalContext()?.session_id;
  }

  /**
   * readSession is an object where we store any information about the current reading session
   * that we want to track. Any Datadog event will include this data and it will allow us to
   * filter events by session and understand what the user did during the session at a low level.
   *
   * A read session starts when the user starts reading a case by clicking on the "Read" button
   * on the work list, or by clicking the "Claim and read" button on the reporter.
   * It ends when the user clicks on the "Submit" button on the reporter.
   *
   * Currently we don't support tracking data after a user clicks on "Submit" and then cancels
   * the submission.
   */
  // @ts-ignore [unsafe-getters-setters]
  get readSession(): (ReadSessionContext & DynamicReadSessionContext) | null | undefined {
    try {
      const readSession: ReadSessionContext = JSON.parse(
        window.localStorage.getItem('analyticsReadSession')
      );
      return {
        ...readSession,
        pageType: getPageType(window.location.href),
        priorStudiesCount: readSession.priorStudiesSmids?.length ?? 0,
        viewerId: getWindowId(window.location.href),
      };
    } catch (e: any) {
      return null;
    }
  }

  // @ts-ignore [unsafe-getters-setters]
  set readSession(value?: ReadSessionContext | null) {
    if (value == null) {
      try {
        window.localStorage.removeItem('analyticsReadSession');
      } catch (e: any) {
        console.error(e);
        this.error(e?.name, {
          key: 'analyticsReadSession',
        });
      }
    } else {
      try {
        window.localStorage.setItem('analyticsReadSession', JSON.stringify(value));
      } catch (e: any) {
        console.error(e);
        this.error(e?.name, {
          key: 'analyticsReadSession',
        });
      }
    }
  }

  refreshReadSession() {
    const readSession = this.readSession;
    if (readSession != null) {
      this.addContext('globalContext.readSession', {
        ...readSession,
        // DD doesn't support arrays, so we need to stringify it
        priorStudiesSmids: readSession.priorStudiesSmids.join(','),
      });
    } else {
      this.addContext('globalContext.readSession', null);
    }
    broadcastChannelAnalytics.refreshReadSession(
      readSession != null ? this.#clearDynamicPropertiesFromReadSession(readSession) : null
    );
  }

  startReadSession(caseSmid: ReadSessionContext['caseSmid']) {
    this.readSession = {
      sessionId: nanoid(),
      caseSmid,
      priorStudiesSmids: [],
    };
    this.refreshReadSession();
  }

  restartViewerTracking() {
    this.sessionStartTime = performance.now();
    this.loadedFrames = 0;
  }

  #clearDynamicPropertiesFromReadSession(
    readSession: ReadSessionContext & DynamicReadSessionContext
  ): ReadSessionContext {
    // @ts-expect-error [EN-7967] - TS2322 - Type 'string[]' is not assignable to type 'keyof DynamicReadSessionContext[]'.
    const dynamicReadSessionKeys: keyof DynamicReadSessionContext[] = [
      'priorStudiesCount',
      'pageType',
      'viewerId',
    ];
    // @ts-expect-error [EN-7967] - TS2739 - Type '{ [k: string]: string | number | readonly string[]; }' is missing the following properties from type 'ReadSessionContext': sessionId, caseSmid, priorStudiesSmids
    const staticReadSession: ReadSessionContext = Object.fromEntries(
      // @ts-expect-error [EN-7967] - TS2769 - No overload matches this call. | TS2339 - Property 'includes' does not exist on type 'keyof DynamicReadSessionContext[]'.
      Object.entries(readSession).filter(([key]: [any]) => !dynamicReadSessionKeys.includes(key))
    );
    return staticReadSession;
  }

  updateReadSession(
    readSession: Partial<
      Flow.Diff<
        ReadSessionContext,
        {
          sessionId: unknown;
        }
      >
    >
  ) {
    if (this.readSession == null) {
      return;
    }

    this.readSession = {
      ...this.#clearDynamicPropertiesFromReadSession(this.readSession),
      ...readSession,
    };
    this.refreshReadSession();
  }

  stopReadSession() {
    this.readSession = null;
    this.refreshReadSession();
  }
}
