import type { BaseStack } from 'generated/graphql';
import type {
  DehydratedViewportConfiguration,
  DehydratedViewportsConfigurations,
  SlimSeries,
  SlimStudy,
  SlimFrame,
  StudyGroup,
  ViewportConfiguration,
  ViewportsConfigurations,
  SlimStack,
  Stack,
  Series,
  Study,
  Frame,
  FullMultiLayerStack,
} from './types';
import memoize from 'lodash.memoize';
import { decodeOverlayStackSmidToStackLayers, isStackSmidMultiLayer } from '../Viewer/stackUtils';

/**
 * Takes a list of viewport configurations and merges them into a single list.
 * The last occurrence is preserved, the list is NOT deep merged.
 */
export function mergeViewportsConfigurations(
  ...configurations: ReadonlyArray<ViewportsConfigurations | null | undefined>
): ViewportsConfigurations {
  return configurations.reduce<ViewportsConfigurations>((acc, configuration) => {
    return {
      ...acc,
      ...configuration,
    };
  }, {});
}

export function viewportsConfigurationsEntries(
  viewportsConfigurations: ViewportsConfigurations | DehydratedViewportsConfigurations
): [string, ViewportConfiguration | null | undefined][] {
  // @ts-expect-error [EN-7967] - TS2322 - Type '[string, DehydratedViewportConfiguration][]' is not assignable to type '[string, ViewportConfiguration][]'.
  return Object.entries(viewportsConfigurations);
}

export function filterViewportsConfigurations(
  viewportsConfigurations: ViewportsConfigurations | DehydratedViewportsConfigurations,
  filter: (arg1: [string, ViewportConfiguration | null | undefined]) => boolean
): ViewportsConfigurations {
  return Object.fromEntries(viewportsConfigurationsEntries(viewportsConfigurations).filter(filter));
}

export function dehydrateViewportConfiguration(
  viewportConfiguration: ViewportConfiguration
): DehydratedViewportConfiguration {
  return {
    ...viewportConfiguration,
    study: viewportConfiguration.study && { smid: viewportConfiguration.study.smid },
    series: viewportConfiguration.series && { smid: viewportConfiguration.series.smid },
    stack: viewportConfiguration.stack && { smid: viewportConfiguration.stack.smid },
  };
}
export function hydrateViewportConfiguration(
  viewportConfiguration: DehydratedViewportConfiguration,
  groupedStudies: ReadonlyArray<StudyGroup>
): ViewportConfiguration | null | undefined {
  const studies = groupedStudies.flat(1).reduce(
    (acc, study) => {
      const studySmid: string = study.smid;
      return { ...acc, [studySmid]: study };
    },
    {} as {
      [key: string]: SlimStudy;
    }
  );
  const series = groupedStudies
    .flat(1)
    .flatMap((study) => study.seriesList)
    .reduce(
      (acc, series) => ({ ...acc, [series.smid]: series }),
      {} as {
        [key: string]: SlimSeries;
      }
    );
  const stacks = groupedStudies
    .flat(1)
    .flatMap((study) => study.stackedFrames)
    .reduce(
      (
        acc: {
          [key: string]: SlimStack;
        },
        stack
      ) => {
        const stackSmid: string = stack.smid;
        return { ...acc, [stackSmid]: stack };
      },
      {} as {
        [key: string]: SlimStack;
      }
    );

  // We have a specific study we are looking for here, if it isnt mapped yet return null
  if (
    viewportConfiguration.wasDropped === true &&
    viewportConfiguration.study &&
    studies[viewportConfiguration.study.smid] == null
  ) {
    return null;
  }

  const config: ViewportConfiguration = {
    ...viewportConfiguration,
    study: viewportConfiguration.study && studies[viewportConfiguration.study.smid],
    series: viewportConfiguration.series && series[viewportConfiguration.series.smid],
    stack: null,
  };

  if (
    viewportConfiguration.stack &&
    viewportConfiguration.stack.smid != null &&
    isStackSmidMultiLayer(viewportConfiguration.stack.smid) &&
    viewportConfiguration.study != null &&
    viewportConfiguration.study.smid != null
  ) {
    config.stack = {
      smid: viewportConfiguration.stack.smid,
      type: '',
      study: {
        smid: viewportConfiguration.study.smid,
      },
    };
  } else {
    config.stack = viewportConfiguration.stack && stacks[viewportConfiguration.stack.smid];
  }

  return config;
}

export function dehydrateViewportsConfigurations(
  viewportsConfigurations: ViewportsConfigurations
): DehydratedViewportsConfigurations {
  return Object.fromEntries(
    viewportsConfigurationsEntries(viewportsConfigurations).map(
      ([key, viewportConfiguration]: [any, any]) => [
        key,
        viewportConfiguration != null
          ? dehydrateViewportConfiguration(viewportConfiguration)
          : null,
      ]
    )
  );
}

export function hydrateViewportsConfigurations(
  viewportsConfigurations: DehydratedViewportsConfigurations,
  groupedStudies: StudyGroup[]
): ViewportsConfigurations {
  const ret = Object.fromEntries(
    Object.entries(viewportsConfigurations).map(([key, viewportConfiguration]: [any, any]) => [
      key,
      viewportConfiguration != null
        ? hydrateViewportConfiguration(viewportConfiguration, groupedStudies)
        : null,
    ])
  );

  return ret;
}

export function studyToSlimStudy(study: Study): SlimStudy {
  return {
    smid: study.smid,
    description: study.description,
    seriesList: study.seriesList.map((series) => seriesToSlimSeries(series)),
    stackedFrames: study.stackedFrames.map((stack) => stackToSlimStack(stack)),
  };
}

export function seriesToSlimSeries(series: Series): SlimSeries {
  return {
    smid: series.smid,
    description: series.description,
    study: { smid: series.study.smid },
  };
}

export function stackToSlimStack(stack: Stack | BaseStack): SlimStack {
  const slim: SlimStack = {
    smid: stack.smid,
    type: stack.type,
    study: { smid: stack.study.smid },
  };

  // @ts-expect-error [EN-7967] - TS2339 - Property 'frames' does not exist on type 'BaseStack | Stack'.
  if (stack.frames != null) {
    slim.frames =
      // @ts-expect-error [EN-7967] - TS2339 - Property 'frames' does not exist on type 'BaseStack | Stack'.
      stack.frames.length > 0
        ? [
            {
              // @ts-expect-error [EN-7967] - TS2339 - Property 'frames' does not exist on type 'BaseStack | Stack'.
              smid: stack.frames[0].smid,
              // @ts-expect-error [EN-7967] - TS2339 - Property 'frames' does not exist on type 'BaseStack | Stack'.
              series: { smid: stack.frames[0].series.smid },
              // @ts-expect-error [EN-7967] - TS2339 - Property 'frames' does not exist on type 'BaseStack | Stack'.
              sopClassUID: stack.frames[0].sopClassUID,
            },
          ]
        : [];
  }

  return slim;
}

export function frameToSlimFrame(frame: Frame): SlimFrame {
  return {
    smid: frame.smid,
    series: { smid: frame.series.smid },
    sopClassUID: '123',
  };
}

export const rehydrateSlimStackToLayeredStack: (
  slimStack?: SlimStack | null | undefined,
  study?: Study | null | undefined
) => FullMultiLayerStack | null | undefined = memoize(
  (slimStack?: SlimStack | null, study?: Study | null): FullMultiLayerStack | null | undefined => {
    if (study != null && slimStack != null && isStackSmidMultiLayer(slimStack.smid)) {
      const stack: FullMultiLayerStack = {
        __typename: 'LayeredStack',
        type: '',
        smid: slimStack.smid,
        stackLayers: decodeOverlayStackSmidToStackLayers(slimStack.smid),
        study: { smid: study.smid },
      };

      return stack;
    }
    return null;
  },
  (slimStack) => slimStack?.smid
);
