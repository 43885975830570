/**
 * This Apollo Link will attempt to read from a custom cache before
 * making a network request. If the cache is empty, it will make a
 * network request and then write the response to the cache.
 *
 * This is useful when you want to pre-cache data for a user before
 * they need it. And you don't want to add the data to the in-memory-cache
 * because it may not be used for a while or may not be necessary for
 * the current page but for a different page.
 *
 * Example: When a user opens the worklist, we can pre-cache the
 * viewer data for the first N cases in the worklist. Then, when
 * the user clicks on a case, the viewer data will already be cached
 * and the viewer will load faster.
 */
import { LRUSWCache } from '../LRUSWCache';
import { logger } from 'modules/logger';
import { ApolloLink, Observable } from '@apollo/client';
import { getMainDefinition } from '@apollo/client/utilities';
import { env } from 'config/env';
import type { NextLink, Operation } from '@apollo/client';

// 500 MB cache size
export const Cache: LRUSWCache = new LRUSWCache({
  key: 'apollo-pre-cache',
  version: env.GRAPHQL_SCHEMA_VERSION ?? '1',
  maxSize: 500 * 1024 * 1024,
  evictionThreshold: 0.1,
});

export class ResponseWithContentLength extends Response {
  constructor(body: string) {
    super(body);
    this.headers.set('Content-Length', String(body.length));
  }
}

type PrefetchPolicy = // this is the default, it's like cache-first but doesn't update the cache

    | 'readonly-cache-first' // this will read from the cache and make a network request if the cache is empty
    | 'cache-first' // this will only read from the cache and will error if the cache is empty
    | 'cache-only' // this will only make a network request and will not read from the cache
    | 'network-only'
    | 'cache-and-network';

export const precacheLink: ApolloLink = new ApolloLink(
  (operation: Operation, forward: NextLink) => {
    return new Observable((observer: any) => {
      try {
        (async () => {
          const cache = await Cache.open();
          // We use the operation name and operation variable as the cache key
          const mainDefinition = getMainDefinition(operation.query);
          const operationKey = `${mainDefinition.name.value}${JSON.stringify(operation.variables)}`;
          const operationUrl = new URL(operationKey, window.location.origin);

          const precachePolicy: PrefetchPolicy =
            // this will read from the cache and make a network request
            operation.getContext().precachePolicy ?? 'readonly-cache-first';

          // If precache is set to 'network-only' we don't read from the cache
          if (precachePolicy !== 'network-only') {
            // Check if the cache has a response for this operation
            // @ts-expect-error [EN-7967] - TS2345 - Argument of type 'URL' is not assignable to parameter of type 'RequestInfo'.
            const cacheResponse = await cache.match(operationUrl);

            // If the cache has a response, return it
            if (cacheResponse != null) {
              const response = await cacheResponse.json();
              logger.debug(`[${precachePolicy}] reading from cache: ${operationKey}.`, response);
              observer.next(response);

              // If the cache has a response and the precachePolicy is set to one that gives priority to the cache
              // we don't make a network request and we complete the observable
              if (
                precachePolicy === 'cache-only' ||
                precachePolicy === 'cache-first' ||
                precachePolicy === 'readonly-cache-first'
              ) {
                logger.debug(
                  `[${precachePolicy}] only read from cache, skipping network: ${operationKey}.`
                );
                observer.complete();
                return;
              }
            } else if (precachePolicy === 'cache-only') {
              // If the cache doesn't have a response and the precachePolicy is 'cache-only'
              // we return an error
              observer.error(
                new Error(
                  'Query executed with precachePolicy=cache-only but no data was present in the cache for the requested query.'
                )
              );
              return;
            } else {
              // if it's cache-first or cache-and-network we are okay with not having a cache response
              // and we will make the network request on the next step
              logger.debug(
                `[${precachePolicy}] did not read from cache, will read from network: ${operationKey}.`
              );
            }
          }

          // Make the network request and write the response to the cache (or update it)
          const observable = forward(operation);

          observable.subscribe({
            next: (data) => {
              // When the precachePolicy is not network-only we write the response to the cache
              if (
                // we want to trigger a network call after we read from cache
                precachePolicy === 'cache-and-network' ||
                // if no cache was found, we want to go through the network
                precachePolicy === 'cache-first'
              ) {
                const response = new ResponseWithContentLength(JSON.stringify(data));
                // dont put anything with an error into the cache
                if (!data.errors) {
                  // @ts-expect-error [EN-7967] - TS2345 - Argument of type 'URL' is not assignable to parameter of type 'RequestInfo'.
                  cache.put(operationUrl, response);
                  logger.debug(
                    `[${precachePolicy}] reading from network and writing to cache: ${operationKey}.`,
                    data
                  );
                }
              } else {
                // we get here if the precachePolicy is 'network-only', here we don't want to write to the cache
                logger.debug(
                  `[${precachePolicy}] reading from network but not writing to cache: ${operationKey}.`
                );
              }

              observer.next(data);
            },
            error: (error) => {
              observer.error(error);
            },
            complete: () => {
              observer.complete();
            },
          });
        })();
      } catch (error: any) {
        observer.error(error);
      }
    });
  }
);

export async function purgePrecache(): Promise<void> {
  return Cache.purge();
}
