import { PIXEL_LOADER_WS_URL } from 'config';
import type { FullSingleLayerStack } from 'domains/viewer/ViewportsConfigurations/types';
import { getGlobalImageIOWorkerPool } from 'modules/viewer/workerSetup';

import { BaseWebSocketImageLoader } from './BaseWebSocketImageLoader';
import type { HandleDataMessageCallback } from './BaseWebSocketImageLoader';
import type { MessageReceivedCallback, OrderedFrame } from './BaseImageLoader';
import { decompressGzippedBuffer } from './imageLoaderUtils';
import type { SupportedTexturesMap } from 'utils/textureUtils';

const NANOID_BYTE_LENGTH = 21;

export class DicomWebSocketImageLoader extends BaseWebSocketImageLoader {
  constructor(SUPPORTED_TEXTURES: SupportedTexturesMap) {
    super(PIXEL_LOADER_WS_URL, SUPPORTED_TEXTURES);
  }

  /******************************************************************
   * Public API
   ******************************************************************/

  loadStack({
    stack,
    initialFocus,
    isInitialFrame,
    stackPriority,
    isDropped,
    messageReceivedCallback,
  }: {
    stack: FullSingleLayerStack;
    initialFocus: number;
    isInitialFrame: boolean;
    stackPriority: number;
    isDropped: boolean;
    messageReceivedCallback: MessageReceivedCallback;
  }): void {
    this._loadStack({
      stack,
      transferType: 'dicom',
      initialFocus,
      isInitialFrame,
      stackPriority,
      isDropped,
      messageReceivedCallback,
    });
  }

  async loadFrames({
    orderedFrames,
    initialFocus,
    isInitialFrame,
    stackPriority,
    isDropped,
    messageReceivedCallback,
  }: {
    orderedFrames: OrderedFrame[];
    initialFocus: number;
    isInitialFrame: boolean;
    stackPriority: number;
    isDropped: boolean;
    messageReceivedCallback: MessageReceivedCallback;
  }) {
    this._loadFrames({
      orderedFrames,
      transferType: 'dicom',
      initialFocus,
      isInitialFrame,
      stackPriority,
      isDropped,
      messageReceivedCallback,
    });
  }

  /******************************************************************
   * Protected API
   ******************************************************************/

  _handleDataMessage: HandleDataMessageCallback = async (data) => {
    if (!(data instanceof ArrayBuffer)) {
      throw new Error('Expected data to be an ArrayBuffer');
    }

    this._log('_handleDataMessage');

    // {requestId} is a 21-character {nanoid} and {frameSmid} is a 36-character UUID.
    let readOffset = 0;
    const requestIdBuffer = data.slice(readOffset, (readOffset += NANOID_BYTE_LENGTH));
    const frameSmidLengthBuffer = data.slice(readOffset, (readOffset += 1));
    const frameSmidLength = new Uint8Array(frameSmidLengthBuffer)[0];
    const frameSmidBuffer = data.slice(readOffset, (readOffset += frameSmidLength));

    const requestId = new TextDecoder('utf-8').decode(requestIdBuffer);
    const frameSmid = new TextDecoder('utf-8').decode(frameSmidBuffer);
    this._log(`Buffer - requestId: ${requestId}, frameSmid: ${frameSmid}`);

    // Remaining buffer is the contents of a gzipped DICOM file
    const fileBuffer = data.slice(readOffset);
    const decompressedBuffer = await decompressGzippedBuffer(fileBuffer);

    this.#receiveFrame(requestId, frameSmid, decompressedBuffer);
    this._resolveDeferredPromise(requestId);
  };

  async #receiveFrame(requestId: string, frameSmid: string, data: ArrayBuffer) {
    const requestInfo = this.requestMap.get(requestId);

    if (requestInfo == null) return;
    // EN-7192 @iandonn95 - I'm pretty sure requestInfo.frameMap keys off index, not frame smid
    // so this may not work. 'dicom' mode is not used at the moment, so this may have been
    // broken for a while
    // console.log(requestInfo.frameMap.get(frameSmid), requestInfo.frameMap.keys());
    const frameInfo = requestInfo.frameMap.get(frameSmid);

    if (frameInfo == null) return;

    this._log('#receive-frame: ', frameInfo.seriesSmid, frameSmid);

    const globalImageIOWorkerPool = getGlobalImageIOWorkerPool();
    const { image } = await globalImageIOWorkerPool.readArrayBuffer(data, `./${frameSmid}.dcm`);

    if (image.data == null) return;

    // @ts-expect-error [EN-7967] - TS2769 - No overload matches this call.
    const pixels = Float32Array.from(image.data);

    requestInfo.messageReceivedCallback({
      pixels,
      frameSmid,
      event: 'data-received',
    });

    requestInfo.frameMap.delete(frameSmid);

    this._log(`${requestInfo.frameMap.size} frames remaining in series ${frameInfo.seriesSmid}`);
  }
}
