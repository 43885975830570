import type { FullSingleLayerStack } from 'domains/viewer/ViewportsConfigurations/types';
import type { MessageReceivedCallback, OrderedFrame } from './BaseImageLoader';
import { BaseImageLoader } from './BaseImageLoader';
import Deferred from 'utils/deferred';
import { PriorityQueue } from '@datastructures-js/priority-queue';

import { GRAPHQL_URL } from 'config';
import { GET_STACK_SIGNED_URLS } from 'modules/Apollo/queries';
import { logger } from 'modules/logger';

import { workerClient } from '../../Apollo/workerClient';
import { MAX_PARALLEL_REQUESTS, trackCacheStatus } from './ParallelPixelWebSocketImageLoader';
import { fetchWithRetry } from './fetchWithRetry';
import type { GetStackSignedUrlsQuery, GetStackSignedUrlsQueryVariables } from 'generated/graphql';
import { parsePixelData } from './imageLoaderUtils';
import OpenJPHModuleLoader from '@cornerstonejs/codec-openjph';
import type { HTJ2KDecoderType } from '@cornerstonejs/codec-openjph';
import { sleep, exponentialBackoff } from 'utils/sleep';
import { PixelDataSharedWorkerError } from '../workers/PixelWorkerConnection';
import { generateProxyUrl } from 'utils/urls';
import type { SupportedTexturesMap } from 'utils/textureUtils';
import { NOOP } from 'config/constants';

const STACK_DOWNLOAD_TIMEOUT_SEC = 30;

type QueuedFrame = {
  stackSmid: string;
  url: string;
  includeCredentials: boolean;
  frameSmid: string;
  sortIndex: number;
  deferred: Deferred<ArrayBuffer | null | 'cancelled'>;
  priority: number;
  expirationTime?: number;
};

type Request = {
  orderedFrames: ReadonlyArray<OrderedFrame>;
  priority: number;
  focus: number;
  stackSmid: string;
};

const STATUS = {
  PENDING: 'PENDING',
  ERROR: 'ERROR',
  COMPLETE: 'COMPLETE',
} as const;

export class HttpImageLoader extends BaseImageLoader {
  #fetchQueue: PriorityQueue<QueuedFrame> = new PriorityQueue<QueuedFrame>(
    (a: QueuedFrame, b: QueuedFrame) => a.priority - b.priority
  );

  #paused: boolean = false;
  #status: keyof typeof STATUS;

  #inflightFrames: {
    frameSmid: string;
  }[] = [];
  #requestMap: Map<string, Request> = new Map<string, Request>();
  #openJPHModuleLoaderPromise: Promise<{
    HTJ2KDecoder: HTJ2KDecoderType;
  }>;

  constructor(SUPPORTED_TEXTURES: SupportedTexturesMap) {
    super(SUPPORTED_TEXTURES);
    this.#status = STATUS.PENDING;

    this.loadOpenJPHModule();
  }

  loadOpenJPHModule() {
    // @ts-expect-error [EN-7967] - TS2349 - This expression is not callable.
    this.#openJPHModuleLoaderPromise = OpenJPHModuleLoader({
      locateFile: (path) => path,
      print: NOOP,
      printErr: (...loggerArgs) => {
        logger.error(...loggerArgs);
      },
    });

    this.#status = STATUS.PENDING;

    this.#openJPHModuleLoaderPromise
      .then(() => {
        this.#status = STATUS.COMPLETE;
      })
      .catch(() => {
        logger.error('Failed to load the openJPHModule');
        this.#status = STATUS.ERROR;
      });
  }

  loadStack({
    stack,
    initialFocus,
    isInitialFrame,
    stackPriority,
    isDropped,
    messageReceivedCallback,
  }: {
    stack: FullSingleLayerStack;
    initialFocus: number;
    isInitialFrame: boolean;
    stackPriority: number;
    isDropped: boolean;
    messageReceivedCallback: MessageReceivedCallback;
  }) {
    const orderedFrames = stack.frames.map((frame, index) => ({
      stackSmid: stack.smid,
      frameSmid: frame.smid,
      seriesSmid: frame.series.smid,
      isMultiFrame: stack.type === 'multi-frame',
      sortIndex: index,
      modules: {
        modalityLut: frame.modules.modalityLut,
      },
    }));
    this.loadFrames({
      orderedFrames,
      initialFocus,
      isInitialFrame,
      stackPriority,
      isDropped,
      messageReceivedCallback,
    });
  }

  refreshLoader() {
    logger.info('Refreshing OpenJPHModule');
    this.loadOpenJPHModule();
  }

  async downloadStacks(
    stackedFrames: ReadonlyArray<ReadonlyArray<OrderedFrame>>,
    orderedFrames: ReadonlyArray<OrderedFrame>,
    stackPriority: number,
    initialFocus: number,
    messageReceivedCallback: MessageReceivedCallback,
    maxNonDelayedAttempts: number = 2
  ): Promise<Map<string, Set<string>>> {
    if (this.#status === STATUS.ERROR) {
      this.refreshLoader();
    }
    const results = await Promise.all(
      stackedFrames.map(async (frames) => {
        const request = {
          orderedFrames,
          priority: stackPriority,
          focus: initialFocus,
          stackSmid: frames[0].stackSmid,
        } as const;
        this.#requestMap.set(frames[0].stackSmid, request);
        let pixelUrls;
        try {
          const {
            data: { pixelURLs },
          } = await workerClient.query<GetStackSignedUrlsQuery, GetStackSignedUrlsQueryVariables>({
            query: GET_STACK_SIGNED_URLS,
            variables: {
              stackSmid: frames[0].stackSmid,
              isMultiFrame: frames[0].isMultiFrame,
              frames: frames.map((frame) => ({
                frameSmid: frame.frameSmid,
                seriesSmid: frame.seriesSmid,
              })),
            },
            fetchPolicy: 'network-only',
            context: {
              fetchOptions: {
                // @ts-ignore [prop-missing] feature not defined in our old version of Flow
                signal: AbortSignal.timeout(STACK_DOWNLOAD_TIMEOUT_SEC * 1000),
              },
            },
          });

          pixelUrls = pixelURLs;
        } catch (error: any) {
          logger.error(
            `Signed Urls Error frameSmid: ${frames[0].stackSmid}, seriesSmid: ${frames[0].seriesSmid}`,
            error
          );
          return new Set(frames.map((frame) => frame.frameSmid));
        }

        const erroredFrames = new Set();

        await Promise.all(
          pixelUrls
            .filter((pixelURL) => frames.some((frame) => frame.frameSmid === pixelURL.frameSmid))
            .map(async ({ frameSmid, signedUrl: { url, expirationTime }, fallbackUrl }, index) => {
              const frame = frames.find((frame) => frame.frameSmid === frameSmid);
              const framePriority =
                frame != null
                  ? calculateFramePriority(frame, stackPriority, initialFocus)
                  : Infinity;
              let dataBuffer = null;
              try {
                // cached URLs are pre-signed so we don't need to include credentials
                dataBuffer = await this.#fetchPixelData({
                  url,
                  expirationTime,
                  includeCredentials: false,
                  frameSmid,
                  priority: framePriority,
                  request,
                });

                if (dataBuffer == null) {
                  if (frame?.isMultiFrame === false) {
                    // non-cached URLs are not pre-signed so we need to include credentials
                    dataBuffer = await this.#fetchPixelData({
                      url: generateProxyUrl(fallbackUrl),
                      includeCredentials: true,
                      frameSmid,
                      priority: framePriority,
                      request,
                    });
                  }
                }

                if (dataBuffer == null) {
                  throw new Error(
                    `${PixelDataSharedWorkerError.FailedToFetch} frameSmid: ${frameSmid}`
                  );
                }

                if (dataBuffer === 'cancelled') {
                  return;
                }

                const pixels = await parsePixelData(
                  dataBuffer,
                  this.#openJPHModuleLoaderPromise,
                  this._SUPPORTED_TEXTURES,
                  frame
                );

                messageReceivedCallback({
                  pixels,
                  frameSmid,
                  event: 'data-received',
                });
              } catch (error: any) {
                const expirationDate = new Date(expirationTime * 1000);
                const now = new Date();
                if (now < expirationDate) {
                  // only log errors if the request hasn't expired
                  logger.error(
                    `Failed to fetch pixel data for stack: ${frames[0].stackSmid} frameSmid: ${frameSmid}`,
                    error
                  );
                }
                erroredFrames.add(frameSmid);
              }
            })
        );

        return erroredFrames;
      })
    );

    // @ts-expect-error [EN-7967] - TS2322 - Type 'Map<string, Set<unknown>>' is not assignable to type 'Map<string, Set<string>>'.
    return new Map(stackedFrames.map((frames, index) => [frames[0].stackSmid, results[index]]));
  }

  async loadFrames(
    {
      orderedFrames,
      initialFocus,
      isInitialFrame,
      stackPriority,
      isDropped,
      messageReceivedCallback,
    }: {
      orderedFrames: OrderedFrame[];
      initialFocus: number;
      isInitialFrame: boolean;
      stackPriority: number;
      isDropped: boolean;
      messageReceivedCallback: MessageReceivedCallback;
    },
    attempts: number = 0,
    maxAttempts: number = 3,
    maxNonDelayedAttempts: number = 1
  ): Promise<void> {
    const framesInStacks = orderedFrames.reduce((map, orderedFrame) => {
      if (!map.has(orderedFrame.stackSmid)) {
        map.set(orderedFrame.stackSmid, []);
      }
      map.get(orderedFrame.stackSmid)?.push(orderedFrame);
      return map;
    }, new Map());

    const stackResults = await this.downloadStacks(
      Array.from(framesInStacks.values()),
      orderedFrames,
      stackPriority,
      initialFocus,
      messageReceivedCallback
    );

    // these values are currently only used for single-stack loads, but it's better to return something
    // at least a little valid
    const minimumPriority = Math.min(
      ...Array.from(framesInStacks.keys()).map(
        (stackSmid) => this.#requestMap.get(stackSmid)?.priority ?? Infinity
      )
    );
    const minimumFocus = Math.min(
      ...Array.from(framesInStacks.keys()).map(
        (stackSmid) => this.#requestMap.get(stackSmid)?.focus ?? Infinity
      )
    );

    const erroredFrames = new Set(
      Array.from(stackResults.values()).flatMap((erroredFrames) => Array.from(erroredFrames))
    );
    const erroredStacks = Array.from(stackResults.entries())
      .filter(([stackSmid, erroredFrames]: [any, any]) => erroredFrames.size > 0)
      // @ts-expect-error [EN-7967] - TS2345 - Argument of type '([stackSmid]: [any]) => any' is not assignable to parameter of type '(value: [string, Set<string>], index: number, array: [string, Set<string>][]) => any'.
      .map(([stackSmid]: [any]) => stackSmid);
    const successfulStacks = Array.from(framesInStacks.keys()).filter(
      (stackSmid) => !erroredStacks.includes(stackSmid)
    );

    // remove successful stacks from the request map
    successfulStacks.forEach((stackSmid) => this.#requestMap.delete(stackSmid));

    // if we exceeded the max number of attempts, we should stop trying to load the frames
    if (attempts > maxAttempts) {
      messageReceivedCallback({
        event: 'transfer-error',
        error: 'Failed to fetch pixel data',
        stacks: erroredStacks,
      });
      return;
    }

    // if there are errored frames, we should try to load them again
    if (erroredFrames.size > 0) {
      if (attempts < maxNonDelayedAttempts) {
        // start from 1 second, double the delay each time, up to 10 seconds
        await sleep(exponentialBackoff(1000, 10000, attempts - maxNonDelayedAttempts));
      }

      await this.loadFrames(
        {
          orderedFrames: orderedFrames.filter((frame) => erroredFrames.has(frame.frameSmid)),
          initialFocus,
          isInitialFrame,
          stackPriority,
          isDropped,
          messageReceivedCallback,
        },
        attempts + 1
      );
      return;
    }

    // if there are no errored frames, we should notify the viewport that the transfer is complete
    messageReceivedCallback({
      requestId: '', // not actually used by the callback
      event: 'transfer-complete',
      framesTransfered: orderedFrames.length,
      endingPriority: minimumPriority,
      endingFocus: minimumFocus,
    });
  }

  updatePriority({
    stack,
    focusFrameIndex,
    stackPriority,
  }: {
    stack: FullSingleLayerStack;
    focusFrameIndex: number;
    stackPriority: number;
  }): void {
    const request = this.#requestMap.get(stack.smid);
    if (request != null) {
      request.priority = stackPriority;
      request.focus = focusFrameIndex;

      const matchingFrames = this.#fetchQueue.remove((f) => f.stackSmid === request.stackSmid);
      matchingFrames.forEach((frame) => {
        frame.priority = calculateFramePriority(frame, stackPriority, focusFrameIndex);
        this.#fetchQueue.enqueue(frame);
      });
    }
  }

  cancel(stackSmid?: string): void {
    if (stackSmid != null) {
      const elems = this.#fetchQueue.remove((f) => f.stackSmid === stackSmid);

      elems.forEach((f: QueuedFrame) => {
        f.deferred.resolve('cancelled');
      });
    } else {
      const elems = this.#fetchQueue.toArray();
      this.#fetchQueue.clear();

      elems.forEach((f: QueuedFrame) => {
        f.deferred.resolve('cancelled');
      });
    }
  }

  pause() {
    this.#paused = true;
  }

  resume() {
    this.#paused = false;
    for (let i = this.#inflightFrames.length; i < MAX_PARALLEL_REQUESTS; i++) {
      this.#processFetchQueue();
    }
  }

  /******************************************************************
   * Protected API
   ******************************************************************/

  async #processFetchQueue(): Promise<undefined> {
    // Check if the counter for parallel requests is less than the configured limit from ENV
    while (this.#fetchQueue.size() > 0 && !this.#paused) {
      const item = this.#fetchQueue.dequeue();
      if (item == null) return;

      const { url, includeCredentials, deferred, frameSmid, expirationTime } = item;
      if (expirationTime != null) {
        const expirationDate = new Date(expirationTime * 1000);
        const now = new Date();
        // Check for signed urls that have already expired.
        // We don't need to make a request that we know will fail.
        if (expirationDate < now) {
          deferred.resolve(null);
          // Remove the frame from the inflight list
          this.#inflightFrames.splice(
            this.#inflightFrames.findIndex((i) => item.frameSmid === i.frameSmid),
            1
          );

          return;
        }
      }

      if (url != null) {
        this.#inflightFrames.push({ frameSmid: item.frameSmid });

        // Initiate the fetch request
        try {
          const response = await fetchWithRetry(new URL(url.toString(), GRAPHQL_URL), {
            credentials: includeCredentials ? 'include' : 'omit',
          });
          trackCacheStatus(url, response);

          // Remove the frame from the inflight list
          this.#inflightFrames.splice(
            this.#inflightFrames.findIndex((i) => frameSmid === i.frameSmid),
            1
          );

          await response.arrayBuffer().then((arrayBuffer) => {
            // Resolve the promise
            deferred.resolve(arrayBuffer);
          });
        } catch (error: any) {
          // rejecting within async code sometimes escapes try-catch blocks
          // specifically, a single-frame-load error should not float to the viewport
          // (only process errors like signed urls or WebSocket errors)
          // instead we can just resolve null to represent the data
          // as long as we log the error ourselves
          deferred.resolve(null);

          // Remove the frame from the inflight list
          this.#inflightFrames.splice(
            this.#inflightFrames.findIndex((i) => item.frameSmid === i.frameSmid),
            1
          );

          if (expirationTime != null) {
            const expirationDate = new Date(expirationTime * 1000);
            const now = new Date();

            if (expirationDate < now) {
              // do not log error if the url expired mid-request
              return;
            }
          }

          logger.error(`Error fetching URL: ${url}`, error);
        }
      }
    }
  }

  #fetchPixelData({
    url,
    expirationTime,
    includeCredentials,
    frameSmid,
    priority,
    request,
  }: {
    url: string;
    expirationTime?: number;
    includeCredentials: boolean;
    frameSmid: string;
    priority: number;
    request: Request;
  }): Promise<ArrayBuffer | null | 'cancelled'> {
    // Create a deferred object
    const deferred = new Deferred<ArrayBuffer | null | 'cancelled'>();

    const sortIndex =
      request.orderedFrames.findIndex((orderedFrame) => orderedFrame.frameSmid === frameSmid) ?? 0;

    // Add the URL and deferred object to the queue
    this.#fetchQueue.enqueue({
      url,
      expirationTime,
      frameSmid,
      sortIndex,
      includeCredentials,
      deferred,
      priority,
      stackSmid: request.stackSmid,
    });

    if (MAX_PARALLEL_REQUESTS > this.#inflightFrames.length) {
      this.#processFetchQueue();
    }

    return deferred.promise;
  }

  queueSize(): number {
    return this.#fetchQueue.size();
  }
}

function calculateFramePriority(
  frame: OrderedFrame | QueuedFrame,
  requestPriority: number,
  requestFocus: number
) {
  const frameDistance = Math.abs(requestFocus - frame.sortIndex);
  return requestPriority + (frame.sortIndex % 5 === 0 ? frameDistance / 5 : frameDistance);
}
