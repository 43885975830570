import Cookie from 'js-cookie';
import { SRNA_PERSIST_TOKEN } from '../config/constants';

export const logout = () => {
  Object.keys(localStorage).forEach((key) => {
    // delete all keys that don't have the persist token
    if (!key.includes(SRNA_PERSIST_TOKEN)) {
      localStorage.removeItem(key);
    }
  });
  Object.keys(sessionStorage).forEach((key) => {
    // delete all keys that don't have the persist token
    if (!key.includes(SRNA_PERSIST_TOKEN)) {
      sessionStorage.removeItem(key);
    }
  });
};

export const isRefreshTokenProvided = (): boolean =>
  Cookie.get('refresh_token_provided') === 'true';
