import { env } from 'config/env';
import { nanoid } from 'nanoid';
import { BroadcastChannel } from 'broadcast-channel';

export let uniquePageId: string = nanoid();
const isWorker = typeof window === 'undefined';
const channel = new BroadcastChannel('active-window-tracking-channel');

// The following is needed to make sure the feature works with Fast Refresh on local dev builds
if (!isWorker && env.NODE_ENV === 'development') {
  if (window.__SIRONA_UNIQUE_PAGE_ID__ == null) {
    window.__SIRONA_UNIQUE_PAGE_ID__ = nanoid();
  }
  uniquePageId = window.__SIRONA_UNIQUE_PAGE_ID__;
}

/**
 * This class is used to track if the current window is active or not.
 * It will track the last focused page and inform other pages, it will
 * also listen to messages from other pages and update the `isWindowActive`
 * property accordingly.
 *
 * You can either read the current value of `isWindowActive` or add a listener
 * that will be called every time the value changes.
 */
class ActiveWindowListener {
  isWindowActive: boolean = false;

  // @ts-expect-error [EN-7967] - TS2322 - Type 'undefined[]' is not assignable to type '(isWindowActive: boolean) => void[]'.
  #listeners: (isWindowActive: boolean) => void[] = [];

  #callListeners() {
    // @ts-expect-error [EN-7967] - TS2339 - Property 'forEach' does not exist on type '(isWindowActive: boolean) => void[]'.
    this.#listeners.forEach((listener) => listener(this.isWindowActive));
  }

  #setWindowActive = (isWindowActive: boolean) => {
    this.isWindowActive = isWindowActive;
    channel.postMessage({ uniquePageId, isWindowActive });
    this.#callListeners();
  };

  constructor(uniquePageId: string) {
    if (isWorker) {
      return;
    }

    this.#setWindowActive(document.hasFocus());

    window.addEventListener('focus', () => {
      this.#setWindowActive(true);
    });

    document.body?.addEventListener('mouseover', () => {
      if (this.isWindowActive === false) {
        this.#setWindowActive(true);
      }
    });

    channel.addEventListener('message', ({ uniquePageId: id, isWindowActive: active }) => {
      if (id !== uniquePageId && active === true) {
        this.#setWindowActive(false);
      }
    });
  }

  addListener(callback: (isWindowActive: boolean) => void) {
    // @ts-expect-error [EN-7967] - TS2339 - Property 'push' does not exist on type '(isWindowActive: boolean) => void[]'.
    this.#listeners.push(callback);
  }

  removeListener(callback: (isWindowActive: boolean) => void) {
    // @ts-expect-error [EN-7967] - TS2339 - Property 'filter' does not exist on type '(isWindowActive: boolean) => void[]'.
    this.#listeners = this.#listeners.filter((listener) => listener !== callback);
  }
}

export const activeWindowListener: ActiveWindowListener = new ActiveWindowListener(uniquePageId);
