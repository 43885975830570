export class Deferred<T = unknown> {
  promise: Promise<T>;
  reject: (arg1?: Error | null | undefined) => void;
  resolve: (arg1: T) => void;
  fullfilled: boolean = false;

  constructor() {
    this.promise = new Promise(
      (resolve: (result: Promise<T> | T) => void, reject: (error?: any) => void) => {
        this.reject = reject;
        this.resolve = (...args) => {
          resolve(...args);
          this.fullfilled = true;
        };
      }
    );
  }
}

export default Deferred;
