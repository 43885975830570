import { Range } from 'domains/reporter/RichTextEditor/core';
import { stringifyRange } from 'domains/reporter/RichTextEditor/utils/stringify';

import { logger } from 'modules/logger';

export type MemoryInfo = {
  jsHeapSizeLimit: number;
  totalJSHeapSize: number;
  usedJSHeapSize: number;
};

export const createMarkForId = (markName: string, id: Range | string): string =>
  `${markName}-${typeof id === 'string' ? id : stringifyRange(id)}`;

/**
 * Performance wraps methods from the performance API
 * as some of them can throw runtime exceptions.
 */
export const performance = {
  now(): number {
    return window.performance.now();
  },
  mark(name: string, id?: Range | string): undefined {
    if (id != null) {
      window.performance.mark(createMarkForId(name, id));
    } else {
      window.performance.mark(name);
    }
  },
  measure(name: string, startMark?: string, endMark?: string): undefined {
    try {
      window.performance.measure(name, startMark, endMark);
    } catch (e: any) {
      logger.error('[performance] Error occurred while measuring performance', e);
    }
  },
  clearMarks(markName?: string): undefined {
    window.performance.clearMarks(markName);
  },
  clearMarksByName(markNames: string[]): undefined {
    markNames.forEach((mark) => performance.clearMarks(mark));
  },
  clearMeasures(measureName?: string): undefined {
    window.performance.clearMeasures(measureName);
  },
  hasEntry(name: string): boolean {
    return performance.getEntriesByName(name).length > 0;
  },
  getEntriesByName(name: string, type?: string): PerformanceEntryList {
    return window.performance.getEntriesByName(name, type);
  },
  getEntriesByType(type: string): PerformanceEntryList {
    return window.performance.getEntriesByType(type);
  },
  memory(): MemoryInfo | null | undefined {
    // @ts-expect-error [EN-7967] - TS2339 - Property 'memory' does not exist on type 'Performance'.
    return window.performance.memory;
  },
} as const;
