import type { Layer } from 'generated/graphql';
import { RendererSupport } from 'generated/graphql';
import type { BaseImagingProvider } from '../ViewportDre/modules/imaging/BaseImagingProvider';
import { MultiStackImagingProvider } from '../ViewportDre/modules/imaging/MultiStackImagingProvider';
import type { Series, Stack } from '../ViewportsConfigurations/types';
import type { TStackProviders } from './ViewerContext';
import { UNICODE_GROUP_SEPARATOR } from 'utils/string';

/** Returns true if the stack smid is a single layer stack */
export const isSingleLayerStack = (stack: Stack): boolean => {
  // @ts-expect-error [EN-7967] - TS2339 - Property 'frames' does not exist on type 'Stack'.
  return stack.frames != null;
};

/** Returns true if the stack smid is a multi-layer stack */
export const isStackSmidMultiLayer = (stackSmid?: string | null): boolean => {
  return stackSmid != null && stackSmid.includes(UNICODE_GROUP_SEPARATOR);
};

/** Returns true if the renderer is supported */
export const isSupportedRender = (stack: Stack | null | undefined, renderer: string): boolean => {
  if (stack && 'frames' in stack) {
    return (
      [RendererSupport.Supported, RendererSupport.BetaSupported].includes(
        stack.supportedRenderers[renderer]
      ) ?? false
    );
  }

  return true;
};

/** Returns true if the renderer is supported as a beta feature */
export const isBetaSupportedRender = (
  stack: Stack | null | undefined,
  renderer: string
): boolean => {
  // @ts-expect-error [EN-7967] - TS2339 - Property 'supportedRenderers' does not exist on type 'Stack'.
  if (stack?.supportedRenderers != null) {
    // @ts-expect-error [EN-7967] - TS2339 - Property 'supportedRenderers' does not exist on type 'Stack'.
    return stack.supportedRenderers[renderer] === RendererSupport.BetaSupported;
  }

  return false;
};

/** Returns true if the first frame has pixels */
export const isPixels = (stack?: Stack | null): boolean => {
  if (stack && 'frames' in stack) {
    return stack.frames[0].hasPixels;
    // @ts-expect-error [EN-7967] - TS2339 - Property 'stackLayers' does not exist on type 'Stack'.
  } else if (stack?.stackLayers != null) {
    return true;
  }

  return false;
};

/** Returns true if all frames has pixels */
export const allPixels = (stack?: Stack | null): boolean => {
  if (stack && 'frames' in stack) {
    return stack.frames.every((frame) => frame.hasPixels);
    // @ts-expect-error [EN-7967] - TS2339 - Property 'stackLayers' does not exist on type 'Stack'.
  } else if (stack?.stackLayers != null) {
    return true;
  }

  return false;
};

/** Returns the stack image, if it exists */
export const getStackImage = (stack: Stack): string => {
  // @ts-expect-error [EN-7967] - TS2339 - Property 'image' does not exist on type 'Stack'.
  if (stack?.image != null) {
    // @ts-expect-error [EN-7967] - TS2339 - Property 'image' does not exist on type 'Stack'.
    return stack.image;
  }

  return '';
};

/** Finds a series by stack smid */
export const findSeriesByStackSmid = (
  stackSmid: string,
  imagingProvider?: BaseImagingProvider<Stack> | null
): Series | null | undefined => {
  if (imagingProvider == null) return;
  // @ts-expect-error [EN-7967] - TS2339 - Property 'series' does not exist on type '{ readonly __typename?: "LayeredStack"; readonly smid: string; readonly type: string; readonly study: { readonly __typename?: "Study"; readonly smid: string; }; readonly stackLayers: readonly { readonly __typename?: "Layer"; readonly stackSmid: string; readonly index: number; }[]; } | { ...; }'.
  const seriesSmid = imagingProvider.study.stackedFrames.find((s) => s.smid === stackSmid)?.series
    ?.smid;
  return imagingProvider.study.seriesList.find((s) => s.smid === seriesSmid);
};

/**
 * Encodes a stackSmid from the base and overlay viewport configurations
 */
export const encodeOverlayStackSmid = (baseStackSmid: string, overlayStackSmid: string): string => {
  return `${baseStackSmid}${UNICODE_GROUP_SEPARATOR}${overlayStackSmid}`;
};

/**
 * Decodes a overlaid stackSmid as a `stackLayers` object
 */
export const decodeOverlayStackSmidToStackLayers = (stackSmid: string): Layer[] =>
  stackSmid.split(UNICODE_GROUP_SEPARATOR).map((stackSmid: string, index: number) => ({
    stackSmid,
    index,
  }));

/** Checks if the stackSmid provided is present in any MultiStackImagingProvider */
export const isStackSmidInAnyMultiLayer = (
  stackSmid: string,
  stackProviders: TStackProviders
): boolean => {
  return Array.from(stackProviders).some(
    ([, provider]: [any, any]) =>
      provider instanceof MultiStackImagingProvider &&
      provider.getRefProviderByStackSmid(stackSmid) != null
  );
};

/** Checks if any multi-stack ImagingProvider instance is present */
export const isAnyMultiLayerRendered = (stackProviders: TStackProviders): boolean => {
  return Array.from(stackProviders).some(
    ([, provider]: [any, any]) => provider instanceof MultiStackImagingProvider
  );
};
