import { AppRoutePath } from '../config/constants';

import { matchPath } from 'react-router-dom';

export const PAGE_TYPES: {
  HOME: 'home';
  VIEWER: 'viewer';
  REPORTER: 'reporter';
  WORKLIST: 'worklist';
  PATIENT_JACKET: 'patient-jacket';
  URT: 'urt';
  PRODUCTPORTAL: 'productportal';
  LOGIN: 'login';
  ADMIN: 'admin';
  UNKNOWN: 'unknown';
} = Object.freeze({
  HOME: 'home',
  VIEWER: 'viewer',
  REPORTER: 'reporter',
  WORKLIST: 'worklist',
  PATIENT_JACKET: 'patient-jacket',
  URT: 'urt',
  PRODUCTPORTAL: 'productportal',
  LOGIN: 'login',
  ADMIN: 'admin',
  UNKNOWN: 'unknown',
});
export type PageTypes = (typeof PAGE_TYPES)[keyof typeof PAGE_TYPES];

/**
 * Returns the pathname of the URL
 * @param {*} str Accepts either an absolute URl a full URL (i.e. location.href) or just a path (i.e. location.pathname or from the PATH constant object)
 * @returns {string} Returns the pathname of the URL. str is returned if it is not a valid URL
 */
export function getPathName(str: string): string {
  try {
    // Assume str is a path if it starts with a forward slash
    return new URL(str.startsWith('/') ? `${window.location.origin}${str}` : str).pathname;
  } catch (err: any) {
    return str;
  }
}

export function getPageType(url: string): PageTypes {
  const pathname = getPathName(url);

  if (matchPath(AppRoutePath.HOME, pathname)) {
    return PAGE_TYPES.HOME;
  } else if (matchPath(AppRoutePath.VIEWER, pathname)) {
    return PAGE_TYPES.VIEWER;
  } else if (matchPath(AppRoutePath.REPORTER, pathname)) {
    return PAGE_TYPES.REPORTER;
  } else if (matchPath(AppRoutePath.PATIENT_JACKET, pathname)) {
    return PAGE_TYPES.WORKLIST;
  } else if (matchPath(AppRoutePath.WORKLIST, pathname)) {
    return PAGE_TYPES.WORKLIST;
  } else if (matchPath(AppRoutePath.PATIENT_JACKET_UNDOCKED, pathname)) {
    return PAGE_TYPES.PATIENT_JACKET;
  } else if (matchPath(AppRoutePath.URT, pathname)) {
    return PAGE_TYPES.URT;
  } else if (matchPath(AppRoutePath.PRODUCTPORTAL, pathname)) {
    return PAGE_TYPES.PRODUCTPORTAL;
  } else if (matchPath(AppRoutePath.LOGIN, pathname)) {
    return PAGE_TYPES.LOGIN;
  } else if (matchPath(AppRoutePath.ADMIN, pathname)) {
    return PAGE_TYPES.ADMIN;
  } else {
    return PAGE_TYPES.UNKNOWN;
  }
}
