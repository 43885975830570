import type { Stack } from '../domains/viewer/ViewportsConfigurations/types';
import type { FrameDataFragment } from '../generated/graphql';
import type { OrderedFrame } from '../modules/viewer/imageloaders/BaseImageLoader';

export const mapToOrderedFrame = (
  frame: FrameDataFragment,
  frameIndex: number,
  stack: Stack
): OrderedFrame => {
  const mappedFrame: OrderedFrame = {
    frameSmid: frame.smid,
    isMultiFrame: stack.type === 'multi-frame',
    seriesSmid: frame.series.smid,
    sortIndex: frameIndex,
    stackSmid: stack.smid,
    modules: {},
  };

  if (frame.modules?.modalityLut != null) {
    mappedFrame.modules.modalityLut = frame.modules.modalityLut;
  }

  return mappedFrame;
};
