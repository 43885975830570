// @ts-expect-error [EN-7967] - TS2305 - Module '"@datadog/browser-rum"' has no exported member 'RumContext'. | TS2305 - Module '"@datadog/browser-rum"' has no exported member 'Context'.
import type { RumEvent, RumContext, Context } from '@datadog/browser-rum';

export function apolloResourceBeforeSend(
  event: RumEvent,
  context: RumContext
  // @ts-expect-error [EN-7967] - TS2355 - A function whose declared type is neither 'undefined', 'void', nor 'any' must return a value.
): boolean | undefined {
  if (event.type === 'resource' && event.resource.url.endsWith('/graphql')) {
    const { requestInit } = context;
    if (requestInit != null) {
      const { body } = requestInit;
      if (body != null) {
        const bodyJson = JSON.parse(body);
        const { operationName } = bodyJson;

        event.context = {
          // $FlowFixMe[incompatible-cast] datadog types are not great
          ...(event.context as Context),
          apollo: {
            operationName,
          },
        };
      }
    }
  }
}
