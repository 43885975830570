import gql from 'graphql-tag';
import type { DocumentNode } from 'graphql';

export const PATIENT_FIELDS: DocumentNode = gql`
  fragment patientFields on Study {
    patientSex
    patientAge
    patientName
    patientDob
    modality
    bodyPart
    mrn
    studyDate
    laterality
  }
`;

export const ADDENDUM_FIELDS: DocumentNode = gql`
  fragment addendumFields on Addendum {
    created
    updated
    submittedAt
    sentAt
    smid
    content {
      ... on AddendumSironaSlate {
        sironaSlate
      }
      ... on AddendumTextBlob {
        textBlob
      }
    }
    owner {
      id
      firstName
      lastName
      email
    }
  }
`;

export const FRAME_DATA_MODULES: DocumentNode = gql`
  fragment moduleData on Modules {
    cine {
      frameTimeVector
      preferredPlaybackSequencing
    }
    multiFrame {
      numberOfFrames
      frameIncrementPointer
    }
    regionCalibration {
      regions {
        ... on Region {
          spatialFormat
          dataType
          flags {
            highPriority
            scalingProtection
          }
          location
          physicalDelta
          physicalUnits
        }
      }
    }
    modalityLut {
      rescaleIntercept
      rescaleSlope
      rescaleType
      modalityLutSequence {
        lutDescriptor
        modalityLutType
        lutData
      }
    }
    patientStudy {
      patientWeight
    }
    petImage {
      correctedImage
      decayCorrection
      units
      startDateTime
      scanDateTime
      radiopharmaceuticalInformation {
        radiopharmaceuticalTotalDose
        radionuclideHalfLife
      }
      frameReferenceTime
      suvScaleFactor
      preCalculatedSuvBwFactor
    }
  }
`;

export const FRAME_DATA_FIELDS: DocumentNode = gql`
  fragment frameData on Frame {
    smid
    series {
      smid
    }
    stack {
      ... on SingleLayerStack {
        smid
      }
      ... on LayeredStack {
        smid
      }
    }
    sopInstanceUID
    sopClassUID
    colorChannels
    direction {
      rows
      columns
      data
      validDirection
    }
    modules {
      ...moduleData
    }
    needsInversion
    imageNumber
    origin
    spacing
    size
    hasPixels
    validSpacing
  }

  ${FRAME_DATA_MODULES}
`;

export const SERIES_DATA_FIELDS: DocumentNode = gql`
  fragment seriesData on Series {
    smid
    study {
      smid
    }
    description
    seriesInstanceUID
    seriesDateTimeLocal
    frameOfReferenceUID
    seriesNumber
    is3Dable
    classifications {
      seriesCriteria {
        anatomic_focus {
          display_name
          ontology_id
        }
      }
      seriesDescription
      studySmid
      seriesSmid
      seriesNumber
      seriesDate
      seriesTime
    }
    imageRegistrations {
      smid
      created
      codeVersion
      modelName
      modelVersion
      seriesFixed
      seriesMoving
      dataType
      fixedFrameOfReferenceUid
      movingFrameOfReferenceUid
      transformMatrix
    }
  }
`;

export const STUDY_DATA_FIELDS_WITHOUT_SERIES: DocumentNode = gql`
  fragment studyDataWithoutSeries on Study {
    smid
    created
    updated
    instanceUID
    description
    hl7Order
    ...patientFields
    provenanceData {
      matchType
      relevant
    }
  }

  ${PATIENT_FIELDS}
`;

export const STUDY_DATA_FIELDS_WITHOUT_SERIES_AND_PROVENANCE_DATA: DocumentNode = gql`
  fragment studyDataWithoutSeriesAndProvenanceData on Study {
    smid
    created
    updated
    instanceUID
    description
    hl7Order
    ...patientFields
  }

  ${PATIENT_FIELDS}
`;

export const STACKED_FRAME_FIELDS: DocumentNode = gql`
  fragment stackedFrame on SingleLayerStack {
    smid
    type
    image
    series {
      smid
    }
    is3Dable
    study {
      smid
    }
    frames # { ...frameData }
    supportedRenderers {
      vtk
    }
    imageParams {
      origin
      spacing
      dimensions
      extent
      direction
      isMultiAxes
      colorWindow
      colorLevel
    }
  }
  fragment layeredStack on LayeredStack {
    smid
    type
    study {
      smid
    }
    stackLayers {
      stackSmid
      index
    }
  }

  ${FRAME_DATA_FIELDS}
`;

export const STUDY_DATA_FIELDS: DocumentNode = gql`
  fragment studyData on Study {
    smid
    created
    updated
    instanceUID
    description
    hl7Order
    accessionNumber
    ...patientFields
    seriesCount
    seriesList {
      ...seriesData
    }
    stackedFrames {
      ...stackedFrame
      ...layeredStack
    }
  }

  ${PATIENT_FIELDS}
  ${SERIES_DATA_FIELDS}
  ${STACKED_FRAME_FIELDS}
`;

export const TABLE_WORKLIST_FIELDS: DocumentNode = gql`
  fragment tableWorklistItemFields on WorklistItem {
    smid
    studies {
      smid
    }
    created
    updated
    studyDate
    submittedAt
    patientName
    patientAge
    patientSex
    patientDob
    patientType
    accessionNumber
    hasQcComments
    bodyPart
    modality
    referringPhysician
    referringPhysicianLastName
    referringPhysicianFirstName
    referringPhysicianMiddleName
    site
    status
    isActive
    claimedBy {
      id
      firstName
      lastName
    }
    mrn
    priority
    studyDescription
    report {
      owner {
        firstName
        lastName
      }
      smid
      submittedAt
      sentAt
      addendums {
        ...addendumFields
      }
    }
    dosage
    studyReason
    techNotes
    isStat
    groupId
    customStatus {
      smid
      name
    }
    hasDraftReport
    dicomInstanceCount
    firstVerifiedStatusDate
  }

  ${ADDENDUM_FIELDS}
`;

export const WORKLIST_ITEM_ANALYTICS_FIELDS: DocumentNode = gql`
  fragment worklistItemAnalyticsFields on WorklistItem {
    smid
    modality
    bodyPart
    studyDescription
  }
`;

export const WORKLIST_FIELDS_BASE: DocumentNode = gql`
  fragment worklistItemBaseFields on WorklistItem {
    smid
    mrn
    status
    isActive
    hasQcComments
    dosage
    studyReason
    studyDescription
    studyDate
    procedure {
      smid
      description
      code
    }
    referringPhysician
    accessionNumber
    techNotes
    patientName
    patientDob
    patientAge
    patientSex
    date
    customMergeFields {
      key
      value
    }
    claimedBy {
      id
      firstName
      lastName
    }
    report {
      smid
      owner {
        firstName
        lastName
      }
      submittedAt
      sentAt
      updated
      version
      sentAt
      content
      addendums {
        ...addendumFields
      }
    }
    groupId
    modality
    customStatus {
      smid
      name
    }
    hasDraftReport
    examCount
  }

  ${ADDENDUM_FIELDS}
`;

export const WORKLIST_FIELDS: DocumentNode = gql`
  fragment worklistItemFields on WorklistItem {
    ...worklistItemBaseFields
    studies {
      ...studyDataWithoutSeries
    }
  }
  ${WORKLIST_FIELDS_BASE}
  ${STUDY_DATA_FIELDS_WITHOUT_SERIES}
`;

export const WORKLIST_FIELDS_WITHOUT_PROVENANCE_DATA: DocumentNode = gql`
  fragment worklistItemFieldsWithoutProvenanceData on WorklistItem {
    ...worklistItemBaseFields
    studies {
      ...studyDataWithoutSeriesAndProvenanceData
    }
  }

  ${WORKLIST_FIELDS_BASE}
  ${STUDY_DATA_FIELDS_WITHOUT_SERIES_AND_PROVENANCE_DATA}
`;

export const MACRO_FIELDS: DocumentNode = gql`
  fragment macroFields on Macro {
    smid
    name
    triggerPhrase
    text
    created
    updated
    # RP-3210
    # This should be a recursive field, but we don't have a way to express that in GraphQL queries.
    # e.g. macroFields = { ..., nestedMacros { ...macroFields } }
    # We've added this here to satisfy the type checker.
    nestedMacros {
      smid
      created
      updated
      name
      triggerPhrase
      text
    }
    procedures {
      smid
      description
      code
    }
    owner {
      id
      firstName
      lastName
    }
    picklists {
      id
      options {
        id
        name
        text
        default
      }
    }
  }
`;

export const REPORT_FIELDS: DocumentNode = gql`
  fragment reportFields on Report {
    smid
    submittedAt
    sentAt
    sections
    content
    sectionsLastUpdated
    macros {
      ...macroFields
    }
    version
  }

  ${MACRO_FIELDS}
`;

export const WINDOW_STATE_FIELDS: DocumentNode = gql`
  fragment workspacePresetsFields on WorkspacePreset {
    smid
    instanceId
    name
    createdAt
    updatedAt
    windows {
      id
      width
      height
      left
      top
      screen
      type
      state
    }
  }
`;

export const AUTOCORRECT_ENTRY_FIELDS: DocumentNode = gql`
  fragment autoCorrectEntryFields on AutoCorrectEntry {
    id
    key
    value
  }
`;

export const VOCAB_ENTRY_FIELDS: DocumentNode = gql`
  fragment vocabEntryFields on VocabEntry {
    id
    phrase
  }
`;

export const WORKLIST_SETTINGS: DocumentNode = gql`
  fragment worklistSettings on WorklistSettings {
    columns {
      id
      width
      enabled
    }
    columnsBySurface {
      surface
      columns {
        id
        width
        enabled
      }
    }
    openPatientJacketOnRead
    isPatientJacketDocked
    favoritedWorklistViews
    savedWorklistViews {
      surface
      views
    }
    worklistAutoLoad {
      surface
    }
    patientJacketLayout
    savedSortingPreferences {
      surface
      searchParams
      worklistViewID
    }
    lastViewedCustomWorklists {
      surface
      worklistViewID
    }
    defaultCustomWorklistsSettings {
      isEnabled
      defaultCustomWorklists {
        surface
        worklistViewID
      }
    }
  }
`;

export const REPORT_TEMPLATE_FIELDS: DocumentNode = gql`
  fragment reportTemplateFields on ReportTemplate {
    id
    name
    triggerPhrase
    created
    content
    sections
    picklists {
      id
      options {
        id
        name
        text
        default
      }
    }
    macros {
      ...macroFields
    }
    procedureReferences {
      smid
      description
      code
      autoload
    }
  }

  ${MACRO_FIELDS}
`;

export const GET_REPORTER_SETTINGS: DocumentNode = gql`
  fragment getReporterSettings on ReporterSettings {
    triggerWord
    aiMode {
      findingsMapperEnabled
      formatImpressionGenerationAsList
      onDemandImpressionGeneration
    }
    mergeFieldsSettings {
      ignoreNavigation
      ignoreDefaultSelection
    }
    styles {
      bodyStyle {
        fontSize
        fontFamily
        textTransform
        textDecoration {
          isBold
          isItalic
          isUnderline
        }
      }
      headingStyles {
        level
        textStyle {
          fontSize
          textTransform
          textDecoration {
            isBold
            isItalic
            isUnderline
          }
        }
      }
    }
    cursorStyle {
      color
    }
    lineIndicator {
      enabled
      placement
      variant
    }
    impressionGenerator {
      enabled
      automatic
      modalities
    }
    autoFillComparison {
      enabled
    }
    focusMode {
      enabled
    }
  }
`;

export const GET_TOOL_PREFERENCES: DocumentNode = gql`
  query getToolPreferences {
    toolPreferences {
      mouse {
        left
        middle
        right
      }
      regularScroll {
        sensitivity
      }
      fastScroll {
        sensitivity
      }
      fineScroll {
        enabled
      }
      wheel {
        toolIds
      }
      keyboard {
        shortcuts {
          id
          key
          interactionId
        }
      }
      bar {
        toolIds
      }
      wwwc {
        presets {
          id
          name
          width
          level
          shortcut
        }
        invertDragDirection
      }
      dictaphone {
        keyMapping {
          id
          key
        }
        deviceLabel
        recordWhilePressed
        showPicklistOptionsInEditor
      }
      workspaces {
        presets {
          ...workspacePresetsFields
        }
        autoApply
      }
      resetToolOnScroll {
        enabled
      }
      mirrorViewerLayouts {
        enabled
      }
      showIconLabel {
        enabled
      }
      invertScroll
    }
  }

  ${WINDOW_STATE_FIELDS}
`;

export const GET_DEFAULT_AUTOLOAD_TEMPLATE_FRAGMENT: DocumentNode = gql`
  fragment GetDefaultAutoloadTemplateFragment on User {
    id
    defaultAutoloadTemplate {
      ...reportTemplateFields
    }
  }

  ${REPORT_TEMPLATE_FIELDS}
`;

export const GET_ME_FRAGMENT: DocumentNode = gql`
  fragment GetMeFragment on User {
    id
    firstName
    lastName
    email
    physicianId
    nvoqCredentials {
      username
      password
    }
    preferredWorkLocation {
      hospital
      home
    }
    viewerSettings {
      showManualLocalizerLines
      virtualMonitorSplit
      eraseAllToolScope
      defaultThumbnailSizePx
      customDicomTags {
        modality
        modalityDicomTags {
          id
          tagId
          enabled
          label
        }
      }
      seriesSkipSettings {
        amount
        keepPreviousWhenAvailable
      }
    }
    securitySettings {
      multiFactorAuth {
        enabled
        linked
      }
    }
    sessionTimeoutInMinutes
    worklistSettings {
      ...worklistSettings
    }
    reporterSettings {
      ...getReporterSettings
    }
    anatomicNavigatorEnabled
    stackInstancesByType
    stackedModalities
    screenShareEnabled
    clinic {
      smid
      name
    }
    autoCorrect {
      id
      key
      value
    }
    roles {
      name
      scope {
        name
        object_id
      }
      permissions {
        name
        component
      }
    }
  }

  ${GET_REPORTER_SETTINGS}
  ${WORKLIST_SETTINGS}
`;

export const GET_USERS: DocumentNode = gql`
  query getUsers($cursor: ID, $search: String, $hasMacros: Boolean, $hasTemplates: Boolean) {
    users(search: $search, cursor: $cursor, hasMacros: $hasMacros, hasTemplates: $hasTemplates) {
      next
      items {
        firstName
        lastName
        email
        id
      }
    }
  }
`;

export const GET_ME: DocumentNode = gql`
  query getMe {
    me {
      id
      firstName
      lastName
      email
      physicianId
      nvoqCredentials {
        username
        password
      }
      preferredWorkLocation {
        hospital
        home
      }
      viewerSettings {
        showManualLocalizerLines
        virtualMonitorSplit
        eraseAllToolScope
        defaultThumbnailSizePx
        customDicomTags {
          modality
          modalityDicomTags {
            id
            tagId
            enabled
            label
          }
        }
        absoluteScroll
        seriesSkipSettings {
          amount
          keepPreviousWhenAvailable
        }
      }
      securitySettings {
        multiFactorAuth {
          enabled
          linked
        }
      }
      sessionTimeoutInMinutes
      worklistSettings {
        ...worklistSettings
      }
      reporterSettings {
        ...getReporterSettings
      }
      anatomicNavigatorEnabled
      stackInstancesByType
      stackedModalities
      screenShareEnabled
      clinic {
        smid
        name
        isRbacEnabled
      }
      autoCorrect {
        id
        key
        value
      }
      roles {
        name
        scope {
          name
          object_id
        }
        permissions {
          name
          component
        }
      }
      ...GetDefaultAutoloadTemplateFragment
    }
  }

  ${WORKLIST_SETTINGS}
  ${GET_REPORTER_SETTINGS}
  ${GET_DEFAULT_AUTOLOAD_TEMPLATE_FRAGMENT}
`;

export const AUTHENTICATE: DocumentNode = gql`
  mutation authenticate($email: String!, $password: String!) {
    authenticate(email: $email, password: $password) {
      ...GetMeFragment
      ... on MFARequired {
        mfaSessionId
      }
    }
  }

  ${GET_ME_FRAGMENT}
`;

export const AUTHENTICATE_MFA: DocumentNode = gql`
  mutation authenticateMFA($email: String!, $totp: String!, $mfaSessionId: String!) {
    authenticateMFA(email: $email, totp: $totp, mfaSessionId: $mfaSessionId) {
      ...GetMeFragment
    }
  }

  ${GET_ME_FRAGMENT}
`;

export const UNAUTHENTICATE: DocumentNode = gql`
  mutation unauthenticate {
    unauthenticate
  }
`;

export const ASSIGN_DEFAULT_AUTOLOAD_TEMPLATE: DocumentNode = gql`
  mutation AssignDefaultAutoloadTemplate($input: AssignDefaultAutoloadTemplateInput!) {
    assignDefaultAutoloadTemplate(input: $input) {
      me {
        ...GetDefaultAutoloadTemplateFragment
      }
    }
  }

  ${GET_DEFAULT_AUTOLOAD_TEMPLATE_FRAGMENT}
`;

export const TOGGLE_ANATOMIC_NAVIGATOR: DocumentNode = gql`
  mutation toggleAnatomicNavigator($anatomicNavigatorEnabled: Boolean!) {
    toggleAnatomicNavigator(anatomicNavigatorEnabled: $anatomicNavigatorEnabled) {
      anatomicNavigatorEnabled
    }
  }
`;

export const TOGGLE_STACKED_SCROLLING: DocumentNode = gql`
  mutation toggleStackedScrolling($stackInstancesByType: Boolean!) {
    toggleStackedScrolling(stackInstancesByType: $stackInstancesByType) {
      stackInstancesByType
    }
  }
`;

export const TOGGLE_ABSOLUTE_SCROLL: DocumentNode = gql`
  mutation toggleAbsoluteScroll($absoluteScroll: Boolean!) {
    toggleAbsoluteScroll(absoluteScroll: $absoluteScroll) {
      absoluteScroll
    }
  }
`;

export const UPDATE_STACKED_MODALITIES: DocumentNode = gql`
  mutation updateStackedModalities($stackedModalities: [String!]!) {
    updateStackedModalities(stackedModalities: $stackedModalities) {
      stackedModalities
    }
  }
`;

export const TOGGLE_MANUAL_LOCALIZER_LINES: DocumentNode = gql`
  mutation toggleManualLocalizerLines($showManualLocalizerLines: Boolean!) {
    toggleManualLocalizerLines(showManualLocalizerLines: $showManualLocalizerLines) {
      showManualLocalizerLines
    }
  }
`;

export const TOGGLE_ERASE_ALL_TOOL_SCOPE: DocumentNode = gql`
  mutation toggleEraseAllToolScope($eraseAllToolScope: EraseAllToolScope) {
    toggleEraseAllToolScope(eraseAllToolScope: $eraseAllToolScope) {
      eraseAllToolScope
    }
  }
`;

export const SET_DEFAULT_THUMBNAIL_SIZE: DocumentNode = gql`
  mutation setDefaultThumbnailSize($thumbnailSizePx: Int!) {
    setDefaultThumbnailSize(thumbnailSizePx: $thumbnailSizePx) {
      thumbnailSizePx
    }
  }
`;

export const TOGGLE_VIRTUAL_MONITOR_SPLIT: DocumentNode = gql`
  mutation toggleVirtualMonitorSplit($virtualMonitorSplit: VirtualMonitorSplit) {
    toggleVirtualMonitorSplit(virtualMonitorSplit: $virtualMonitorSplit) {
      virtualMonitorSplit
    }
  }
`;

export const UPDATE_SERIES_SKIP_AMOUNT_SETTINGS: DocumentNode = gql`
  mutation updateSeriesSkipSettings($seriesSkipSettings: SeriesSkipSettingsPayload) {
    updateSeriesSkipSettings(seriesSkipSettings: $seriesSkipSettings) {
      seriesSkipSettings {
        amount
        keepPreviousWhenAvailable
      }
    }
  }
`;

export const UPDATE_CUSTOM_DICOM_TAGS: DocumentNode = gql`
  mutation updateCustomDicomTags(
    $modality: String!
    $modalityDicomTags: [DicomTagRenderInfoInput!]!
  ) {
    updateCustomDicomTags(modality: $modality, modalityDicomTags: $modalityDicomTags) {
      modality
      modalityDicomTags {
        id
        tagId
        enabled
        label
      }
    }
  }
`;

export const GET_LAST_VIEWED_WORKLISTS: DocumentNode = gql`
  query getLastViewedWorklists {
    me {
      id
      lastViewedWorklists {
        ...worklistItemFields
      }
    }
  }

  ${WORKLIST_FIELDS}
`;

export const GET_HEADING_KEYWORDS: DocumentNode = gql`
  query getHeadingKeywords {
    headingKeywords {
      exam
      history
      technique
      findings
      comparison
      impressions
    }
  }
`;

export const UPDATE_LAST_VIEWED_WORKLISTS: DocumentNode = gql`
  mutation updateLastViewedWorklists($smids: [ID!]!) {
    updateLastViewedWorklists(smids: $smids) {
      lastViewedWorklists {
        smid
      }
    }
  }
`;

export const UPDATE_WORKLIST_SETTINGS: DocumentNode = gql`
  mutation updateWorklistSettings(
    $columns: [WorklistColumnInput!]
    $favoritedWorklistViews: [ID!]
    $savedWorklistViews: [SavedWorklistViewInput!]
    $savedSortingPreferences: [SavedSortingPreferenceInput!]
    $openPatientJacketOnRead: Boolean
    $isPatientJacketDocked: Boolean
    $columnsBySurface: [WorklistColumnsBySurfaceInput!]
    $worklistAutoLoad: WorklistAutoLoadInput
    $patientJacketLayout: JSON
    $lastViewedCustomWorklists: [DefaultCustomWorklistInput!]
    $defaultCustomWorklistsSettings: DefaultCustomWorklistsSettingInput
  ) {
    updateWorklistSettings(
      columns: $columns
      favoritedWorklistViews: $favoritedWorklistViews
      savedWorklistViews: $savedWorklistViews
      savedSortingPreferences: $savedSortingPreferences
      openPatientJacketOnRead: $openPatientJacketOnRead
      isPatientJacketDocked: $isPatientJacketDocked
      columnsBySurface: $columnsBySurface
      worklistAutoLoad: $worklistAutoLoad
      patientJacketLayout: $patientJacketLayout
      lastViewedCustomWorklists: $lastViewedCustomWorklists
      defaultCustomWorklistsSettings: $defaultCustomWorklistsSettings
    ) {
      ...worklistSettings
    }
  }
  ${WORKLIST_SETTINGS}
`;

export const GET_ALL_ANNOTATIONS: DocumentNode = gql`
  query getAllAnnotations($worklistIds: [ID!], $studyIds: [ID!], $query: JSON) {
    annotations(worklistIds: $worklistIds, studyIds: $studyIds, query: $query) {
      smid
      type
      source
      seriesSmid
      aiAnnotationSmid
      pixel_space_representation
      data
      customLogic {
        id
        logic
      }
      ... on GspsAnnotation {
        created
        owner {
          firstName
          lastName
        }
        presentationState {
          smid
        }
      }
    }
  }
`;

export const CREATE_ANNOTATION: DocumentNode = gql`
  mutation createAnnotation(
    $seriesSmid: ID!
    $type: String!
    $aiAnnotationSmid: ID
    $pixel_space_representation: JSON
    $data: JSON!
  ) {
    createAnnotation(
      seriesSmid: $seriesSmid
      type: $type
      aiAnnotationSmid: $aiAnnotationSmid
      pixel_space_representation: $pixel_space_representation
      data: $data
    ) {
      smid
      type
      aiAnnotationSmid
      pixel_space_representation
      data
      customLogic {
        id
        logic
      }
    }
  }
`;

export const CREATE_PRESENTATION_STATE: DocumentNode = gql`
  mutation createPresentationState($name: String!, $annotationSmids: [ID!]!) {
    createPresentationState(name: $name, annotationSmids: $annotationSmids) {
      name
      annotationSmids
    }
  }
`;

export const UPDATE_ANNOTATION: DocumentNode = gql`
  mutation updateAnnotation($smid: ID!, $pixel_space_representation: JSON, $data: JSON!) {
    updateAnnotation(
      smid: $smid
      pixel_space_representation: $pixel_space_representation
      data: $data
    ) {
      smid
      type
      aiAnnotationSmid
      pixel_space_representation
      data
      customLogic {
        id
        logic
      }
    }
  }
`;

export const GET_WORKLIST_ITEM_ANALYTICS: DocumentNode = gql`
  query getWorklistItemAnalytics($smid: ID!) {
    worklistItem(id: $smid) {
      ...worklistItemAnalyticsFields
    }
  }
  ${WORKLIST_ITEM_ANALYTICS_FIELDS}
`;

export const GET_WORKLIST_ITEM: DocumentNode = gql`
  query getWorklistItem($smid: ID!) {
    worklistItem(id: $smid) {
      ...worklistItemFields
    }
  }

  ${WORKLIST_FIELDS}
`;

export const GET_WORKLIST_ITEM_BY_STUDY_SMID: DocumentNode = gql`
  query getWorklistItemByStudySmid($smid: ID!) {
    worklistItemByStudySmid(smid: $smid) {
      ...worklistItemFieldsWithoutProvenanceData
    }
  }

  ${WORKLIST_FIELDS_WITHOUT_PROVENANCE_DATA}
`;

export const ASSIGN_EXAM_COUNT: DocumentNode = gql`
  mutation AssignExamCount($input: AssignExamCountInput!) {
    assignExamCount(input: $input) {
      smid
      examCount
    }
  }

  ${WORKLIST_FIELDS}
`;

export const ASSIGN_EXAMS_TO_USER: DocumentNode = gql`
  mutation AssignExamsToUser($userSmid: ID!, $worklistItemSmids: [ID!]!) {
    assignExamsToUser(userSmid: $userSmid, worklistItemSmids: $worklistItemSmids) {
      assignedExams {
        smid
        claimedBy {
          id
          firstName
          lastName
        }
      }
      failedExams {
        smid
        errorReason
      }
    }
  }
`;

export const GET_WORKLIST_ITEM_AUDIT_LOGS: DocumentNode = gql`
  query getWorklistItemAuditLogs($smid: ID!) {
    worklistItemAuditLogs(id: $smid) {
      actor {
        firstName
        lastName
      }
      claimedBy {
        firstName
        lastName
      }
      createdAt
      customStatus
      groupId
      status
    }
  }
`;

export const GET_WORKLIST_CUSTOM_STATUSES: DocumentNode = gql`
  query getWorklistCustomStatuses {
    worklistCustomStatuses {
      smid
      name
    }
  }
`;

export const GET_PATIENT_JACKET: DocumentNode = gql`
  query GetPatientJacket($smid: ID!, $aiMode: Boolean!) {
    worklistItem(id: $smid) {
      mrn
      summary @include(if: $aiMode) {
        patientHistory
        relevantFindings
      }
    }
  }
`;

export const GET_WORKLIST: DocumentNode = gql`
  query getWorklist(
    $cursor: ID
    $limit: Int
    $sortOrder: [SortOrder!]
    $sortColumn: [SortColumn!]
    $claimedBy: ID
    $site: [String]
    $patientType: [String]
    $patientSex: [String]
    $patientDob: [DateTime]
    $modality: [String]
    $priority: [String]
    $status: WorklistItemStatus
    $isActive: Boolean
    $filter: String
    $smids: [ID!]
    $studyDate: [DateTime]
    $submittedAt: [DateTime]
    $customStatus: [String]
    $mrn: [String]
    $mrnPartial: [String]
    # TODO: It may be appropriate to update the graphQL types so that accessionNumber is not nullable
    $accessionNumber: [String]
    $accessionNumberPartial: [String]
    $activeViews: [ID!]
    $issuer: [String!]
    $hasDraftReport: Boolean
    $studyDescription: [String]
    $bodyPart: [String]
    $patientName: [String]
    $referringPhysician: [String]
    $priorityFilter: [String]
  ) {
    worklistItems(
      limit: $limit
      cursor: $cursor
      sortOrder: $sortOrder
      sortColumn: $sortColumn
      statsOnTop: false
      claimedBy: $claimedBy
      site: $site
      patientType: $patientType
      patientSex: $patientSex
      patientDob: $patientDob
      modality: $modality
      priority: $priority
      status: $status
      isActive: $isActive
      filter: $filter
      smids: $smids
      studyDate: $studyDate
      submittedAt: $submittedAt
      customStatus: $customStatus
      mrn: $mrn
      mrnPartial: $mrnPartial
      accessionNumber: $accessionNumber
      accessionNumberPartial: $accessionNumberPartial
      activeViews: $activeViews
      issuer: $issuer
      hasDraftReport: $hasDraftReport
      studyDescription: $studyDescription
      bodyPart: $bodyPart
      patientName: $patientName
      referringPhysician: $referringPhysician
      priorityFilter: $priorityFilter
    ) {
      totalCount
      filteredCount
      next
      items {
        ...tableWorklistItemFields
      }
    }
  }

  ${TABLE_WORKLIST_FIELDS}
`;

export const GET_GROUPABLE_WORKLIST_ITEMS: DocumentNode = gql`
  query getGroupableWorklistItems($mrn: String!, $patientName: String!) {
    groupableWorklistItems(mrn: $mrn, patientName: $patientName) {
      smid
      studyDescription
      studyDate
      accessionNumber
      claimedBy {
        id
        firstName
        lastName
      }
      groupId
    }
  }
`;

// We only need data from the grouped worklist items which could be used in a placeholder.
// See placeholder/constants for the list of fields.
export const GET_GROUPED_WORKLIST_ITEMS: DocumentNode = gql`
  query getGroupedWorklistItems($groupId: ID) {
    groupedWorklistItems(groupId: $groupId) {
      smid
      accessionNumber
      patientAge
      patientSex
      dosage
      studyDescription
      studyReason
      studyDate
      referringPhysician
      techNotes
      studies {
        laterality
        studyDate
      }
      customMergeFields {
        key
        value
      }
    }
  }
`;

export const CREATE_WORKLIST_ITEM_GROUP: DocumentNode = gql`
  mutation createWorklistItemGroup($worklistItemSmids: [ID!]!) {
    createWorklistItemGroup(worklistItemSmids: $worklistItemSmids)
  }
`;

export const UPDATE_WORKLIST_ITEM_GROUP: DocumentNode = gql`
  mutation updateWorklistItemGroup($groupId: ID!, $worklistItemSmids: [ID!]!) {
    updateWorklistItemGroup(groupId: $groupId, worklistItemSmids: $worklistItemSmids)
  }
`;

export const DELETE_WORKLIST_ITEM_GROUP: DocumentNode = gql`
  mutation deleteWorklistItemGroup($groupId: ID!) {
    deleteWorklistItemGroup(groupId: $groupId) {
      status
    }
  }
`;

export const GET_STUDY: DocumentNode = gql`
  query getStudy($smid: ID!) {
    study(smid: $smid) {
      ...studyData
    }
  }

  ${STUDY_DATA_FIELDS}
`;

export const GET_COMPARATIVE_STUDIES: DocumentNode = gql`
  query getComparativeStudies($searchSmid: ID!, $matchTypes: [MatchType!], $bodyPart: [String!]) {
    bodyPartMatchedComparativeStudies: studies(
      searchSmid: $searchSmid
      matchTypes: $matchTypes
      bodyPart: $bodyPart
    ) {
      items {
        accessionNumber
        description
        modality
        mrn
        patientAge
        smid
        studyDate
        seriesCount
      }
    }

    allComparativeStudies: studies(searchSmid: $searchSmid, matchTypes: $matchTypes) {
      totalCount
    }
  }
`;

export const GET_STUDIES: DocumentNode = gql`
  query getStudies(
    $limit: Int
    $cursor: ID
    $filter: String
    $modality: [String]
    $mrn: String
    $bodyPart: [String]
    $studyDate: [DateTime]
    $sortOrder: SortOrder
    $sortColumn: SortColumn
    $searchSmid: ID
    $matchTypes: [MatchType!]
  ) {
    studies(
      limit: $limit
      cursor: $cursor
      filter: $filter
      modality: $modality
      mrn: $mrn
      bodyPart: $bodyPart
      studyDate: $studyDate
      sortOrder: $sortOrder
      sortColumn: $sortColumn
      searchSmid: $searchSmid
      matchTypes: $matchTypes
    ) {
      totalCount
      next
      items {
        ...studyData
      }
    }
  }

  ${STUDY_DATA_FIELDS}
`;

export const GET_STUDIES_WITHOUT_SERIES: DocumentNode = gql`
  query getStudiesWithoutSeries(
    $limit: Int
    $cursor: ID
    $filter: String
    $modality: [String]
    $mrn: String
    $bodyPart: [String]
    $studyDate: [DateTime]
    $sortOrder: SortOrder
    $sortColumn: SortColumn
    $searchSmid: ID
    $matchTypes: [MatchType!]
    $overrideShowRelevantPriorsOnly: Boolean
  ) {
    studies(
      limit: $limit
      cursor: $cursor
      filter: $filter
      modality: $modality
      mrn: $mrn
      bodyPart: $bodyPart
      studyDate: $studyDate
      sortOrder: $sortOrder
      sortColumn: $sortColumn
      searchSmid: $searchSmid
      matchTypes: $matchTypes
      overrideShowRelevantPriorsOnly: $overrideShowRelevantPriorsOnly
    ) {
      totalCount
      next
      items {
        ...studyDataWithoutSeries
      }
    }
  }

  ${STUDY_DATA_FIELDS_WITHOUT_SERIES}
`;

export const GET_STUDIES_WITHOUT_SERIES_BY_SMIDS: DocumentNode = gql`
  query getStudiesWithoutSeriesBySmids($smids: [ID!]!) {
    studiesBySmid(smids: $smids) {
      ...studyDataWithoutSeries
    }
  }

  ${STUDY_DATA_FIELDS_WITHOUT_SERIES}
`;
export const GET_SERIES_FROM_STUDIES: DocumentNode = gql`
  query getSeriesFromStudies($smids: [ID!]!) {
    studiesBySmid(smids: $smids) {
      seriesList {
        smid
        seriesInstanceUID
        description
        SOPClassUID
        seriesDateTimeLocal
        frameOfReferenceUID
        seriesNumber
      }
    }
  }
`;

export const GET_CLAIMED_ITEMS: DocumentNode = gql`
  query getClaimedItems($claimedBy: ID!) {
    worklistItems(claimedBy: $claimedBy, status: IN_PROGRESS) {
      totalCount
      items {
        smid
      }
    }
  }
`;

export const GET_WORKLIST_FILTER_DROPDOWN_ITEMS: DocumentNode = gql`
  query getWorklistFilterDropdownItems(
    $limit: Int
    $fieldName: String
    $searchValue: String
    $claimedBy: ID
    $site: [String]
    $patientType: [String]
    $patientSex: [String]
    $patientDob: [DateTime]
    $modality: [String]
    $priority: [String]
    $status: WorklistItemStatus
    $isActive: Boolean
    $filter: String
    $smids: [ID!]
    $studyDate: [DateTime]
    $submittedAt: [DateTime]
    $customStatus: [String]
    $mrn: [String]
    $mrnPartial: [String]
    $accessionNumber: [String]
    $accessionNumberPartial: [String]
    $activeViews: [ID!]
    $issuer: [String!]
    $hasDraftReport: Boolean
    $studyDescription: [String]
    $bodyPart: [String]
    $patientName: [String]
    $referringPhysician: [String]
    $priorityFilter: [String]
  ) {
    worklistFilterDropdownItems(
      limit: $limit
      fieldName: $fieldName
      searchValue: $searchValue
      claimedBy: $claimedBy
      site: $site
      patientType: $patientType
      patientSex: $patientSex
      patientDob: $patientDob
      modality: $modality
      priority: $priority
      status: $status
      isActive: $isActive
      filter: $filter
      smids: $smids
      studyDate: $studyDate
      submittedAt: $submittedAt
      customStatus: $customStatus
      mrn: $mrn
      mrnPartial: $mrnPartial
      accessionNumber: $accessionNumber
      accessionNumberPartial: $accessionNumberPartial
      activeViews: $activeViews
      issuer: $issuer
      hasDraftReport: $hasDraftReport
      studyDescription: $studyDescription
      bodyPart: $bodyPart
      patientName: $patientName
      referringPhysician: $referringPhysician
      priorityFilter: $priorityFilter
    ) {
      items {
        count
        value
        label
      }
      totalCount
    }
  }
`;

export const GET_MY_QUEUE: DocumentNode = gql`
  query getMyQueue($claimedBy: ID!) {
    worklistItems(claimedBy: $claimedBy, status: IN_PROGRESS) {
      totalCount
      items {
        smid
        groupId
        report {
          smid
          submittedAt
        }
      }
    }
  }
`;

export const GET_STUDY_DESCRIPTIONS: DocumentNode = gql`
  query getStudyDescriptions($search: String, $cursor: ID) {
    studyDescriptions(search: $search, cursor: $cursor) {
      totalCount
      cursor
      next
      items {
        smid
        description
        autoloadTemplate {
          id
          name
        }
      }
    }
  }
`;

export const GET_PROCEDURE: DocumentNode = gql`
  query getProcedure($id: ID!) {
    procedure(id: $id) {
      smid
      code
      description
      autoloadTemplate {
        id
        name
      }
    }
  }
`;

export const GET_PROCEDURES: DocumentNode = gql`
  query getProcedures($search: String, $cursor: ID) {
    procedures(search: $search, cursor: $cursor) {
      totalCount
      cursor
      next
      items {
        smid
        code
        description
        autoloadTemplate {
          id
          name
        }
      }
    }
  }
`;

export const GET_PROCEDURES_BY_SMID: DocumentNode = gql`
  query getProceduresBySmid($smids: [ID!]!) {
    proceduresBySmid(smids: $smids) {
      smid
      code
      description
      autoloadTemplate {
        id
        name
      }
    }
  }
`;

export const GET_PRIORITIES: DocumentNode = gql`
  query getPriorities {
    priorities {
      name
      value
      dueDuration
    }
  }
`;

export const HANGING_PROTOCOL: DocumentNode = gql`
  fragment hangingProtocol on HangingProtocol {
    smid
    name
    numberOfPriors
    data {
      layouts {
        id
        layout
      }
    }
    viewportsConfigurations {
      id
      configuration {
        study {
          smid
        }
        series {
          smid
        }
        stack {
          smid
        }
        viewType
        comparisonIndex
        seriesDescriptions
        seriesCriteria {
          anatomic_focus {
            display_name
            ontology_id
          }
        }
      }
    }
    created
    updated
    studyDescriptions
    studyCriteria
  }
`;

export const GET_HANGING_PROTOCOLS: DocumentNode = gql`
  query getHangingProtocols(
    $cursor: ID
    $limit: Int
    $studyDescriptions: [String!]
    $minimumNumberOfPriors: Int!
    $cases: [[ID!]!]
  ) {
    hangingProtocols(
      cursor: $cursor
      limit: $limit
      studyDescriptions: $studyDescriptions
      minimumNumberOfPriors: $minimumNumberOfPriors
      sortBy: NUMBER_OF_PRIORS
      cases: $cases
    ) {
      items {
        ...hangingProtocol
      }
    }
  }

  ${HANGING_PROTOCOL}
`;

export const GET_CASE_CLASSIFICATION: DocumentNode = gql`
  query getCaseClassification($studySmids: [ID!]!) {
    caseClassification(studySmids: $studySmids) {
      study_description
      study_smid
      study_classification
    }
  }
`;

export const GET_WORKLIST_ITEMS_CLASSIFICATIONS: DocumentNode = gql`
  query getWorklistItemsClassifications($worklistItemSmids: [ID!]!) {
    worklistItemsClassifications(worklistItemSmids: $worklistItemSmids)
  }
`;

export const GET_SERIES_CLASSIFICATIONS: DocumentNode = gql`
  query getSeriesClassifications($seriesSmids: [ID!]!) {
    seriesClassifications(seriesSmids: $seriesSmids) {
      seriesCriteria {
        anatomic_focus {
          display_name
          ontology_id
        }
      }
      seriesDescription
      studySmid
      seriesSmid
      seriesNumber
      seriesDate
      seriesTime
    }
  }
`;

export const GET_HANGING_PROTOCOL: DocumentNode = gql`
  query getHangingProtocol($id: ID!, $cases: [[ID!]!]) {
    hangingProtocol(id: $id, cases: $cases) {
      ...hangingProtocol
    }
  }

  ${HANGING_PROTOCOL}
`;

export const GET_ALL_HANGING_PROTOCOLS: DocumentNode = gql`
  query getAllHangingProtocols($cursor: ID, $limit: Int) {
    hangingProtocols(cursor: $cursor, limit: $limit) {
      items {
        smid
        name
        numberOfPriors
        data {
          layouts {
            id
            layout
          }
        }
        created
        updated
        studyDescriptions
        studyCriteria
      }
    }
  }
`;

export const UPDATE_HANGING_PROTOCOL: DocumentNode = gql`
  mutation updateHangingProtocol($id: ID!, $payload: HangingProtocolPayload!) {
    updateHangingProtocol(id: $id, payload: $payload) {
      ...hangingProtocol
    }
  }

  ${HANGING_PROTOCOL}
`;

export const CREATE_HANGING_PROTOCOL: DocumentNode = gql`
  mutation createHangingProtocol($payload: HangingProtocolPayload!) {
    createHangingProtocol(payload: $payload) {
      ...hangingProtocol
    }
  }

  ${HANGING_PROTOCOL}
`;

export const DELETE_HANGING_PROTOCOL: DocumentNode = gql`
  mutation deleteHangingProtocol($id: ID!) {
    deleteHangingProtocol(id: $id) {
      smid
    }
  }
`;

export const DELETE_ANNOTATIONS: DocumentNode = gql`
  mutation deleteAnnotations($smids: [ID!]!) {
    deleteAnnotations(smids: $smids) {
      results {
        status
        smid
      }
    }
  }
`;

export const UPDATE_REPORT: DocumentNode = gql`
  mutation updateReport($smid: ID!, $worklistItemSmid: ID!, $payload: ReportPayload!) {
    updateReport(smid: $smid, worklistItemSmid: $worklistItemSmid, payload: $payload) {
      smid
      created
      updated
      version
      sectionsLastUpdated
      content
      template {
        id
        picklists {
          id
          options {
            id
            name
            text
            default
          }
        }
        macros {
          smid
          name
          triggerPhrase
          text
          created
          updated
          procedures {
            smid
            description
            code
          }
          owner {
            id
            firstName
            lastName
          }
        }
      }
      addendums {
        ...addendumFields
      }
      macros {
        smid
        name
        triggerPhrase
        text
        created
        updated
        procedures {
          smid
          description
          code
        }
        owner {
          id
          firstName
          lastName
        }
      }
      sections
      picklists {
        id
        options {
          id
          name
          text
          default
        }
      }
    }
  }

  ${ADDENDUM_FIELDS}
`;

export const CREATE_ADDENDUM: DocumentNode = gql`
  mutation createAddendum($reportSmid: ID!) {
    createAddendum(reportSmid: $reportSmid) {
      ...addendumFields
    }
  }

  ${ADDENDUM_FIELDS}
`;

export const CANCEL_ADDENDUM: DocumentNode = gql`
  mutation cancelAddendum($smid: ID!) {
    cancelAddendum(smid: $smid) {
      ...addendumFields
    }
  }

  ${ADDENDUM_FIELDS}
`;

export const UPDATE_ADDENDUM: DocumentNode = gql`
  mutation updateAddendum($smid: ID!, $payload: AddendumPayload!) {
    updateAddendum(smid: $smid, payload: $payload) {
      ...addendumFields
    }
  }

  ${ADDENDUM_FIELDS}
`;

export const SUBMIT_ADDENDUM: DocumentNode = gql`
  mutation submitAddendum($smid: ID!, $immediate_submit: Boolean) {
    submitAddendum(smid: $smid, immediate_submit: $immediate_submit) {
      ...addendumFields
    }
  }

  ${ADDENDUM_FIELDS}
`;

export const DELETE_ADDENDUM: DocumentNode = gql`
  mutation deleteAddendum($smid: ID!) {
    deleteAddendum(smid: $smid) {
      confirmed
    }
  }
`;

export const UPDATE_MOUSE_PREFERENCES: DocumentNode = gql`
  mutation updateMousePreferences($id: ID!, $button: String!) {
    updateMousePreferences(id: $id, button: $button) {
      left
      middle
      right
    }
  }
`;

export const UPDATE_MOUSE_SCROLL_DIRECTION: DocumentNode = gql`
  mutation updateMouseScrollDirection($invertScroll: Boolean!) {
    updateMouseScrollDirection(invertScroll: $invertScroll) {
      invertScroll
    }
  }
`;

export const UPDATE_MOUSE_SENSITIVITY_PREFERENCES: DocumentNode = gql`
  mutation updateMouseSensitivityPreferences(
    $regularScroll: Float
    $fastScroll: Float
    $fineScroll: Boolean
  ) {
    updateMouseSensitivityPreferences(
      regularScroll: $regularScroll
      fastScroll: $fastScroll
      fineScroll: $fineScroll
    ) {
      regularScroll {
        sensitivity
      }
      fastScroll {
        sensitivity
      }
      fineScroll {
        enabled
      }
    }
  }
`;

export const UPDATE_WHEEL_PREFERENCES: DocumentNode = gql`
  mutation updateWheelPreferences($ids: [ID!]!) {
    updateWheelPreferences(ids: $ids) {
      toolIds
    }
  }
`;

export const CLEAR_KEYBOARD_PREFERENCES: DocumentNode = gql`
  mutation clearKeyboardPreferences($resetToDefault: Boolean!) {
    clearKeyboardPreferences(resetToDefault: $resetToDefault) {
      shortcuts {
        id
        key
        interactionId
      }
    }
  }
`;

export const UPDATE_KEYBOARD_PREFERENCES: DocumentNode = gql`
  mutation updateKeyboardPreferences($id: ID!, $key: String!, $interactionId: String) {
    updateKeyboardPreferences(id: $id, key: $key, interactionId: $interactionId) {
      shortcuts {
        id
        key
        interactionId
      }
    }
  }
`;

export const UPDATE_DICTAPHONE_PREFERENCES: DocumentNode = gql`
  mutation updateDictaphonePreferences(
    $keyMapping: [IDictaphoneKey!]
    $deviceLabel: String
    $recordWhilePressed: Boolean
    $showPicklistOptionsInEditor: Boolean
  ) {
    updateDictaphonePreferences(
      keyMapping: $keyMapping
      deviceLabel: $deviceLabel
      recordWhilePressed: $recordWhilePressed
      showPicklistOptionsInEditor: $showPicklistOptionsInEditor
    ) {
      keyMapping {
        id
        key
      }
      deviceLabel
      recordWhilePressed
      showPicklistOptionsInEditor
    }
  }
`;

export const UPDATE_BAR_PREFERENCES: DocumentNode = gql`
  mutation updateBarPreferences($ids: [ID!]!) {
    updateBarPreferences(ids: $ids) {
      toolIds
    }
  }
`;

export const UPDATE_RESET_TOOL_ON_SCROLL_PREFERENCES: DocumentNode = gql`
  mutation updateResetToolOnScrollPreferences($enabled: Boolean!) {
    updateResetToolOnScrollPreferences(enabled: $enabled) {
      enabled
    }
  }
`;

export const UPDATE_MIRROR_VIEWER_LAYOUTS_PREFERENCES: DocumentNode = gql`
  mutation updateMirrorViewerLayoutsPreferences($enabled: Boolean!) {
    updateMirrorViewerLayoutsPreferences(enabled: $enabled) {
      enabled
    }
  }
`;

export const UPDATE_SHOW_ICON_LABEL_PREFERENCES: DocumentNode = gql`
  mutation updateShowIconLabelPreferences($enabled: Boolean!) {
    updateShowIconLabelPreferences(enabled: $enabled) {
      enabled
    }
  }
`;

export const UPDATE_ME: DocumentNode = gql`
  mutation updateMe(
    $firstName: String
    $lastName: String
    $physicianId: String
    $preferredWorkLocation: PreferredWorkLocationPayload
  ) {
    updateMe(
      firstName: $firstName
      lastName: $lastName
      physicianId: $physicianId
      preferredWorkLocation: $preferredWorkLocation
    ) {
      firstName
      lastName
      physicianId
      preferredWorkLocation {
        hospital
        home
      }
    }
  }
`;

export const UPDATE_PASSWORD: DocumentNode = gql`
  mutation updatePassword($oldPassword: String!, $newPassword: String!, $totp: String) {
    updatePassword(oldPassword: $oldPassword, newPassword: $newPassword, totp: $totp) {
      confirmed
    }
  }
`;

export const CHANGE_WORKLIST_QUALITY_CONTROL_STATE: DocumentNode = gql`
  mutation changeWorklistItemQualityControlState($smid: ID!, $qc: Boolean!, $comment: String) {
    changeWorklistItemQualityControlState(smid: $smid, qc: $qc, comment: $comment) {
      smid
      status
    }
  }
`;

export const CHANGE_WORKLIST_ITEM_STATUS: DocumentNode = gql`
  mutation changeWorklistItemStatus($smid: ID!, $claim: Boolean, $resetReport: Boolean) {
    changeWorklistItemStatus(smid: $smid, claim: $claim, resetReport: $resetReport) {
      smid
      claimedBy {
        id
        firstName
        lastName
      }
      accessionNumber
    }
  }
`;

export const BATCH_CLAIM_WORKLIST_ITEMS: DocumentNode = gql`
  mutation batchClaimWorklistItems($worklistItems: [BatchClaimInput!]!) {
    batchClaimWorklistItems(worklistItems: $worklistItems) {
      smid
      success
      errorReason
      claimedBy {
        id
        firstName
        lastName
      }
      accessionNumber
    }
  }
`;

export const CHANGE_WORKLIST_ITEM_CUSTOM_STATUS: DocumentNode = gql`
  mutation changeWorklistItemCustomStatus($smid: ID!, $customStatusID: ID!) {
    changeWorklistItemCustomStatus(smid: $smid, customStatusID: $customStatusID) {
      smid
      customStatus {
        smid
        name
      }
    }
  }
`;

export const UNDO_CHANGE_WORKLIST_ITEM_CUSTOM_STATUS: DocumentNode = gql`
  mutation undoChangeWorklistItemCustomStatus($smid: ID!, $customStatusID: ID!) {
    undoChangeWorklistItemCustomStatus(smid: $smid, customStatusID: $customStatusID) {
      smid
      customStatus {
        smid
        name
      }
    }
  }
`;

export const CHANGE_WORKLIST_ITEM_PRIORITY: DocumentNode = gql`
  mutation changeWorklistItemPriority($smid: ID!, $name: String!) {
    changeWorklistItemPriority(smid: $smid, name: $name) {
      smid
      priority
    }
  }
`;

export const CREATE_WORKLIST_ITEM_ACTIVITY: DocumentNode = gql`
  mutation createWorklistItemActivity($smid: ID!) {
    createWorklistItemActivity(smid: $smid)
  }
`;

export const RESET_AND_RELEASE_WORKLIST_ITEM: DocumentNode = gql`
  mutation resetAndReleaseWorklistItem($smid: ID!) {
    resetAndReleaseWorklistItem(smid: $smid) {
      smid
    }
  }
`;

export const GET_STUDIES_BY_SMID: DocumentNode = gql`
  query getStudiesBySmid($smids: [ID!]!) {
    studiesBySmid(smids: $smids) {
      ...studyData
      provenanceData {
        matchType
        relevant
      }
    }
  }

  ${STUDY_DATA_FIELDS}
`;

export const CREATE_REPORT_TEMPLATE: DocumentNode = gql`
  mutation createTemplate($payload: IReportTemplatePayload!) {
    createTemplate(payload: $payload) {
      id
    }
  }
`;

export const UPDATE_REPORT_TEMPLATE: DocumentNode = gql`
  mutation updateTemplate($id: ID!, $payload: IReportTemplatePayload!) {
    updateTemplate(id: $id, payload: $payload) {
      id
    }
  }
`;

export const GET_REPORT_TEMPLATES: DocumentNode = gql`
  query getTemplates(
    $cursor: ID
    $limit: Int
    $owners: [ID!]
    $search: String
    $procedures: [ID!]
    $studyDescriptions: [String!]
    $autoload: Boolean
  ) {
    templates(
      owners: $owners
      cursor: $cursor
      limit: $limit
      search: $search
      procedures: $procedures
      studyDescriptions: $studyDescriptions
      autoload: $autoload
    ) {
      totalCount
      cursor
      next
      items {
        id
        name
        triggerPhrase
        created
        content
        owner {
          id
          firstName
          lastName
        }
      }
    }
  }
`;

export const GET_REPORT_TEMPLATE: DocumentNode = gql`
  query getTemplate($id: ID!) {
    template(id: $id) {
      ...reportTemplateFields
    }
  }

  ${REPORT_TEMPLATE_FIELDS}
`;

export const DELETE_REPORT_TEMPLATE: DocumentNode = gql`
  mutation deleteTemplate($id: ID!) {
    deleteTemplate(id: $id) {
      smid
    }
  }
`;

export const GET_MACROS: DocumentNode = gql`
  query getMacros($owners: [ID!], $cursor: ID, $limit: Int, $procedures: [ID!], $search: String) {
    macros(
      owners: $owners
      procedures: $procedures
      cursor: $cursor
      limit: $limit
      search: $search
    ) {
      cursor
      next
      items {
        smid
        name
        triggerPhrase
        text
        created
        updated
        procedures {
          smid
          description
          code
        }
        owner {
          id
          firstName
          lastName
        }
      }
    }
  }
`;

export const GET_MACRO: DocumentNode = gql`
  query getMacro($id: ID!) {
    macro(id: $id) {
      smid
      name
      triggerPhrase
      text
      created
      updated
      procedures {
        smid
        description
        code
      }
      nestedMacros {
        smid
        name
        text
        picklists {
          id
          options {
            id
            name
            text
            default
          }
        }
      }
      picklists {
        id
        options {
          id
          name
          text
          default
        }
      }
      owner {
        id
        firstName
        lastName
      }
    }
  }
`;

export const CREATE_MACRO: DocumentNode = gql`
  mutation createMacro($payload: MacroPayload!) {
    createMacro(payload: $payload) {
      smid
    }
  }
`;
export const UPDATE_MACRO: DocumentNode = gql`
  mutation updateMacro($id: ID!, $payload: MacroPayload!) {
    updateMacro(id: $id, payload: $payload) {
      smid
    }
  }
`;

export const DELETE_MACRO: DocumentNode = gql`
  mutation deleteMacro($id: ID!) {
    deleteMacro(id: $id) {
      smid
    }
  }
`;

export const CREATE_WWWC_TOOL_PRESET: DocumentNode = gql`
  mutation createWwwcToolPreset($payload: WwwcPresetPayload!) {
    createWwwcToolPreset(payload: $payload) {
      id
    }
  }
`;

export const UPDATE_WWWC_TOOL_PRESET: DocumentNode = gql`
  mutation updateWwwcToolPreset($id: ID!, $payload: WwwcPresetPayload!) {
    updateWwwcToolPreset(id: $id, payload: $payload) {
      id
    }
  }
`;

export const DELETE_WWWC_TOOL_PRESET: DocumentNode = gql`
  mutation deleteWwwcToolPreset($id: ID!) {
    deleteWwwcToolPreset(id: $id) {
      id
    }
  }
`;

export const SET_WWWC_DRAG_DIRECTION: DocumentNode = gql`
  mutation setWwwcDragDirection($value: Boolean!) {
    setWwwcDragDirection(value: $value) {
      invertDragDirection
    }
  }
`;

export const CONFIRM_ACCOUNT: DocumentNode = gql`
  mutation confirmAccount($userId: String!, $token: ID!, $password: String!) {
    confirmAccount(userId: $userId, token: $token, password: $password) {
      confirmed
    }
  }
`;

export const REQUEST_PASSWORD_RESET: DocumentNode = gql`
  mutation requestPasswordReset($email: String!) {
    requestPasswordReset(email: $email) {
      confirmed
    }
  }
`;

export const RESET_PASSWORD: DocumentNode = gql`
  mutation resetPassword($token: ID!, $userId: ID!, $password: String!) {
    resetPassword(token: $token, userId: $userId, password: $password) {
      confirmed
    }
  }
`;

export const GET_REPORT: DocumentNode = gql`
  query getReport($smid: ID!) {
    report(smid: $smid) {
      ...reportFields
    }
  }

  ${REPORT_FIELDS}
`;

export const GET_REPORTS: DocumentNode = gql`
  query getReports($accessionNumbers: [ID!]!) {
    reports(accessionNumbers: $accessionNumbers) {
      smid
      submittedAt
      sentAt
      sectionsLastUpdated
      macros {
        ...macroFields
      }
      sections
      content
      format
      addendums {
        ...addendumFields
      }
    }
  }

  ${MACRO_FIELDS}
  ${ADDENDUM_FIELDS}
`;

export const GET_PATIENT_HISTORY_LIST: DocumentNode = gql`
  query getPatientHistoryList(
    $mrn: [String!]!
    $sortColumn: [SortColumn!]
    $sortOrder: [SortOrder!]
  ) {
    worklistItems(mrn: $mrn, sortColumn: $sortColumn, sortOrder: $sortOrder) {
      items {
        smid
        studies {
          smid
        }
        claimedBy {
          id
        }
        patientName
        studyDate
        created
        studyDate
        bodyPart
        modality
        status
        mrn
        studyDescription
        report {
          smid
          worklistitem
          content
        }
        dosage
        studyReason
        techNotes
        patientSex
        patientDob
        patientAge
        accessionNumber
      }
    }
  }
`;

export const GET_BASE_VIEWER_DATA: DocumentNode = gql`
  query getBaseViewerData(
    $worklistIds: [ID!]!
    $studyIds: [ID!]!
    $skippedStudyIds: [ID!]!
    $hasWorklistIds: Boolean!
    $hasStudyIds: Boolean!
    $includeAutoloadedPriors: Boolean!
  ) {
    worklistItems(smids: $worklistIds) @include(if: $hasWorklistIds) {
      items {
        smid
        studies {
          ...studyData
        }
        autoloadedPriors(skippedStudyIds: $skippedStudyIds) @include(if: $includeAutoloadedPriors) {
          ...studyData
          provenanceData {
            matchType
            relevant
          }
        }
        allAutoloadedPriors @include(if: $includeAutoloadedPriors) {
          smid
        }
      }
    }

    studiesBySmid(smids: $studyIds) @include(if: $hasStudyIds) {
      ...studyData
      provenanceData {
        matchType
        relevant
      }
    }

    matchHangingProtocolByWorklistsAndStudies(
      worklistIds: $worklistIds
      studyIds: $studyIds
      includeAutoloadedPriors: $includeAutoloadedPriors
      skippedStudyIds: $skippedStudyIds
    ) {
      ...hangingProtocol
    }

    presentationStates(
      worklistIds: $worklistIds
      studyIds: $studyIds
      includeAutoloadedPriors: $includeAutoloadedPriors
      skippedStudyIds: $skippedStudyIds
    ) {
      ... on GspsPresentationState {
        smid
        owner {
          firstName
          lastName
        }
        created
        study {
          smid
          description
        }
        annotations {
          smid
        }
        name
      }

      ... on GspsPresentationStateError {
        message
      }
    }
  }

  ${PATIENT_FIELDS}
  ${STUDY_DATA_FIELDS}
  ${HANGING_PROTOCOL}
`;

export const GET_STACK_SIGNED_URLS: DocumentNode = gql`
  query getStackSignedUrls($stackSmid: ID!, $isMultiFrame: Boolean!, $frames: [FrameToLoad!]!) {
    pixelURLs(stackSmid: $stackSmid, isMultiFrame: $isMultiFrame, frames: $frames) {
      frameSmid
      signedUrl {
        url
        expirationTime
      }
      fallbackUrl
    }
  }
`;

export const GET_WORKSPACES_PREFERENCES: DocumentNode = gql`
  query getWorkspacesPreferences {
    toolPreferences {
      workspaces {
        presets {
          ...workspacePresetsFields
        }
        autoApply
      }
    }
  }
  ${WINDOW_STATE_FIELDS}
`;

export const CANCEL_REPORT: DocumentNode = gql`
  mutation cancelReport($smid: ID!) {
    cancelReport(smid: $smid) {
      smid
      submittedAt
    }
  }
`;

export const SUBMIT_REPORT: DocumentNode = gql`
  mutation submitReport($smid: ID!, $immediate_submit: Boolean) {
    submitReport(smid: $smid, immediate_submit: $immediate_submit) {
      __typename
      ... on SubmitReportPayload {
        report {
          smid
          submittedAt
        }
      }
      ... on SubmitReportProblem {
        invalidInputs {
          field
          message
        }
        unknownProblem
      }
    }
  }
`;

export const CREATE_WINDOW_STATE: DocumentNode = gql`
  mutation createWorkspacePreset($payload: WorkspacePresetPayload!) {
    createWorkspacePreset(payload: $payload) {
      ...workspacePresetsFields
    }
  }
  ${WINDOW_STATE_FIELDS}
`;

export const UPDATE_WINDOW_STATE: DocumentNode = gql`
  mutation updateWorkspacePreset($smid: ID!, $payload: WorkspacePresetPayload!) {
    updateWorkspacePreset(smid: $smid, payload: $payload) {
      ...workspacePresetsFields
    }
  }
  ${WINDOW_STATE_FIELDS}
`;

export const SET_WORKSPACE_AUTO_APPLY: DocumentNode = gql`
  mutation setWorkspaceAutoApply($value: Boolean!) {
    setWorkspaceAutoApply(value: $value) {
      autoApply
    }
  }
`;

export const DELETE_WORKSPACE_PRESET: DocumentNode = gql`
  mutation deleteWorkspacePreset($smid: ID!) {
    deleteWorkspacePreset(smid: $smid) {
      smid
    }
  }
`;

export const UPDATE_REPORTER_SETTINGS: DocumentNode = gql`
  mutation updateReporterSettings(
    $triggerWord: String
    $styles: StylesInput
    $cursorStyle: CursorStyleInput
    $lineIndicator: LineIndicatorInput
    $mergeFieldsSettings: MergeFieldsSettingsInput
    $aiMode: AIModeInput
    $impressionGenerator: ImpressionGeneratorInput
    $autoFillComparison: AutoFillComparisonInput
    $focusMode: FocusModeInput
  ) {
    updateReporterSettings(
      triggerWord: $triggerWord
      styles: $styles
      cursorStyle: $cursorStyle
      lineIndicator: $lineIndicator
      mergeFieldsSettings: $mergeFieldsSettings
      aiMode: $aiMode
      impressionGenerator: $impressionGenerator
      autoFillComparison: $autoFillComparison
      focusMode: $focusMode
    ) {
      triggerWord
      aiMode {
        findingsMapperEnabled
        formatImpressionGenerationAsList
        onDemandImpressionGeneration
      }
      impressionGenerator {
        automatic
        enabled
        modalities
      }
      mergeFieldsSettings {
        ignoreNavigation
        ignoreDefaultSelection
      }
      styles {
        bodyStyle {
          fontSize
          fontFamily
          textTransform
          textDecoration {
            isBold
            isItalic
            isUnderline
          }
        }
        headingStyles {
          level
          textStyle {
            fontSize
            textTransform
            textDecoration {
              isBold
              isItalic
              isUnderline
            }
          }
        }
      }
      cursorStyle {
        color
      }
      lineIndicator {
        enabled
        placement
        variant
      }
      autoFillComparison {
        enabled
      }
      focusMode {
        enabled
      }
    }
  }
`;

export const DELETE_USER_LINKED_AI_ANNOTATIONS: DocumentNode = gql`
  mutation deleteUserLinkedAiAnnotations($payload: [String!]!) {
    deleteUserLinkedAiAnnotations(payload: $payload) {
      status
    }
  }
`;

export const REJECT_AI_ANNOTATIONS: DocumentNode = gql`
  mutation rejectAiAnnotations($ids: [ID!]!) {
    rejectAiAnnotations(ids: $ids) {
      logIds
    }
  }
`;

export const ACCEPT_AI_ANNOTATIONS: DocumentNode = gql`
  mutation acceptAiAnnotations($annotationsByGroup: [AcceptAiAnnotation!]!) {
    acceptAiAnnotations(payload: $annotationsByGroup) {
      logIds
      annotations {
        annotationSmid
      }
    }
  }
`;

export const GET_BODY_PARTS: DocumentNode = gql`
  query getBodyParts {
    bodyParts {
      name
    }
  }
`;

export const GET_MODALITIES: DocumentNode = gql`
  query getModalities {
    modalities {
      name
    }
  }
`;

export const GET_INSTANCE_TAGS: DocumentNode = gql`
  query getInstanceTags($seriesSmid: ID!, $instanceSmid: ID!) {
    dicomInstance(seriesSmid: $seriesSmid, instanceSmid: $instanceSmid) {
      smid
      dicomTags {
        id
        vr
        values {
          key
          value
        }
        name
      }
    }
  }
`;

export const CREATE_AUTOCORRECT_ENTRY: DocumentNode = gql`
  mutation createAutoCorrectEntry($input: CreateAutoCorrectEntryInput!) {
    createAutoCorrectEntry(input: $input) {
      entry {
        id
        key
        value
      }
    }
  }
`;

export const DELETE_AUTOCORRECT_ENTRY: DocumentNode = gql`
  mutation deleteAutoCorrectEntry($input: DeleteAutoCorrectEntryInput!) {
    deleteAutoCorrectEntry(input: $input) {
      id
    }
  }
`;

export const UPDATE_AUTOCORRECT_ENTRY: DocumentNode = gql`
  mutation updateAutoCorrectEntry($input: UpdateAutoCorrectEntryInput!) {
    updateAutoCorrectEntry(input: $input) {
      entry {
        id
        key
        value
      }
    }
  }
`;

export const GET_VOCAB_ENTRIES: DocumentNode = gql`
  query getVocabEntries {
    vocabEntries {
      id
      phrase
    }
  }
`;

export const CREATE_VOCAB_ENTRY: DocumentNode = gql`
  mutation createVocabEntry($input: CreateVocabEntryInput!) {
    createVocabEntry(input: $input) {
      entry {
        id
        phrase
      }
    }
  }
`;

export const DELETE_VOCAB_ENTRY: DocumentNode = gql`
  mutation deleteVocabEntry($input: DeleteVocabEntryInput!) {
    deleteVocabEntry(input: $input) {
      id
    }
  }
`;

export const GET_TOOLS: DocumentNode = gql`
  query getTools {
    tools {
      id
      name
      category
      description
      icon
      payloadCapabilities
      interactions {
        ... on ToolViewportClickInteraction {
          type
          id
          maxConcurrentRequests
          request {
            url
            method
          }
          button
          cursorIcon
        }
        ... on ToolVoiceCommandInteraction {
          type
          id
          maxConcurrentRequests
          request {
            url
            method
          }
          triggerPhrase
        }
        ... on ToolKeyboardShortcutInteraction {
          type
          id
          maxConcurrentRequests
          request {
            url
            method
          }
          keyboardShortcut
        }
        ... on ToolIconButtonClickInteraction {
          type
          id
          maxConcurrentRequests
          request {
            url
            method
          }
        }
        ... on ToolContextMenuOptionInteraction {
          type
          id
          maxConcurrentRequests
          request {
            url
            method
          }
          label
        }
        ... on ToolMergeFieldInteraction {
          type
          id
          maxConcurrentRequests
          request {
            url
            method
          }
          label
        }
        ... on ToolAnnotationInteraction {
          type
          id
          maxConcurrentRequests
          request {
            url
            method
          }
          targetAnnotationId
          targetAnnotationGroupId
          button
          action
        }
      }
      customAnnotationsLogic {
        id
        logic
      }
    }
  }
`;

export const TRIGGER_TOOL_INTERACTION: DocumentNode = gql`
  mutation triggerToolInteraction(
    $payload: ToolInteractionPayload!
    $toolId: ID!
    $toolInteractionId: ID!
    $toolInteractionType: ToolInteractionType!
  ) {
    triggerToolInteraction(
      toolInteractionType: $toolInteractionType
      toolInteractionId: $toolInteractionId
      toolId: $toolId
      payload: $payload
    ) {
      ... on ToolInteractionAnnotationResponse {
        type
        annotation {
          type
          groupId
          label
          style {
            color
            opacity
          }
          allowedTransformations {
            move
            transform
          }
          segments {
            start {
              ... on IndexCoordinates {
                indexSpace
              }
              ... on WorldCoordinates {
                worldSpace
              }
            }
            end {
              ... on IndexCoordinates {
                indexSpace
              }
              ... on WorldCoordinates {
                worldSpace
              }
            }
          }
          customLogic {
            id
            toolId
            logic
          }
        }
      }
      ... on ToolInteractionWidgetResponse {
        type
        widget {
          id
          title
          type
          position {
            ... on IndexCoordinates {
              indexSpace
            }
            ... on WorldCoordinates {
              worldSpace
            }
          }
          placement
          urlPath
          data
          dismissActions
        }
      }
      ... on ToolInteractionMessageResponse {
        type
        message
        display
        severity
      }
      ... on ToolInteractionUpdateToolActiveStateResponse {
        type
        active
      }
      ... on ToolInteractionUpdateViewportSliceResponse {
        type
        viewportId
        slice
      }
      ... on ToolInteractionUpdateViewportPresentationStateResponse {
        viewportId
        type
        window
        level
        zoom
        rotate
      }
      ... on ToolInteractionStartAnnotationResponse {
        type
        annotationType
      }
    }
  }
`;

export const GET_WORKLIST_VIEWS: DocumentNode = gql`
  query getWorklistViews {
    worklistViews {
      smid
      name
    }
  }
`;

export const GET_WORKLIST_VIEW: DocumentNode = gql`
  query getWorklistView($smid: ID!) {
    worklistView(smid: $smid) {
      smid
      name
      filters {
        name
        options {
          label
          value
        }
      }
    }
  }
`;

export const GET_CUSTOM_ANNOTATION_LOGIC: DocumentNode = gql`
  query getCustomAnnotationLogic($toolId: ID!, $type: String!) {
    customAnnotationLogic(toolId: $toolId, type: $type) {
      id
      logic
    }
  }
`;

export const GET_CURRENT_WORKLIST_REPORT: DocumentNode = gql`
  query getCurrentWorklistReport($smid: ID!) {
    worklistItem(id: $smid) {
      ...worklistItemFields
      report {
        smid
        created
        updated
        version
        sectionsLastUpdated
        workstationID
        status
        format
        template {
          ...reportTemplateFields
        }
        macros {
          ...macroFields
        }
        submittedAt
        sentAt
        addendums {
          ...addendumFields
        }
        sections
        content
        picklists {
          id
          options {
            id
            name
            text
            default
          }
        }
      }
    }
  }
  ${ADDENDUM_FIELDS}
  ${WORKLIST_FIELDS}
  ${REPORT_TEMPLATE_FIELDS}
  ${MACRO_FIELDS}
`;

export const TOGGLE_SCREEN_SHARE: DocumentNode = gql`
  mutation toggleScreenShare($screenShareEnabled: Boolean!) {
    toggleScreenShare(screenShareEnabled: $screenShareEnabled) {
      screenShareEnabled
    }
  }
`;

export const GET_STRUCTURED_REPORT: DocumentNode = gql`
  query getStructuredReport($seriesSmid: ID!, $frameSmid: ID!) {
    structuredReport(seriesSmid: $seriesSmid, frameSmid: $frameSmid) {
      id
      patient {
        name
        age
        id
        sex
      }
      study {
        date
        description
        id
        time
        uid
      }
      series {
        description
        number
        uid
      }
      instance {
        number
        uid
      }
      general {
        completion_flag
        completion_flag_description
        date
        instance_number
        preliminary_flag
        time
        verification_status
      }
      observation {
        uid
        datetime
      }
      modalities
      sop_class {
        uid
      }
      content
    }
  }
`;

export const GET_RHINO_PROMPT_BY_ID: DocumentNode = gql`
  query GetRhinoPromptById($id: ID!) {
    rhinoPrompt(id: $id) {
      id
      prompt
      label
      featureName
      modelIdentifier
      role
    }
  }
`;

export const GET_RHINO_PROMPTS: DocumentNode = gql`
  query GetRhinoPrompts($role: String, $featureName: String, $modelIdentifier: String) {
    rhinoPrompts(role: $role, featureName: $featureName, modelIdentifier: $modelIdentifier) {
      id
      prompt
      label
      featureName
      modelIdentifier
      role
    }
  }
`;

export const GET_RHINO_MODELS: DocumentNode = gql`
  query GetRhinoModels($modelIdentifier: String, $vendor: String) {
    rhinoModels(modelIdentifier: $modelIdentifier, vendor: $vendor) {
      id
      vendor
      label
      config {
        modelName
        temperature
      }
    }
  }
`;

export const SEND_RHINO_QUERY: DocumentNode = gql`
  mutation SendRhinoQuery($input: RhinoQueryInput!) {
    rhinoQuery(input: $input) {
      id
      modelIdentifier
      queryMessages {
        role
        content
      }
      response {
        message {
          role
          content
        }
        usage {
          promptTokens
          completionTokens
          totalTokens
        }
      }
      rawApiResponse {
        id
        object
        created
        model
        choices {
          index
          message {
            role
            content
          }
          finishReason
        }
        usage {
          promptTokens
          completionTokens
          totalTokens
        }
      }
    }
  }
`;

export const SEND_RHINO_FEEDBACK: DocumentNode = gql`
  mutation SendRhinoFeedback($input: RhinoFeedbackInput!) {
    rhinoFeedback(input: $input) {
      success
    }
  }
`;

export const SEND_RHINO_MEDCHECK_QUERY: DocumentNode = gql`
  mutation SendRhinoMedCheckQuery($input: RhinoMedCheckInput!) {
    rhinoMedCheck(input: $input) {
      content
    }
  }
`;

export const GET_AUTHENTICATOR_TOKEN: DocumentNode = gql`
  query getAuthenticatorToken($userId: String, $password: String!) {
    authenticatorToken(userId: $userId, password: $password)
  }
`;

export const LINK_AUTHENTICATOR: DocumentNode = gql`
  mutation linkAuthenticator($totp: String!, $userId: String, $password: String!) {
    linkAuthenticator(totp: $totp, userId: $userId, password: $password) {
      linked
    }
  }
`;

export const TOGGLE_MULTI_FACTOR_AUTH: DocumentNode = gql`
  mutation toggleMultiFactorAuth(
    $multiFactorAuthEnabled: Boolean!
    $password: String!
    $totp: String
  ) {
    toggleMultiFactorAuth(
      multiFactorAuthEnabled: $multiFactorAuthEnabled
      password: $password
      totp: $totp
    ) {
      multiFactorAuthEnabled
      authenticatorToken
    }
  }
`;

export const TOGGLE_MULTI_FACTOR_LINK: DocumentNode = gql`
  mutation toggleMultiFactorLink($multiFactorAuthLinked: Boolean!) {
    toggleMultiFactorLink(multiFactorAuthLinked: $multiFactorAuthLinked) {
      multiFactorAuthLinked
    }
  }
`;

export const GENERATE_IMPRESSION: DocumentNode = gql`
  mutation generateImpression($input: GenerateImpressionInput!) {
    generateImpression(input: $input) {
      impression
    }
  }
`;

export const GENERATE_PRIORS_SUMMARY: DocumentNode = gql`
  query generatePriorsSummary($caseSmid: ID!) {
    generatePriorsSummary(caseSmid: $caseSmid) {
      priorsSummary
    }
  }
`;

export const QUALITY_CONTROL_COMMENTS: DocumentNode = gql`
  query getQualityControlComments($smid: ID!) {
    qualityControlComments(smid: $smid) {
      comment
      authorSmid
      authorName
      createdAt
    }
  }
`;
