/**
 * Set of async utilities for IndexedDB, useful to avoid callback hell.
 */

import { logger } from './logger';

export function idbCursor<A>(
  request: IDBRequest,
  iterator: (accumulator: A, cursor: IDBCursorWithValue, exit: () => void) => A,
  accumulator: A
): Promise<A> {
  return new Promise((resolve: (result: Promise<A> | A) => void, reject: (error?: any) => void) => {
    let shouldExit = false;
    function exit() {
      shouldExit = true;
    }

    request.addEventListener('success', (event: unknown) => {
      // @ts-expect-error [EN-7967] - TS2339 - Property 'target' does not exist on type 'unknown'.
      const cursor: IDBCursorWithValue = event.target.result;
      if (cursor == null || shouldExit) {
        resolve(accumulator);
        return;
      }

      accumulator = iterator(accumulator, cursor, exit);
      cursor.continue();
    });

    request.addEventListener('error', (event: unknown) => {
      logger.error('Error with idb cursor');
      // @ts-expect-error [EN-7967] - TS2339 - Property 'target' does not exist on type 'unknown'.
      reject(event.target.error);
    });
  });
}

export function idbGet<T>(
  objectStore: IDBObjectStore,
  itemKey: string
): Promise<T | null | undefined> {
  return new Promise((resolve: (result: Promise<never>) => void, reject: (error?: any) => void) => {
    const request = objectStore.get(itemKey);

    request.addEventListener('success', (event: unknown) => {
      // @ts-expect-error [EN-7967] - TS2339 - Property 'target' does not exist on type 'unknown'.
      resolve(event.target.result);
    });
    request.addEventListener('error', (event: unknown) => {
      logger.error('Error with idb get');
      // @ts-expect-error [EN-7967] - TS2339 - Property 'target' does not exist on type 'unknown'.
      reject(event.target.error);
    });
  });
}

export function idbTransaction(
  db: IDBDatabase,
  storeNames: string | string[],
  mode: 'readonly' | 'readwrite' | 'versionchange' | undefined,
  callback: (transaction: IDBTransaction) => Promise<unknown>
): Promise<void> {
  return new Promise(
    async (
      resolve: (result: Promise<undefined> | undefined) => void,
      reject: (error?: any) => void
    ) => {
      const names = typeof storeNames === 'string' ? storeNames : storeNames.join(', ');

      const transaction = db.transaction(storeNames, mode);

      transaction.addEventListener('complete', () => {
        // @ts-expect-error [EN-7967] - TS2794 - Expected 1 arguments, but got 0. Did you forget to include 'void' in your type argument to 'Promise'?
        resolve();
      });

      transaction.addEventListener('abort', (event: unknown) => {
        logger.error(`Transaction aborted for storenames: ${names}`);

        // @ts-expect-error [EN-7967] - TS2339 - Property 'target' does not exist on type 'unknown'.
        reject(event.target.error ?? 'Unknown abort error');
      });

      transaction.addEventListener('error', (event: unknown) => {
        logger.error(`Transaction error for storenames: ${names}`);

        // @ts-expect-error [EN-7967] - TS2339 - Property 'target' does not exist on type 'unknown'.
        reject(event.target.error);
      });

      await callback(transaction);
    }
  );
}
