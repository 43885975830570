import type { ResponseWithContentLength } from './Apollo/precacheLink';
import analytics from '../modules/analytics';

type CacheRegistryItem = {
  lastAccessed: number;
  size: number;
};
type CacheRegistryData = {
  totalSize: number;
  items: {
    [key: string]: CacheRegistryItem;
  };
};

class CacheRegistry {
  cacheKey: string;

  // this is a reference to the cache registry data stored in localStorage
  // accessing this is faster than reading from localStorage
  totalSize: number;

  constructor(cacheKey: string) {
    this.cacheKey = cacheKey;
    window.addEventListener('storage', this.#handleExternalUpdates, false);
  }

  // If a different tab updates the cache registry, we need to update our
  // reference to the totalSize to keep it in sync
  #handleExternalUpdates = (event: StorageEvent) => {
    if (event.key === this.cacheKey) {
      if (event.newValue == null) {
        this.totalSize = 0;
      } else {
        this.totalSize = JSON.parse(event.newValue).totalSize;
      }
    }
  };

  read(): CacheRegistryData {
    const rawData = localStorage.getItem(this.cacheKey);
    if (rawData != null) {
      return JSON.parse(rawData);
    }
    return {
      totalSize: 0,
      items: {},
    };
  }

  reset(): void {
    localStorage.removeItem(this.cacheKey);
    this.totalSize = 0;
  }

  touch(key: string, size: number): void {
    const data = this.read();
    data.items[key] = { lastAccessed: Date.now(), size };
    data.totalSize += size;
    this.totalSize = data.totalSize;
    try {
      localStorage.setItem(this.cacheKey, JSON.stringify(data));
    } catch (e: any) {
      console.error(`setItem for ${this.cacheKey}`);
      if (analytics) {
        analytics.error(e?.name, {
          key: this.cacheKey,
        });
      }
    }
  }

  remove(key: string): void {
    const data = this.read();
    const itemSize = data.items[key].size;
    data.totalSize -= itemSize;
    this.totalSize = data.totalSize;

    delete data.items[key];

    try {
      localStorage.setItem(this.cacheKey, JSON.stringify(data));
    } catch (e: any) {
      console.error(`setItem for ${this.cacheKey}`);
      if (analytics) {
        analytics.error(e?.name, {
          key: this.cacheKey,
        });
      }
    }
  }
}

export class LRUSWCache {
  cacheKey: string;
  maxSize: number;
  cache: Cache | null;

  registry: CacheRegistry;
  evictionThreshold: number;

  constructor({
    key,
    version,
    maxSize,
    evictionThreshold = 0,
  }: {
    key: string;
    version: string;
    maxSize: number;
    // the eviction threshold is a percentage of the maximum cache size (0.0 - 1.0)
    evictionThreshold?: number;
  }) {
    this.cacheKey = key;
    this.maxSize = maxSize;
    this.registry = new CacheRegistry(key);
    this.evictionThreshold = evictionThreshold;

    const previousCacheVersion = localStorage.getItem(`${key}-version`);
    if (previousCacheVersion != null) {
      // if the cache version has changed, clear the cache registry
      if (previousCacheVersion !== version) {
        localStorage.removeItem(key);
        this.registry.reset();
      }
    }
    try {
      localStorage.setItem(`${key}-version`, String(version));
    } catch (e: any) {
      console.error(`setItem for ${key}-version`);
      analytics.error(e?.name, {
        key: `${key}-version`,
      });
    }
  }

  // eslint-disable-next-line no-use-before-define
  async open(): Promise<LRUSWCache> {
    if (this.cache == null) {
      const cache = await caches.open(this.cacheKey);
      this.cache = cache;
      await this.#validateMetadata();
    }
    return this;
  }

  // checks if the cache is full and if so evicts the least recently used item
  #evict = async (): Promise<void> => {
    if (this.registry.totalSize <= this.maxSize) {
      // cache is not full
      return;
    }

    const data = this.registry.read();
    const sortedKeys = Object.keys(data.items).sort(
      (a, b) => data.items[a].lastAccessed - data.items[b].lastAccessed
    );

    let totalSize = this.registry.totalSize;

    // finds enough items to remove to get the cache size below the eviction threshold
    const keysToRemove: Array<string> = [];
    for (const key of sortedKeys) {
      totalSize -= data.items[key].size;
      keysToRemove.push(key);
      if (totalSize <= this.maxSize * (1 - this.evictionThreshold)) {
        break;
      }
    }

    await Promise.all(keysToRemove.map((key) => this.delete(key)));
  };

  #requestInfoToURLString = (requestInfo: RequestInfo): string => {
    if (requestInfo instanceof Request) {
      return requestInfo.url;
    } else {
      return requestInfo.toString();
    }
  };

  async #validateMetadata() {
    if (this.cache != null) {
      const cache = this.cache;
      const metadataKeys = this.registry.read().items;
      const cacheKeys = (await cache.keys()).map((key) => this.#requestInfoToURLString(key));

      if (
        cacheKeys.length !== Object.keys(metadataKeys).length ||
        cacheKeys.some((ck) => !(ck in metadataKeys))
      ) {
        // metadata is out of sync, we can clear the cache since its not critical data
        // instead of trying to rebuild it, which could be expensive
        console.error('Cache metadata out of date, purging data');
        this.registry.reset();
        await Promise.all(cacheKeys.map((ck) => cache.delete(ck)));
      }
    }
  }

  #guardUninitialized = (): Cache => {
    if (this.cache == null) {
      throw new Error('Cache is not initialized, call open() first');
    }
    return this.cache;
  };

  async put(request: RequestInfo, response: ResponseWithContentLength): Promise<void> {
    const cache = this.#guardUninitialized();

    const size = response.headers.get('content-length');

    try {
      await cache.put(request, response);
      this.registry.touch(this.#requestInfoToURLString(request), Number(size));
    } catch (e: any) {
      console.error('Unable to cache data, the available storage may be full', e);
    }

    await this.#evict();
  }

  async match(request: RequestInfo): Promise<Response | null> {
    const cache = this.#guardUninitialized();

    const resource = await cache.match(request);

    return resource;
  }

  async delete(request: RequestInfo): Promise<boolean> {
    const cache = this.#guardUninitialized();

    const deleted = await cache.delete(request);
    if (deleted) {
      this.registry.remove(this.#requestInfoToURLString(request));

      return true;
    } else {
      return false;
    }
  }

  async purge(): Promise<void> {
    this.registry.reset();
    if (this.cache != null) {
      const cache = this.cache;
      const allKeys = await cache.keys();
      await Promise.all(
        allKeys.map((key) => {
          return cache.delete(key);
        })
      );
    }
  }
}
