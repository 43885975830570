import { pipe, toLower, toUpper, map, join, split } from 'ramda';

export const UNICODE_GROUP_SEPARATOR = '\u{0029}';

export const trim = (str?: string | null): string => String(str).trim();

/** Capitalizes a string. By default, this function will force all but the first letter to lowercase
 * */
export const capitalize = (str: string, preserveCase: boolean = false): string => {
  const rest = preserveCase ? str : str.toLowerCase();
  return str.charAt(0).toUpperCase() + rest.slice(1);
};

export const snakeToPascal = (str: string): string =>
  pipe(toLower, split('_'), map(capitalize), join(''))(str);

export const pascalToCapitalSnake = (str: string): string =>
  pipe(split(/(?=[A-Z])/), join('_'), toUpper)(str);

export function pluralize(count: number, word: string): string {
  if (Intl.PluralRules == null) {
    return word;
  }

  const pluralRules = new Intl.PluralRules('en-US');
  const pluralForm = pluralRules.select(count);

  switch (pluralForm) {
    case 'one':
      return word;
    case 'other':
      return `${word}s`;
    default:
      throw new Error(`Unexpected plural form: ${pluralForm}`);
  }
}

export function conjugateVerb(count: number, verb: string): string {
  if (Intl.PluralRules == null) {
    return verb;
  }

  const pr = new Intl.PluralRules();
  return pr.select(count) === 'one' ? verb + 's' : verb;
}

export function addNumberSuffix(num: number | string): string {
  const suffixes = new Map([
    ['one', 'st'],
    ['two', 'nd'],
    ['few', 'rd'],
    ['other', 'th'],
  ]);

  if (Intl.PluralRules == null) {
    return String(num);
  }

  const pr = new Intl.PluralRules('en', { type: 'ordinal' });
  const suffix = suffixes.get(pr.select(Number(num)));

  if (!suffix) {
    return String(num);
  }

  return `${num}${suffix}`;
}
