import { ScreenName } from '../generated/graphql';
import { getWindowId } from '../hooks/useOpenTabs';
import { PAGE_TYPES, getPageType } from './pageTypes';

export function getScreenName(urlOrPathname: string): ScreenName {
  const pageType = getPageType(urlOrPathname);

  switch (pageType) {
    case PAGE_TYPES.VIEWER:
      if (getWindowId(urlOrPathname) === '0') {
        return ScreenName.Viewer0;
      } else {
        return ScreenName.Viewer1;
      }
    case PAGE_TYPES.REPORTER:
      return ScreenName.Reporter;
    case PAGE_TYPES.WORKLIST:
      return ScreenName.Worklist;
    case PAGE_TYPES.PATIENT_JACKET:
      return ScreenName.PatientJacket;
    default:
      throw new Error(`Unknown screen for ${urlOrPathname}`);
  }
}

export function getSironaUrlFromScreenName(
  screen: ScreenName,
  currentCaseId?: string | null
): string {
  const { protocol, host } = window.location;
  const w = currentCaseId != null ? `${currentCaseId}` : '';
  switch (screen) {
    case 'WORKLIST':
      return `${protocol}//${host}/worklist`;
    case 'REPORTER':
      return `${protocol}//${host}/case/${w}/reporter`;
    case 'VIEWER0':
      return `${protocol}//${host}/case/${w}/viewer/0`;
    case 'VIEWER1':
      return `${protocol}//${host}/case/${w}/viewer/1`;
    case 'PATIENT_JACKET':
      // The jacket can show a different case than the currentCaseId. The
      //  downstream code will look for an open window that startsWith this URL,
      //  so if we include the current case ID it may not match the window URL.
      return `${protocol}//${host}/jacket`;
    default:
      return `${protocol}//${host}/`;
  }
}
