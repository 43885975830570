import type { Json } from 'generated/graphql';

import { idbCursor, idbGet, idbTransaction } from '../idb';
import type { FullSingleLayerStack } from 'domains/viewer/ViewportsConfigurations/types';
import type { PixelMap } from './PixelDataLoader';
import { nanoid } from 'nanoid';
import { logger } from '../logger';

type IDBVersionChangeEvent = {
  oldVersion: number;
  newVersion: number;
  target: IDBRequest;
};

type StackCacheItem = {
  smid: string;
  pixelsQuota: number;
  createdAt: Date;
  updatedAt: Date;
};

const KB = 1000; // bytes
const MB = 1000 * KB;
const GB = 1000 * MB;
const MAX_PIXELS_QUOTA = 1 * GB;

const PIXEL_CACHE_KEY = 'pixel-data';
const PIXEL_CACHE_VERSION = 7;

const createStoreObjects = (event: IDBVersionChangeEvent) => {
  const { target, oldVersion } = event;
  const dbRequest = target;

  const db: IDBDatabase = dbRequest.result;

  dbRequest.onerror = () => {
    console.error('Error loading database.');
  };

  // Starting from version 7 we improved the cache indexes for the cache object store
  // and we added a new object store for the stacks, so we need to delete the old ones
  if (oldVersion < 7) {
    // Only if the DB is not empty, delete the cache object store
    if (oldVersion > 0 && db.objectStoreNames.contains('cache')) {
      db.deleteObjectStore('cache');
    }

    if (oldVersion > 0 && db.objectStoreNames.contains('stacks')) {
      db.deleteObjectStore('stacks');
    }

    // Create a new cache object store with the new indexes
    const pixelsStore = db.createObjectStore('cache', { keyPath: 'frameSmid' });
    pixelsStore.createIndex('stackSmid, sortIndex', ['stackSmid', 'sortIndex'], {
      unique: false,
    });

    // Create a new stacks object store with indexes
    const stacksStore = db.createObjectStore('stacks', { keyPath: 'smid' });
    stacksStore.createIndex('updatedAt', 'updatedAt', { unique: false });
  }
};

export function clearDB(): Promise<void> {
  return new Promise(
    async (
      resolve: (result: Promise<undefined> | undefined) => void,
      reject: (error?: any) => void
    ) => {
      const openDB = globalThis.indexedDB.open(PIXEL_CACHE_KEY, PIXEL_CACHE_VERSION);

      openDB.addEventListener('success', () => {
        const db = openDB.result;
        if (db == null) {
          reject();
          return;
        }

        Promise.all(
          ['cache', 'stacks'].map((storeKey) => {
            return new Promise(
              (
                resolveClear: (result: Promise<undefined> | undefined) => void,
                rejectClear: (error?: any) => void
              ) => {
                if (db.objectStoreNames.contains(storeKey)) {
                  const transaction = db.transaction([storeKey], 'readwrite');
                  const store = transaction.objectStore(storeKey);
                  store.clear();
                  transaction.oncomplete = () => {
                    // @ts-expect-error [EN-7967] - TS2794 - Expected 1 arguments, but got 0. Did you forget to include 'void' in your type argument to 'Promise'?
                    resolveClear();
                  };
                  transaction.onerror = (event: any) => {
                    logger.error('Error in transaction to clearing the pixel cache.');
                    rejectClear();
                  };
                  transaction.onabort = () => {
                    logger.error('Transaction aborted clearing the pixel cache.');
                    rejectClear();
                  };
                } else {
                  // @ts-expect-error [EN-7967] - TS2794 - Expected 1 arguments, but got 0. Did you forget to include 'void' in your type argument to 'Promise'?
                  resolveClear();
                }
              }
            );
          })
        )
          // @ts-expect-error [EN-7967] - TS2794 - Expected 1 arguments, but got 0. Did you forget to include 'void' in your type argument to 'Promise'?
          .then(() => resolve())
          .catch(() => reject());
      });

      // @ts-expect-error [EN-7967] - TS2769 - No overload matches this call.
      openDB.addEventListener('upgradeneeded', createStoreObjects);
    }
  );
}

export function openDB(): Promise<IDBDatabase> {
  return new Promise(
    (
      resolve: (result: Promise<IDBDatabase> | IDBDatabase) => void,
      reject: (error?: any) => void
    ) => {
      const openOrCreateDB: IDBOpenDBRequest = globalThis.indexedDB.open(
        PIXEL_CACHE_KEY,
        PIXEL_CACHE_VERSION
      );
      openOrCreateDB.addEventListener('success', () => {
        const db: IDBDatabase = openOrCreateDB.result;
        if (db == null) {
          reject();
          return;
        }
        resolve(db);
      });
      openOrCreateDB.addEventListener('error', () => {
        reject();
      });

      // @ts-expect-error [EN-7967] - TS2769 - No overload matches this call.
      openOrCreateDB.addEventListener('upgradeneeded', createStoreObjects);
    }
  );
}

export async function writeAllToDB(
  db: IDBDatabase,
  tableName: string,
  map: PixelMap
): Promise<void> {
  // @ts-expect-error [EN-7967] - TS2741 - Property 'usageDetails' is missing in type 'StorageEstimate' but required in type '{ quota: number; usageDetails: { caches: number; indexedDB: number; }; }'.
  const storageEstimate:
    | {
        quota: number;
        usageDetails: {
          caches: number;
          indexedDB: number;
        };
      }
    | null
    | undefined =
    // $FlowIssue[prop-missing] Flow support for navigator.storage is not complete
    await navigator?.storage?.estimate();

  const storageQuotaEstimate: number | null | undefined = storageEstimate?.quota;
  const cachesUsage: number = storageEstimate?.usageDetails?.caches ?? 0;

  // If storageQuotaEstimate is null, we don't know how much space we have
  // so we'll use a conservative 1GB limit, otherwise we'll use 80% of the available space
  // as the limit to ensure we don't fill up the storage and leave space for other storages
  const maxPixelsQuota = Math.floor(
    storageQuotaEstimate != null ? storageQuotaEstimate * 0.8 - cachesUsage : MAX_PIXELS_QUOTA
  );

  return idbTransaction(db, [tableName, 'stacks'], 'readwrite', async (transaction) => {
    const writeId = nanoid();
    await Promise.all(
      Array.from(map).map(async ([frameSmid, entry]: [any, any]) => {
        const { frameInfo, data, status } = entry;
        // asynchronous writes may have already handled this entry
        if (status === 'written') return;
        const { stackSmid, sortIndex } = frameInfo;
        const objectStore = transaction.objectStore(tableName);
        objectStore.put({
          frameSmid,
          stackSmid,
          sortIndex,
          data,
          createdAt: Date.now(),
          updatedAt: Date.now(),
        });

        // after moving the image to the cache, mark this as written
        entry.status = 'written';
        entry.writeId = writeId;

        if ('pixels' in data) {
          const stacksObjectStore = transaction.objectStore('stacks');
          const stack = await idbGet<StackCacheItem>(stacksObjectStore, stackSmid);

          stacksObjectStore.put({
            smid: stackSmid,
            pixelsQuota: (stack?.pixelsQuota ?? 0) + data.pixels.length,
            createdAt: stack?.createdAt ?? Date.now(),
            updatedAt: Date.now(),
          });

          // read all stacks pixelsQuota and sum them up
          // NOTE(fzivolo): we use updatedAt as index because it makes it very difficult
          // to delete siblings stacks in the same "case load" operation
          const totalPixelsQuota = await idbCursor(
            stacksObjectStore.index('updatedAt').openCursor(),
            (totalPixelsQuota, cursor) => {
              const stack: StackCacheItem | null | undefined = cursor.value;
              totalPixelsQuota += stack?.pixelsQuota ?? 0;
              return totalPixelsQuota;
            },
            0
          );

          // If totalPixelsQuota is larger than MAX_PIXELS_QUOTA, delete the oldest stacks
          // until totalPixelsQuota is smaller than MAX_PIXELS_QUOTA
          if (totalPixelsQuota < maxPixelsQuota) {
            return;
          }

          // Clean until we are under the quota
          await idbCursor(
            stacksObjectStore.index('updatedAt').openCursor(),
            (totalPixelsQuota, cursor, exit) => {
              const stack: StackCacheItem = cursor.value;

              // If we are under the quota, don't delete anything
              if (totalPixelsQuota < maxPixelsQuota) {
                exit();
                return totalPixelsQuota;
              }

              // If the current stack is the one we just added, don't delete it
              if (stack.smid === stackSmid) {
                return totalPixelsQuota;
              }

              // Delete the stack and subtract its pixelsQuota from totalPixelsQuota
              // we'll then run the cursor again to see if we are under the quota
              totalPixelsQuota -= stack?.pixelsQuota ?? 0;
              stacksObjectStore.delete(cursor.primaryKey);
              return totalPixelsQuota;
            },
            totalPixelsQuota
          );
        }
      })
    );

    if (transaction.commit != null) {
      transaction.commit();
    }

    // clear data after it has been committed
    for (const entry of map.values()) {
      if (entry.writeId === writeId) {
        entry.data = { pixels: new Int8Array(0) };
        entry.size = 0;
      }
    }
  });
}

export async function writeToDB<T extends Json>(
  db: IDBDatabase,
  tableName: string,
  frameSmid: string,
  stackSmid: string,
  sortIndex: number,
  data: T
): Promise<void> {
  // @ts-expect-error [EN-7967] - TS2741 - Property 'usageDetails' is missing in type 'StorageEstimate' but required in type '{ quota: number; usageDetails: { caches: number; indexedDB: number; }; }'.
  const storageEstimate:
    | {
        quota: number;
        usageDetails: {
          caches: number;
          indexedDB: number;
        };
      }
    | null
    | undefined =
    // $FlowIssue[prop-missing] Flow support for navigator.storage is not complete
    await navigator?.storage?.estimate();

  const storageQuotaEstimate: number | null | undefined = storageEstimate?.quota;
  const cachesUsage: number = storageEstimate?.usageDetails?.caches ?? 0;

  // If storageQuotaEstimate is null, we don't know how much space we have
  // so we'll use a conservative 1GB limit, otherwise we'll use 80% of the available space
  // as the limit to ensure we don't fill up the storage and leave space for other storages
  const maxPixelsQuota = Math.floor(
    storageQuotaEstimate != null ? storageQuotaEstimate * 0.8 - cachesUsage : MAX_PIXELS_QUOTA
  );

  return idbTransaction(db, [tableName, 'stacks'], 'readwrite', async (transaction) => {
    const objectStore = transaction.objectStore(tableName);
    objectStore.put({
      frameSmid,
      stackSmid,
      sortIndex,
      data,
      createdAt: Date.now(),
      updatedAt: Date.now(),
    });

    // @ts-expect-error [EN-7967] - TS2322 - Type 'T' is not assignable to type 'object'.
    if ('pixels' in data) {
      const stacksObjectStore = transaction.objectStore('stacks');
      const stack = await idbGet<StackCacheItem>(stacksObjectStore, stackSmid);

      stacksObjectStore.put({
        smid: stackSmid,
        // @ts-expect-error [EN-7967] - TS2339 - Property 'length' does not exist on type 'unknown'.
        pixelsQuota: (stack?.pixelsQuota ?? 0) + data.pixels.length,
        createdAt: stack?.createdAt ?? Date.now(),
        updatedAt: Date.now(),
      });

      // read all stacks pixelsQuota and sum them up
      // NOTE(fzivolo): we use updatedAt as index because it makes it very difficult
      // to delete siblings stacks in the same "case load" operation
      const totalPixelsQuota = await idbCursor(
        stacksObjectStore.index('updatedAt').openCursor(),
        (totalPixelsQuota, cursor) => {
          const stack: StackCacheItem | null | undefined = cursor.value;
          totalPixelsQuota += stack?.pixelsQuota ?? 0;
          return totalPixelsQuota;
        },
        0
      );

      // If totalPixelsQuota is larger than MAX_PIXELS_QUOTA, delete the oldest stacks
      // until totalPixelsQuota is smaller than MAX_PIXELS_QUOTA
      await idbCursor(
        stacksObjectStore.index('updatedAt').openCursor(),
        (totalPixelsQuota, cursor, exit) => {
          const stack: StackCacheItem = cursor.value;

          // If we are under the quota, don't delete anything
          if (totalPixelsQuota < maxPixelsQuota) {
            exit();
            return totalPixelsQuota;
          }

          // If the current stack is the one we just added, don't delete it
          if (stack.smid === stackSmid) {
            return totalPixelsQuota;
          }

          // Delete the stack and subtract its pixelsQuota from totalPixelsQuota
          // we'll then run the cursor again to see if we are under the quota
          totalPixelsQuota -= stack?.pixelsQuota ?? 0;
          stacksObjectStore.delete(cursor.primaryKey);
          return totalPixelsQuota;
        },
        totalPixelsQuota
      );
    }

    if (transaction.commit != null) {
      transaction.commit();
    }
  });
}

export function readFromDB<T>(db: IDBDatabase, tableName: string, frameSmid: string): Promise<T> {
  return new Promise((resolve: (result: Promise<T> | T) => void, reject: (error?: any) => void) => {
    const transaction = db.transaction([tableName], 'readonly');
    const objectStore = transaction.objectStore(tableName);
    const request = objectStore.get(frameSmid);

    request.addEventListener('success', () => {
      const data: T = request.result;
      resolve(data);
    });
    request.addEventListener('error', (event: unknown) => {
      // @ts-expect-error [EN-7967] - TS2339 - Property 'target' does not exist on type 'unknown'.
      reject(event.target.error);
    });
  });
}

export function readAllFromDB<T>(
  db: IDBDatabase,
  tableName: string,
  stackSmid: string,
  onRead: (arg1: T) => unknown,
  direction: IDBCursorDirection
): Promise<void> {
  return new Promise(
    (resolve: (result: Promise<undefined> | undefined) => void, reject: (error?: any) => void) => {
      const transaction = db.transaction([tableName], 'readonly');
      const objectStore = transaction.objectStore(tableName);
      const indexRequest = objectStore
        .index('stackSmid, sortIndex')
        // $FlowIssue[prop-missing] Flow support for IDB is not complete
        .openCursor(IDBKeyRange.bound([stackSmid, 0], [stackSmid, Infinity]), direction);
      indexRequest.addEventListener('success', (event: unknown) => {
        // @ts-expect-error [EN-7967] - TS2339 - Property 'target' does not exist on type 'unknown'.
        const cursor: IDBCursor = event.target.result;
        if (cursor == null) {
          // @ts-expect-error [EN-7967] - TS2794 - Expected 1 arguments, but got 0. Did you forget to include 'void' in your type argument to 'Promise'?
          resolve();
          return;
        }

        // @ts-expect-error [EN-7967] - TS2339 - Property 'value' does not exist on type 'IDBCursor'.
        onRead(cursor.value);
        cursor.continue();
      });
      indexRequest.addEventListener('error', () => {
        reject();
      });
    }
  );
}

export function getDirectionForCacheLoad(initialStack?: {
  stack: FullSingleLayerStack;
  initialFrameIndex: number;
}): IDBCursorDirection {
  if (initialStack == null) {
    return 'next';
  }
  return initialStack.initialFrameIndex < initialStack.stack.frames.length / 2 ? 'next' : 'prev';
}
