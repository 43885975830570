export function sleep(ms: number): Promise<void> {
  return new Promise((resolve: (result: Promise<undefined> | undefined) => void) =>
    setTimeout(resolve, ms)
  );
}

export function exponentialBackoff(
  initialDelay: number,
  maxDelay: number,
  attempt: number
): number {
  return Math.min(initialDelay * 2 ** attempt, maxDelay);
}
