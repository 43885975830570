import { readArrayBuffer } from 'itk-wasm';
import type { Image } from 'itk-wasm';
import { env } from 'config/env';

import ImageWorker from './workers/dicom-worker?worker';
export { ImageWorker };

type PooledWorker = {
  worker: Worker;
  // workerPromise: typeof WebworkerPromise,
  status: 'idle' | 'busy';
};

type ReadArrayBufferResponse = {
  image: Image;
};

export class ImageIOWorkerPool {
  pooledWorkers: Array<PooledWorker>;

  constructor() {
    let numWorkers = window.navigator.hardwareConcurrency ?? 0;
    if (numWorkers <= 0) numWorkers = 4;

    const itkWasmVersion = env?.ITK_VERSIONS?.itkWasmVersion;
    if (itkWasmVersion == null) {
      throw new Error('ITK_VERSIONS.itkWasmVersion not set');
    }

    this.pooledWorkers = new Array(numWorkers);
    for (let i = 0; i < numWorkers; i++) {
      const worker = new Worker(
        `/itk/web-workers.${itkWasmVersion}/min-bundles/pipeline.worker.js`
      );
      // const workerPromise = new WebworkerPromise(worker);
      this.pooledWorkers[i] = {
        worker,
        // workerPromise,
        status: 'idle',
      };
    }
  }

  async readArrayBuffer(buffer: ArrayBuffer, fileName: string): Promise<ReadArrayBufferResponse> {
    const pooledWorker = await this.getAvailablePooledWorker();
    pooledWorker.status = 'busy';

    // @ts-expect-error [EN-7967] - TS2339 - Property 'image' does not exist on type 'ReadImageResult | ReadMeshResult'. | TS2554 - Expected 4 arguments, but got 3.
    const { image } = await readArrayBuffer(pooledWorker.worker, buffer, fileName);
    pooledWorker.status = 'idle';

    return { image };
  }

  async getAvailablePooledWorker(): Promise<PooledWorker> {
    const worker = this.pooledWorkers.find((w) => w.status === 'idle');

    if (worker == null) {
      return new Promise((resolve: (result: Promise<PooledWorker> | PooledWorker) => void) => {
        setTimeout(async () => {
          const worker = await this.getAvailablePooledWorker();
          resolve(worker);
        }, 10);
      });
    }

    return worker;
  }
}

let globalImageIOWorkerPool: ImageIOWorkerPool | null = null;
export const getGlobalImageIOWorkerPool = (): ImageIOWorkerPool => {
  if (globalImageIOWorkerPool === null) {
    globalImageIOWorkerPool = new ImageIOWorkerPool();
  }

  return globalImageIOWorkerPool;
};
