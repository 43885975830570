import type { ValidationResult } from './useSystemCheck';
import { logger } from 'modules/logger';
import analytics from 'modules/analytics';
import { globalContext } from 'modules/analytics/constants';

/** Enum-like object of all system check validators */
export const SysChkValidator = Object.freeze({
  ExtensionInstallState: 'extensionInstallState',
  ExtensionMinVersion: 'extensionMinVersion',
});
export type SysChkValidatorTypes = (typeof SysChkValidator)[keyof typeof SysChkValidator];

/** Enum-like object of enforcement level of each validator */
export const SysChkValidatorEnforcement = Object.freeze({
  /** Required for the app to function */
  Blocking: 'Blocking',
  Recommended: 'Recommended',
});
export type SysChkValidatorEnforcementTypes =
  (typeof SysChkValidatorEnforcement)[keyof typeof SysChkValidatorEnforcement];

type ValidatorOptions = {
  /** Force a validator result for testing purposes */
  force?: boolean;
};
type SystemCheckValidator = {
  enabled: boolean;
  enforcement: SysChkValidatorEnforcementTypes;
  validationFn: (options?: ValidatorOptions | null | undefined) => ValidationResult;
};

export const validateExtensionInstallState = (
  options: ValidatorOptions | null = {}
): ValidationResult => {
  const validatorCondition = window.__SIRONA_WORKSPACE_MANAGER_AVAILABLE__ != null;

  if (!validatorCondition) {
    logger.info(`[SysCheckFailure] Extension not installed`);
  }
  if ((options?.force === undefined && validatorCondition) || options?.force === true) {
    return {
      id: SysChkValidator.ExtensionInstallState,
      result: true,
      reason: 'Extension installed',
    };
  }
  return {
    id: SysChkValidator.ExtensionInstallState,
    result: false,
    reason: `Extension is not installed`,
  };
};

export const EXTENSION_MIN_VERSION = 3;
export const validateExtensionMinVersion = (
  options: ValidatorOptions | null = {}
): ValidationResult => {
  const validatorCondition =
    window.__SIRONA_WORKSPACE_MANAGER_API_VERSION__ >= EXTENSION_MIN_VERSION;
  if (!validatorCondition) {
    logger.info(
      `[SysCheckFailure] Extension API version is out of date; actual: ${window.__SIRONA_WORKSPACE_MANAGER_API_VERSION__}, expected: >=${EXTENSION_MIN_VERSION} - ext version: ${window.__SIRONA_WORKSPACE_MANAGER_SEMVER__}`
    );
  }

  analytics.addContext(
    globalContext.workspace.extensionVersion,
    window.__SIRONA_WORKSPACE_MANAGER_SEMVER__
  );

  if ((options?.force === undefined && validatorCondition) || options?.force === true) {
    return {
      id: SysChkValidator.ExtensionMinVersion,
      result: true,
      reason: 'Extension version is up-to-date',
    };
  }

  return {
    id: SysChkValidator.ExtensionMinVersion,
    result: false,
    reason: `Installed extension version is out of date`,
  };
};

export type SystemCheckValidatorsMap = Map<SysChkValidatorTypes, SystemCheckValidator>;
/** Map of all validators and their validation functions for evaluating the Sirona System Check
 * value */
export const SystemCheckValidators: SystemCheckValidatorsMap = new Map([
  [
    SysChkValidator.ExtensionInstallState,
    {
      enabled: true,
      enforcement: SysChkValidatorEnforcement.Blocking,
      validationFn: validateExtensionInstallState,
    },
  ],
  [
    SysChkValidator.ExtensionMinVersion,
    {
      enabled: true,
      enforcement: SysChkValidatorEnforcement.Blocking,
      validationFn: validateExtensionMinVersion,
    },
  ],
]);

/** Runs all enabled validators to produce a single result */
export const validateAll = (
  options: ValidatorOptions | null = {}
): {
  result: boolean;
  validators: ValidationResult[];
} =>
  Array.from(SystemCheckValidators.values())
    .filter((validator) => validator.enabled)
    .map((validator) => {
      return validator.validationFn(options);
    })
    .reduce(
      (acc, validator) => {
        // @ts-expect-error [EN-7967] - TS2322 - Type 'boolean' is not assignable to type 'true'.
        acc.result = options?.force !== undefined ? options.force : acc.result && validator.result;
        acc.validators.push(validator);
        return acc;
      },
      { result: true, validators: [] }
    );

/** Run a single validator by ID */
export const validateSingle = (
  validatorId: SysChkValidatorTypes,
  options: ValidatorOptions | null = {}
):
  | ValidationResult
  | (ValidationResult & {
      id: null;
    }) => {
  const validator = SystemCheckValidators.get(validatorId);
  if (validator == null) {
    logger.error(`Validator "${validatorId}" not found`);
    return { id: null, result: false, reason: undefined };
  }
  return validator?.validationFn(options);
};
