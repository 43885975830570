// Counts the number of digits of a number (e.g., 10 -> 2 digits, 999 -> 3 digits)
export const numDigits = (x: number): number =>
  Math.max(Math.floor(Math.log10(Math.abs(x))), 0) + 1;

/**
 * Returns a number with a maximum precision; trailing zeros will be truncated
 *
 * @param n Input number for the basis of the precision adjustment
 * @param precision Maximum precision of the number right of the decimal; must be greater
 * than zero
 */
export const maxPrecision = (n: number, precision: number): number =>
  Math.floor(n * Math.pow(10, precision)) / Math.pow(10, precision);
