import { useEffect } from 'react';
import { atom, useSetRecoilState } from 'recoil';
import type { RecoilState } from 'recoil';
import {
  addExtensionListener,
  extensionListenerReadyEvent,
  removeExtensionListener,
} from 'domains/extension/extensionEventCreators';
import { isWorkspaceManagerAvailable } from 'modules/workspace-manager';
import { RESPONSE_EVENTS as EXTENSION_RESPONSE_EVENTS } from 'domains/extension/constants';
import { dispatchExtensionEvent } from '../domains/extension/extensionEventCreators';

export const ExtWindowName = Object.freeze({
  Viewer0: 'viewer0',
  Viewer1: 'viewer1',
  Reporter: 'reporter',
  Worklist: 'worklist',
  Patient_Jacket: 'patient-jacket',
});

export type ExtWindowNameType = (typeof ExtWindowName)[keyof typeof ExtWindowName];

export type ExtTab = {
  windowId: number;
  id: number;
  url: string;
  createdAt: number;
  path: string;
};

export type WindowState = {
  windows: Partial<Record<ExtWindowNameType, ExtTab | null>>;
  caseId: string;
};

export const extensionAppState: RecoilState<WindowState> = atom({
  key: 'extension-app-state',
  default: { windows: {}, caseId: '' },
});

type SetExtensionStateType = CustomEvent<WindowState>;

export const useExtensionState = (): void => {
  const setExtensionState = useSetRecoilState(extensionAppState);

  useEffect(() => {
    const setExtStateHandler = (event: SetExtensionStateType) => {
      setExtensionState(event.detail);
    };

    const addInjectionListener = !isWorkspaceManagerAvailable();

    const handleExtensionInjected = () => {
      dispatchExtensionEvent(extensionListenerReadyEvent());
      removeExtensionListener(
        EXTENSION_RESPONSE_EVENTS.EXTENSION_INJECTED,
        handleExtensionInjected
      );
    };

    addExtensionListener(EXTENSION_RESPONSE_EVENTS.WINDOW_STATE_UPDATED, setExtStateHandler);

    if (addInjectionListener) {
      addExtensionListener(EXTENSION_RESPONSE_EVENTS.EXTENSION_INJECTED, handleExtensionInjected);
    } else {
      dispatchExtensionEvent(extensionListenerReadyEvent());
    }

    return () => {
      removeExtensionListener(EXTENSION_RESPONSE_EVENTS.WINDOW_STATE_UPDATED, setExtStateHandler);
      if (addInjectionListener) {
        removeExtensionListener(
          EXTENSION_RESPONSE_EVENTS.EXTENSION_INJECTED,
          handleExtensionInjected
        );
      }
    };
  }, [setExtensionState]);
};
