import type { SupportedTextureTypes, SupportedTexturesMap } from 'utils/textureUtils';
import type { FullSingleLayerStack } from 'domains/viewer/ViewportsConfigurations/types';
import { logger } from 'modules/logger';
import type { ModalityLutModule } from 'generated/graphql';

export type TransferStartingMessage = {
  event: 'transfer-starting';
  requestId: string;
};

export type TransferErrorOutgoingMessage = {
  event: 'transfer-error';
  stacks: string[];
  error: string;
};

export type TransferCompleteMessage = {
  event: 'transfer-complete';
  requestId: string;
  framesTransfered: number;
  endingPriority: number;
  endingFocus: number;
};

export type FrameDataMessage = {
  event: 'frame-data';
  cachePixelUrl: string;
  dcmdataPixelUrl: string | null | undefined;
};

export type OutgoingMessage =
  | TransferStartingMessage
  | TransferErrorOutgoingMessage
  | TransferCompleteMessage
  | FrameDataMessage;

export type DataReceivedMessage = {
  event: 'data-received';
  frameSmid: string;
  pixels: SupportedTextureTypes;
};

export type CallbackMessage = OutgoingMessage | DataReceivedMessage;

export type MessageReceivedCallback = (message: CallbackMessage) => void;

export type OrderedFrame = {
  seriesSmid: string;
  frameSmid: string;
  stackSmid: string;
  isMultiFrame: boolean;
  sortIndex: number;
  modules: {
    modalityLut?: ModalityLutModule | null;
  };
};

export type LoadFramesProps = {
  orderedFrames: OrderedFrame[];
  initialFocus: number;
  isInitialFrame: boolean;
  stackPriority: number;
  isDropped: boolean;
  messageReceivedCallback: MessageReceivedCallback;
};

export class BaseImageLoader {
  _SUPPORTED_TEXTURES: SupportedTexturesMap;

  constructor(SUPPORTED_TEXTURES: SupportedTexturesMap) {
    this._SUPPORTED_TEXTURES = SUPPORTED_TEXTURES;
  }

  /******************************************************************
   * Public API
   ******************************************************************/

  loadStack(loadStackProps: {
    stack: FullSingleLayerStack;
    initialFocus: number;
    isInitialFrame: boolean;
    stackPriority: number;
    isDropped: boolean;
    messageReceivedCallback: MessageReceivedCallback;
  }): void {
    throw new Error('Not yet implemented');
  }

  async loadFrames(loadFramesProps: LoadFramesProps): Promise<void> {
    throw new Error('Not yet implemented');
  }

  cancel(stackSmid?: string): void {
    throw new Error('Not yet implemented');
  }

  updatePriority({
    stack,
    focusFrameIndex,
    stackPriority,
  }: {
    stack: FullSingleLayerStack;
    focusFrameIndex: number;
    stackPriority: number;
  }): void {
    throw new Error('Not yet implemented');
  }

  // manage download queue pauses to avoid OOM
  // NOOP for older unsupported Image Loaders
  resume(): void {}
  pause(): void {}
  refreshLoader(): void {}

  _log(message: string, ...data: unknown[]) {
    logger.debug(message, ...data);
  }
}
