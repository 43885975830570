import { generatePath, matchPath } from 'react-router-dom';
import { AUTHLESS_PATHS, AppRoutePath } from 'config/constants';

export const isAuthlessPathname = (pathname?: string | null): boolean =>
  pathname != null ? AUTHLESS_PATHS.some((path) => matchPath(path, pathname)) : false;

export const makeViewerUrl = (smid: string, windowId: number): string =>
  generatePath(AppRoutePath.VIEWER, { windowId: `${windowId}`, worklistId: smid });

export const makeReporterUrl = (smid: string): string =>
  generatePath(AppRoutePath.REPORTER, { worklistId: smid });
