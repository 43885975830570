import { env } from './env';

type Config = {
  deployEnvironment: string;
  graphql: string;
  adminUrl: string;
  orderManagerUrl: string;
  patientManagerUrl: string;
  rolesCookieName: string;
  graphqlWs: string;
  apiGateway: string;
  pixelLoaderWs: string;
  nvoqWs: string;
  nvoqWsMaximumConnections: number;
  asrPlexWs: string;
  asrPlexVadWs: string;
  segmentAppId: string;
  splitioApiKey: string;
};

type EnvMapping = {
  // @ts-expect-error [EN-7967] - TS1337 - An index signature parameter type cannot be a literal type or generic type. Consider using a mapped object type instead.
  [key: 'local' | 'development' | 'staging' | 'production']: Config;
  preview: (arg1: string) => Config; // Special case to allow namespaced previews,
  sironaPreview: (arg1: string) => Config; // Special case to allow namespaced previews
};

const OKTETO_NAMESPACE = env.OKTETO_NAMESPACE || '';
export const SIRONA_PREVIEW: string = env.SIRONA_PREVIEW || '';
const envMapping: EnvMapping = {
  // @ts-expect-error [EN-7967] - TS2353 - Object literal may only specify known properties, and 'local' does not exist in type 'EnvMapping'.
  local: {
    deployEnvironment: 'local',
    graphql: `http://localhost:4000/graphql`,
    adminUrl: `http://localhost:3042/admin`,
    orderManagerUrl: `http://localhost:3042/manage/order`,
    patientManagerUrl: `http://localhost:3042/manage/patient`,
    rolesCookieName: 'local_roles',
    graphqlWs: `ws://localhost:4000/graphql`,
    apiGateway: 'http://localhost:4000',
    pixelLoaderWs: `ws://localhost:4000/pixelws`,
    nvoqWs: 'wss://test.nvoq.com/wsapi/v2/dictation/topics/radiology',
    nvoqWsMaximumConnections: 3,
    asrPlexWs: 'ws://localhost:4000/ws/transcribe',
    asrPlexVadWs: 'ws://localhost:4000/ws/vad',
    segmentAppId: 'Y3YGaLukzPdNWPXEjlGjea3aJso2Y0GG',
    splitioApiKey:
      env.NODE_ENV === 'test' || env.LOCAL_SPLITS === 'true'
        ? 'localhost'
        : 'ulc2iijia2jp42b2mf1l600k61c42iufnmav',
  },
  preview: (oktetoNamespace: string) => ({
    deployEnvironment: 'preview',
    graphql: `https://graphql-${oktetoNamespace}.preview.dev.sironamedical.com/graphql`,
    adminUrl: `https://admin-${oktetoNamespace}.preview.dev.sironamedical.com/admin`,
    orderManagerUrl: `https://admin-${oktetoNamespace}.preview.dev.sironamedical.com/manage/order`,
    patientManagerUrl: `https://admin-${oktetoNamespace}.preview.dev.sironamedical.com/manage/patient`,
    rolesCookieName: 'local_roles',
    graphqlWs: `wss://graphql-${oktetoNamespace}.preview.dev.sironamedical.com/graphql`,
    apiGateway: `https://graphql-${oktetoNamespace}.preview.dev.sironamedical.com`,
    pixelLoaderWs: `wss://graphql-${oktetoNamespace}.preview.dev.sironamedical.com/pixelws`,
    nvoqWs: 'wss://test.nvoq.com/wsapi/v2/dictation/topics/radiology',
    nvoqWsMaximumConnections: 3,
    asrPlexWs: `wss://graphql-${oktetoNamespace}.preview.dev.sironamedical.com/ws/transcribe`,
    asrPlexVadWs: `wss://graphql-${oktetoNamespace}.preview.dev.sironamedical.com/ws/vad`,
    segmentAppId: 'Y3YGaLukzPdNWPXEjlGjea3aJso2Y0GG',
    splitioApiKey: 'ulc2iijia2jp42b2mf1l600k61c42iufnmav',
  }),
  sironaPreview: (sironaPreview: string) => ({
    deployEnvironment: 'preview',
    adminUrl: `https://admin-${sironaPreview}.preview.dev.sironamedical.com/admin`,
    orderManagerUrl: `https://admin-${sironaPreview}.preview.dev.sironamedical.com/manage/order`,
    patientManagerUrl: `https://admin-${sironaPreview}.preview.dev.sironamedical.com/manage/patient`,
    rolesCookieName: 'local_roles',
    graphql: `https://graphql-${sironaPreview}.dev.sironamedical.com/graphql`,
    graphqlWs: `wss://graphql-${sironaPreview}.dev.sironamedical.com/graphql`,
    apiGateway: `https://graphql-${sironaPreview}.dev.sironamedical.com`,
    pixelLoaderWs: `wss://graphql-${sironaPreview}.dev.sironamedical.com/pixelws`,
    nvoqWs: 'wss://test.nvoq.com/wsapi/v2/dictation/topics/radiology',
    nvoqWsMaximumConnections: 3,
    asrPlexWs: `wss://graphql-${sironaPreview}.dev.sironamedical.com/ws/transcribe`,
    asrPlexVadWs: `wss://graphql-${sironaPreview}.dev.sironamedical.com/ws/vad`,
    segmentAppId: 'Y3YGaLukzPdNWPXEjlGjea3aJso2Y0GG',
    splitioApiKey: 'ulc2iijia2jp42b2mf1l600k61c42iufnmav',
  }),
  development: {
    deployEnvironment: 'development',
    adminUrl: 'https://app.dev.sironamedical.com/admin',
    orderManagerUrl: 'https://app.dev.sironamedical.com/manage/order',
    patientManagerUrl: 'https://app.dev.sironamedical.com/manage/patient',
    rolesCookieName: 'dev_roles',
    graphql: 'https://graphql.dev.sironamedical.com/graphql',
    graphqlWs: 'wss://graphql.dev.sironamedical.com/graphql',
    apiGateway: 'https://graphql.dev.sironamedical.com',
    pixelLoaderWs: 'wss://graphql.dev.sironamedical.com/pixelws',
    nvoqWs: 'wss://test.nvoq.com/wsapi/v2/dictation/topics/radiology',
    nvoqWsMaximumConnections: 3,
    asrPlexWs: 'wss://graphql.dev.sironamedical.com/ws/transcribe',
    asrPlexVadWs: 'wss://graphql.dev.sironamedical.com/ws/vad',
    segmentAppId: 'Y3YGaLukzPdNWPXEjlGjea3aJso2Y0GG',
    splitioApiKey: 'ulc2iijia2jp42b2mf1l600k61c42iufnmav',
  },
  staging: {
    deployEnvironment: 'staging',
    adminUrl: 'https://app.stg.sironamedical.com/admin',
    orderManagerUrl: 'https://app.stg.sironamedical.com/manage/order',
    patientManagerUrl: 'https://app.stg.sironamedical.com/manage/patient',
    rolesCookieName: 'stg_roles',
    graphql: 'https://graphql.stg.sironamedical.com/graphql',
    graphqlWs: 'wss://graphql.stg.sironamedical.com/graphql',
    apiGateway: 'https://graphql.stg.sironamedical.com',
    pixelLoaderWs: 'wss://graphql.stg.sironamedical.com/pixelws',
    nvoqWs: 'wss://healthcare.nvoq.com/wsapi/v2/dictation/topics/radiology',
    nvoqWsMaximumConnections: 7,
    asrPlexWs: 'wss://graphql.stg.sironamedical.com/ws/transcribe',
    asrPlexVadWs: 'wss://graphql.stg.sironamedical.com/ws/vad',
    segmentAppId: 'fHPBx5PtUtAR3Si5Fr6m8BurpFGS8Grv',
    splitioApiKey: '69fu9r39ra5d4914kd7g27p246i5gsvhn3ko',
  },
  production: {
    deployEnvironment: 'production',
    adminUrl: 'https://app.sironamedical.com/admin',
    orderManagerUrl: 'https://app.sironamedical.com/manage/order',
    patientManagerUrl: 'https://app.sironamedical.com/manage/patient',
    rolesCookieName: 'prod_roles',
    graphql: 'https://graphql.prod.sironamedical.com/graphql',
    graphqlWs: 'wss://graphql.prod.sironamedical.com/graphql',
    apiGateway: 'https://graphql.prod.sironamedical.com',
    pixelLoaderWs: 'wss://graphql.prod.sironamedical.com/pixelws',
    nvoqWs: 'wss://healthcare.nvoq.com/wsapi/v2/dictation/topics/radiology',
    nvoqWsMaximumConnections: 7,
    asrPlexWs: 'wss://graphql.prod.sironamedical.com/ws/transcribe',
    asrPlexVadWs: 'wss://graphql.prod.sironamedical.com/ws/vad',
    segmentAppId: 'PAwhfXbi6zGSCvgr5I8hC9kF3ZphVj0g',
    splitioApiKey: 'behco42hidesjj8f2n86lco36qvui88fvi7s',
  },
};

const getConfigFromDeployEnv = () => {
  switch (env.DEPLOY_ENV) {
    case 'production':
      // @ts-expect-error [EN-7967] - TS2339 - Property 'production' does not exist on type 'EnvMapping'.
      return envMapping.production;
    case 'staging':
      // @ts-expect-error [EN-7967] - TS2339 - Property 'staging' does not exist on type 'EnvMapping'.
      return envMapping.staging;
    case 'development':
      // @ts-expect-error [EN-7967] - TS2339 - Property 'development' does not exist on type 'EnvMapping'.
      return envMapping.development;
    case 'preview':
      return SIRONA_PREVIEW
        ? envMapping.sironaPreview(SIRONA_PREVIEW)
        : envMapping.preview(OKTETO_NAMESPACE);
    default:
      // @ts-expect-error [EN-7967] - TS2339 - Property 'local' does not exist on type 'EnvMapping'.
      return envMapping.local;
  }
};

const config: Config = getConfigFromDeployEnv();

// If a environment variable is provided, we try to parse and use it
const envOrFallback = <T>(env: string | null | undefined | T, fallback: T): T => {
  if (env != null) {
    if (typeof env === 'string') {
      try {
        return JSON.parse(env);
      } catch (e: any) {
        // @ts-expect-error [EN-7967] - TS2322 - Type 'string | (T & string)' is not assignable to type 'T'.
        return env;
      }
    } else {
      return env;
    }
  }
  return fallback;
};

export const API_GATEWAY_URL: string = envOrFallback(env.API_GATEWAY_URL, config.apiGateway);
export const GRAPHQL_URL: string = envOrFallback(env.GRAPHQL_ENDPOINT, config.graphql);
export const ADMIN_URL: string = envOrFallback(env.ADMIN_URL, config.adminUrl);
export const ORDER_MANAGER_URL: string = envOrFallback(
  // @ts-expect-error [EN-7967] - TS2339 - Property 'ORDER_MANAGER_URL' does not exist on type '{ readonly STORYBOOK: any; readonly STORYBOOK_STORYSHOTS: any; readonly E2E_TEST: any; readonly TEST: "true"; readonly NODE_ENV: string; readonly LOCAL_SPLITS: any; readonly ENABLE_CONSOLE_ANALYTICS: any; ... 23 more ...; readonly DISABLE_SEGMENT: any; }'.
  env.ORDER_MANAGER_URL,
  config.orderManagerUrl
);
export const PATIENT_MANAGER_URL: string = envOrFallback(
  // @ts-expect-error [EN-7967] - TS2339 - Property 'PATIENT_MANAGER_URL' does not exist on type '{ readonly STORYBOOK: any; readonly STORYBOOK_STORYSHOTS: any; readonly E2E_TEST: any; readonly TEST: "true"; readonly NODE_ENV: string; readonly LOCAL_SPLITS: any; readonly ENABLE_CONSOLE_ANALYTICS: any; ... 23 more ...; readonly DISABLE_SEGMENT: any; }'.
  env.PATIENT_MANAGER_URL,
  config.patientManagerUrl
);

export const GRAPHQL_WS_URL: string = envOrFallback(env.GRAPHQL_WS_ENDPOINT, config.graphqlWs);
export const PIXEL_LOADER_WS_URL: string = envOrFallback(
  env.PIXEL_LOADER_WS_ENDPOINT,
  config.pixelLoaderWs
);
export const ASR_PLEX_WS_URL: string = envOrFallback(env.ASR_PLEX_WS_URL, config.asrPlexWs);
export const ASR_PLEX_VAD_WS_URL: string = envOrFallback(
  env.ASR_PLEX_VAD_WS_URL,
  config.asrPlexVadWs
);

export const DEPLOY_ENV: string = config.deployEnvironment;
export const NVOQ_WS_URL: string = envOrFallback(env.NVOQ_WS_URL, config.nvoqWs);
export const NVOQ_WS_MAXIMUM_CONNECTIONS: number = envOrFallback(
  env.NVOQ_WS_MAXIMUM_CONNECTIONS,
  config.nvoqWsMaximumConnections
);
export const SEGMENT_APP_ID = config.segmentAppId;
export const SPLITIO_API_KEY = config.splitioApiKey;
export const REPORTER_DEBUG: string = envOrFallback(env.REPORTER_DEBUG, 'false');
export const DATADOG_APP_VERSION: string = env.GIT_TAG ?? '?.?.?';
export const LOG_LEVEL: string = envOrFallback(env.LOG_LEVEL, 'info');
