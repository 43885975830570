// @ts-nocheck

export const env = {
  STORYBOOK: import.meta.env.STORYBOOK,
  STORYBOOK_STORYSHOTS: import.meta.env.STORYBOOK_STORYSHOTS,
  E2E_TEST: import.meta.env.PUBLIC_E2E_TEST,
  // We use `process.env.NODE_ENV` as a fallback because `import.meta.env.TEST` is not available in Jest
  // eslint-disable-next-line no-process-env
  TEST: (import.meta.env.TEST ?? process.env.NODE_ENV === 'test') ? 'true' : undefined,
  NODE_ENV: import.meta.env.MODE,
  LOCAL_SPLITS: import.meta.env.PUBLIC_LOCAL_SPLITS,
  ENABLE_CONSOLE_ANALYTICS: import.meta.env.PUBLIC_ENABLE_CONSOLE_ANALYTICS,
  GIT_TAG: import.meta.env.PUBLIC_GIT_TAG,
  REPORTER_DEBUG: import.meta.env.PUBLIC_REPORTER_DEBUG,
  NVOQ_WS_URL: import.meta.env.PUBLIC_NVOQ_WS_URL,
  NVOQ_WS_MAXIMUM_CONNECTIONS: import.meta.env.PUBLIC_NVOQ_WS_MAXIMUM_CONNECTIONS,
  GRAPHQL_ENDPOINT: import.meta.env.PUBLIC_GRAPHQL_ENDPOINT,
  GRAPHQL_WS_ENDPOINT: import.meta.env.PUBLIC_GRAPHQL_WS_ENDPOINT,
  ADMIN_URL: import.meta.env.ADMIN_URL,
  PIXEL_LOADER_WS_ENDPOINT: import.meta.env.PUBLIC_PIXEL_LOADER_WS_ENDPOINT,
  API_GATEWAY_URL: import.meta.env.PUBLIC_API_GATEWAY_URL,
  ASR_PLEX_WS_URL: import.meta.env.PUBLIC_ASR_PLEX_WS_URL,
  ASR_PLEX_VAD_WS_URL: import.meta.env.PUBLIC_ASR_PLEX_VAD_WS_URL,
  DEPLOY_ENV: import.meta.env.PUBLIC_DEPLOY_ENV,
  ENV: import.meta.env.ENV,
  OKTETO_NAMESPACE: import.meta.env.PUBLIC_OKTETO_NAMESPACE,
  SIRONA_PREVIEW: import.meta.env.PUBLIC_SIRONA_PREVIEW,
  BASE_URL: import.meta.env.BASE_URL,
  MODE: import.meta.env.MODE,
  BUILD_SHA1: import.meta.env.PUBLIC_BUILD_SHA1,
  ITK_VERSIONS: import.meta.env.ITK_VERSIONS,
  // prettier-ignore
  MAX_PARALLEL_PIXEL_WEBSOCKET_CONCURRENCY: import.meta.env.PUBLIC_MAX_PARALLEL_PIXEL_WEBSOCKET_CONCURRENCY,
  GRAPHQL_SCHEMA_VERSION: import.meta.env.GRAPHQL_SCHEMA_VERSION,
  LOG_LEVEL: import.meta.env.PUBLIC_LOG_LEVEL,
  FORCE_REMOTE_LOGGING: import.meta.env.PUBLIC_FORCE_REMOTE_LOGGING,
  DISABLE_SEGMENT: import.meta.env.PUBLIC_DISABLE_SEGMENT,
} as const;
